.steps {
  position: relative;
  margin-top: 172px;
  margin-bottom: 40px;
  z-index: 1;
  padding-bottom: 100px;
  @include media(1200) {
    margin-top: 150px;
    margin-bottom: 20px;
    padding-bottom: 0; }
  @include media(992) {
    margin-top: 120px; }
  @include media(768) {
    margin-top: 100px; }
  @include media(576) {
    margin-top: 90px; }
  &::before {
    content: '';
    position: absolute;
    bottom: 100%;
    margin-bottom: -20px;
    right: 0;
    left: 0;
    z-index: -1;
    height: 300px;
    @include bgImg('../../../public/img/bg_steps-1.svg');
    background-position: top 45px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    @include media(1500) {
      top: -240px;
 }      //height: 400px
    @include media(992) {
      top: -170px; }
    @include media(768) {
      top: -120px; }
    @include media(576) {
      top: -90px; } }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 573px;
    @include bgImg('../../../public/img/bg_steps-2.svg');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
    @include media(1200) {
      bottom: 40px; } } }

.steps_1 {
  .steps__picture-box {
    @include media(992) {
      margin-bottom: 32px; } }
  .steps__picture-button {
    top: 377px;
    left: 215px;
    padding: 21px 16px;
    font-size: 19px;
    line-height: 23px;
    @include media(1500) {
      top: 128px;
      left: 101px;
      padding: 9px 6px;
      font-size: 8px;
      line-height: 10px; } } }

.steps_2 {
  .steps__picture-box {
    @include media(992) {
      margin-top: 25px;
      margin-bottom: -20px; } }
  .steps__picture-button {
    top: 159px;
    left: 239px;
    padding: 21px 16px;
    font-size: 14px;
    line-height: 23px;
    @include media(1500) {
      top: 64px;
      left: 101px;
      padding: 10px 6px;
      font-size: 8px;
      line-height: 10px; } } }

.steps_3 {
  .steps__picture-box {
    @include media(992) {
      margin-bottom: 32px; } }
  .steps__picture-button {
    top: 376px;
    left: 222px;
    padding: 21px 52px;
    font-size: 19px;
    line-height: 23px;
    @include media(1500) {
      top: 128px;
      left: 101px;
      padding: 9px 22px;
      font-size: 8px;
      line-height: 10px; } } }

.steps__cont {
  @include cont; }

.steps__area {
  display: flex;
  margin-bottom: 64px;
  @include media(1200) {
    margin-bottom: 32px; }
  @include media(992) {
    display: block;
    margin-bottom: 0; }
  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    bottom: 250px;
    width: 100%;
    background-color: #f3f8fb;
    @include media(768) {
      bottom: 150px; }
    @include media(576) {
      top: 0;
      bottom: 100px; } } }

.steps__box {
  position: relative;
  z-index: 1;
  @include media(1920) {
    margin-top: -100px; }
  @include media(992) {
    margin-top: -50px; } }
.steps__title {
  @include title;
  margin-bottom: 54px;
  margin-top: 20px;
  @include media(1200) {
    margin-bottom: 39px; }
  @include media(520) {
    font-size: 19px;
 } }    //margin-left: 68px

.steps__span {
  letter-spacing: .02em;
  color: #3F4883; }

.steps__list {
  counter-reset: item; }

.steps__item {
  display: block;
  position: relative;
  margin-bottom: 35px;
  padding-left: 119px;
  padding-right: 20px;
  @include media(1200) {
    margin-bottom: 16px;
    padding-left: 68px; }
  &:hover {
    .steps__headline {
      color: #00AF4A; } }
  &:not(:last-of-type)::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 6px;
    left: 37px;
    width: 4px;
    height: calc(100% + 35px);
    background-color: #00AF4A;
    @include media(1200) {
      left: 22px; } }
  &::before {
    counter-increment: item;
    content: counter(item);
    position: absolute;
    z-index: 2;
    top: 6px;
    left: 0;
    width: 76px;
    height: 76px;
    padding-top: 7px;
    box-sizing: border-box;
    font-size: 35px;
    line-height: 51px;
    font-weight: 700;
    color: #00AF4A;
    background: #F3F8FB;
    text-align: center;
    border: 4px solid #00AF4A;
    border-radius: 50%;
    @include media(1200) {
      padding-top: 1px;
      top: 4px;
      width: 48px;
      height: 48px;
      font-size: 25px;
      line-height: 37px; } } }

.steps__headline {
  margin-bottom: 9px;
  letter-spacing: .02em;
  font-size: 24px;
  line-height: 35px;
  color: black;
  @include media(1200) {
    margin-bottom: 2px;
    font-size: 18px;
    line-height: 26px; } }

.steps__text {
  letter-spacing: .02em;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: black;
  @include media(1200) {
    font-size: 13px;
    line-height: 19px; } }

.steps__picture-box {
  flex-shrink: 0;
  position: relative;
  width: 780px;
  padding-top: 22px;
  @include media(1500) {
    margin: 150px auto 0;
    width: 375px - 32px;
    padding-top: 0; }
  @include media(1200) {
    margin-top: 0; } }

.steps__picture-button {
  position: absolute;
  text-transform: uppercase;
  letter-spacing: .02em;
  font-weight: 700;
  color: white; }

.steps__picture {
  display: block;
  @include media(1500) {
    margin: 0 -16px; } }

.steps__image {
  width: 780px;
  height: 654px;
  @include media(1500) {
    width: 375px;
    height: auto; } }

.steps__card {
  position: relative;
  border: 3px solid #3F4883;
  border-radius: 15px;
  padding: 31px 50px 41px;
  background-color: white;
  text-align: center;
  margin-top: 80px;
  @include bgImg('../../../public/img/bg_steps-3.svg');
  background-repeat: no-repeat;
  background-position: bottom 25px center;
  @include media(1200) {
    @include bgImg('../../../public/img/bg_steps-4.svg');
    background-position: top 11px center;
    background-size: contain;
    padding: 27px 1px 35px; } }

.steps__caption {
  @include title;
  margin-bottom: 23px;
  @include media(1200) {
    margin: 0 auto 18px;
    max-width: 370px;
    font-size: 22px;
    line-height: 30px; } }

.steps__note {
  display: block;
  margin-bottom: 31px;
  letter-spacing: .02em;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  @include media(1200) {
    margin-bottom: 32px;
    font-size: 15px;
    line-height: 22px; } }

.steps__btn-box {}

.steps__button {
  padding: 15px 35px;
  letter-spacing: .02em;
  font-size: 17px;
  line-height: 25px;
  @include media(1200) {
    padding: 14px 26px;
    font-size: 15px;
    line-height: 22px; } }
