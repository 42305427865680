html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
button {
    background: transparent;
    border: 0;
    margin: 0;
    outline: 0;
    padding: 0;
    font-family: 'Montserrat';
    text-decoration: none;
    letter-spacing: 1px;
    box-sizing: border-box;
    /*word-break: break-word;*/
}

:root {
    color-scheme: only light;
}

html {
}
body {
    scroll-behavior: smooth;
}
img {
    /*max-width: 100%;*/
    max-height: 100%;
}

li {
    list-style: none;
}

input,
textarea,
select {
    box-sizing: border-box;
    font-family: 'Montserrat';
    outline: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../../../public/img/drop-down-arrow.png") no-repeat right #fff;
    background-position-x: 97%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

body {
    min-width: 320px;
    -webkit-text-size-adjust: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../../../public/static/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    font-display: swap;
    src: url('../../../public/static/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    src: url('../../../public/static/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Montserrat';
    src: url('../../../public/static/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

.logo_wrap {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.mn_burger {
    height: 22px;
    width: 31px;
    cursor: pointer;
}

.mn_burger>span {
    width: 31px;
    height: 3px;
    background: rgba(24, 35, 107, 1);
    margin-bottom: 7px;
    display: block;
}

.mn_burger>span:last-child {
    margin-bottom: 0;
}

.mn_burger2 {
    border: none;
    background: url("../../../public/img/burger.png") no-repeat center;
}

.arr_menu {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #dbdde8;
    transition: .2s;
}

.dropdown .hd_ava {
    margin-right: 10px;
}

.dropdown:hover .arr_menu {
    border-top: 8px solid #404883;
}

.logo_wrap .dropdown_wrap {
    margin-left: 20px;
    height: 22px;
}

.header {
    padding: 35px 0 25px;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 6px 1px rgba(28, 65, 71, .09);
}

.img-sdelka>img {
    width: 100%;
}

.ul_lv1 {
    align-items: center;
    display: flex;
}

.ul_lv1>li {
    margin: 0 10px;
}

.ul_lv1>li>a,
.ul_lv1 .last {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: #565d8c;
    display: block;
    transition: .2s;
}

.main_menu {
    position: relative;
}

.menu-container>img {
    width: 23px;
    height: 23px;
    margin-left: 24px;
}

.menu-container ul li {
    padding: 16px 24px;
}

.menu-container a,
.menu-container li {
    color: #18236B;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    width: 100%;
}
.flash-danger {
    padding: 10px;
    border-radius: 5px;
    background: #F15B5B85;
}

.popup-ul>ul {
    padding-left: 32px;
}

.popup-ul>ul li {
    text-transform: none;
}

.menu-container ul li a:hover {
    background: rgba(24, 35, 107, 0.15);
}

.menu-container>ul>li:last-child {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 40px;
}

#tariff-popup {
    position: absolute;
    right: -330px;
    top: 45px;
}

#tariff-popup li {
    position: relative;
    background: #FFF;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.05);
    width: 400px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#tariff-popup li a {
    padding: 20px 30px;
    color: #18236B;
    display: block;
    font-size: 16px;
}

@media screen and (max-width: 1350px) {
    #tariff-popup li {
        width: 250px;
    }

    #tariff-popup {
        right: -130px;
    }
}

@media screen and (max-width: 950px) {
    #tariff-popup {
        right: 0;
    }
}

#tariff-popup li:after {
    /*content: '';*/
    /*right: 20px;*/
    /*background: url("../../../public/img/arrow-right-tariff.svg") no-repeat center center;*/
    /*width: 10px;*/
    /*display: inline-block;*/
    /*height: 10px;*/
    /*position: absolute;*/

}

#tariff-popup li:hover {
    background: rgb(219, 221, 232);
}

.ul_lv1 .last {
    cursor: pointer;
}

.ul_lv1 .last:after,
.popup-ul>li:first-child:after {
    content: '';
    background-color: #18236B;
    -webkit-mask: url("../../../public/img/arrow-down-tar.svg")no-repeat center;
    mask: url("../../../public/img/arrow-down-tar.svg") no-repeat center;
    width: 14px;
    height: 10px;
    display: inline-block;
    transition: all .3s;
}

.popup-ul>li:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ul_lv1 .active.last {
    color: #C0C4DF;
}

.ul_lv1 .active.last:after,
.popup-ul>li.active:first-child:after {
    transform: rotateX(180deg);
    background-color: #C0C4DF;
}

.popup-ul>li.active:first-child {
    background: #18236B26;
}

.popup-ul>li.active:first-child:after {
    background-color: #18236B;
}

.ul_lv1>li>a.active,
.ul_lv1>li>a:hover,
.ul_lv1>li.current>a {
    color: #53bc5a;
    text-decoration: underline;
}

.dropdown_menu a {
    white-space: nowrap;
    color: #565d8c;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 15px;
    transition: .2s;
    display: block;
}

.dropdown_menu a.active,
.dropdown_menu a:hover {
    color: #53bc5a;
}

.scroll-paginator-wrap button {
    cursor: pointer;
    width: 100%;
    border-radius: 15px;
    background: #D5D6D8;
    display: flex;
    height: 58px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 600;

}

.dropdown_menu li:last-child a {
    background: #f1f4f8;
}

.dropdown_menu {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgb(223, 227, 233);
    border-radius: 4px;
    position: absolute;
    left: 0;
    display: none;
    background: #ffffff;
    margin-top: 15px;
}

.dropdown_wrap {
    position: relative;
}

.btn1 {
    display: inline-block;
    width: 175px;
    outline: none;
    box-shadow: none;
    padding: 15px 0;
    text-align: center;
    color: #565d8c;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid #565d8c;
    transition: .2s;
    border-radius: 100px;
}

.form-select-worktype__add {
    margin-top: 12px;
}

.btn1.active,
.btn1:hover {
    color: #fff;
    background: #565d8c;
}

.btn1+.btn1 {
    margin-left: 15px;
}

.btn2 {
    display: inline-block;
    padding: 15px 30px;
    color: #fff;
    background: #00af49;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .1s;
    cursor: pointer;
    /*box-shadow: 0 3px 6px 2px #203e2a;*/
}

.btn2:hover {
    background: #36915c;
}

.btn11 {
    display: inline-block;
    padding: 15px 30px;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .1s;
    cursor: pointer;
}


.btn2:hover {
    /*box-shadow: 0 0 0 7px #e3f6eb;*/
}

.btn2:focus {
    box-shadow: 0 3px 6px 0 #203e2a;
}


.gz_b01 {
    padding: 200px 0 230px;
}

@media screen and (max-width:1920px) {
    .gz_b01 {
        margin-bottom: 90px;
        margin-top: 150px;
    }
}

@media screen and (max-width:769px) {
    .gz_b01 {
        margin-bottom: 0;
        margin-top: 0;
    }
}

.gz_b01 h1 {
    font-size: 40px;
    line-height: 56px;
}

.gz_b01 h1 span {
    color: #00af49;
}

.dark-blue {
    background: #18236B;
    color: #fff;
}


/*.gz_header {*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    left: 0;*/
/*    top: 0;*/
/*}*/
.filter-button-container > button {
    font-size: 16px;
    border-radius: 13px;
}
.dark-blue,
.dark-blue-outline,
.green,
.light-green,
.green-outline {
    /*white-space: nowrap;*/
    border-radius: 50px;
    text-align: center;
    font-size: 20px;
    padding: 15px 30px;
    cursor: pointer;
    font-weight: 600;
    transition: all .3s;
}

.green:hover {
    background: #1C4647;
}
.green,
.green-outline {
    border: 2px solid #2D6163;
}
.light-green {
    background: #ABC0C1;
    color: #1F4445;
}
.light-green:hover {
    background: #7C9D9F;
}

.green-outline:disabled:hover {
    background: white;
    cursor: inherit;
}

#container {
    background: #fff;
    width: 800px;
    padding: 30px;
    min-height: 400px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
    overflow: auto;
}

p {
    color: #444;
    margin: 15px 0;
}

.scrollBox {
    position: relative;
    width: 400px;
    height: 300px;

    padding-right: 14px;
    overflow: hidden;
}

.scrollbar {
    position: absolute;
    width: 6px;
    height: 100%;

    right: 0;
}

.scrollDragger {
    position: absolute;
    background: #ccc;
    width: 6px;
    height: 30px;

    cursor: pointer;
    margin-left: -1px;
    z-index: 2;
}

.scrollRail {
    position: absolute;
    background: #eee;
    width: 4px;
    height: 100%;
    z-index: 1;
}

ul {
    list-style: none;
    margin: 0;
}

.fadein {
    display: block;
    float: left;
    margin-bottom: 15px;
    color: #fff;
    font: 40px Edmondsans;
    text-align: center;
    padding: 75px 0;
    width: 100%;
    height: 50px;
    background: #35c52e;
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMzNWM1MmUiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMGI5ODMwIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-radial-gradient(center, ellipse cover, #35c52e 0%, #0b9830 100%);
    /* FF3.6+ */
    background: -webkit-radial-gradient(center, ellipse cover, #35c52e 0%, #0b9830 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(center, ellipse cover, #35c52e 0%, #0b9830 100%);
    /* Opera 12+ */
    background: -ms-radial-gradient(center, ellipse cover, #35c52e 0%, #0b9830 100%);
    /* IE10+ */
    background: radial-gradient(ellipse at center, #35c52e 0%, #0b9830 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#35c52e', endColorstr='#0b9830', GradientType=1);
    /* IE6-8 fallback on horizontal gradient */
}

#flux_carousel li {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 145px;
    height: 145px;
    background: #35c52e;
    /* Old browsers */
}

#flux_carousel ul:nth-child(2n+2) li {
    background: #ccc;
}

.tab {
    background: #108de4;
    padding: 20px;
    color: #eee;
    border-radius: 5px;
}

#perspective {
    margin: 0 auto;
    margin-top: 80px;
    width: 210px;
    height: 140px;
    position: relative;
    -webkit-perspective: 1100px;
    perspective: 1100px;
}

#carousel {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transform: rotateY(0deg) translateZ(-288px);
}

#carousel figure {
    display: block;
    position: absolute;
    background: #2ecc71;
    width: 278px;
    height: 180px;
    opacity: 0.6;
    margin: 12px;
    color: #fff;
    cursor: pointer;
    -webkit-transition: opacity 1s, -webkit-transform 1s;
    -moz-transition: opacity 1s, -moz-transform 1s;
    -o-transition: opacity 1s, -o-transform 1s;
    transition: opacity 1s, transform 1s;
}

.dark-blue-outline {
    color: #18236B;
    background: #fff;
    border: 2px solid #18236B;
}

.green-outline {
    color: #2D6163;
    background: #FFF;
}

.green-outline:hover {
    background: #2D6163;
    color: #FFFFFF;
}

.green-outline:hover.green-outline-arrow:before {
    background: url("../../../public/img/toggler-white.svg");
}

.green {
    color: #FFFFFF;
    background: #2D6163;
}

.green-outline-arrow:before,
.green-outline-arrow-down:before {
    background: url("../../../public/img/toggler.svg");
    content: "";
    fill: green;
    display: inline-block;
    background-size: 21px 13px;
    margin-right: 13px;
    width: 21px;
    height: 13px;
}

.green-outline-arrow-down:before {
    transform: rotateX(180deg);
}

.rotateslider-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    background: #C4D0FD;
    height: 440px;
    overflow: hidden;
}

.rotateslider-container .rotateslider-item {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(0.6);
    -ms-transform: translateY(-50%) translateX(-50%) scale(0.6);
    transform: translateY(-50%) translateX(-50%) scale(0.6);
    opacity: 0;
    /*-webkit-filter: blur(px);*/
    /*filter: blur(1px);*/
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.rotateslider-container .rotateslider-item.next {
    left: 80%;
    opacity: 0.5;
}

.rotateslider-container .rotateslider-item.prev {
    left: 20%;
    opacity: 0.5;
}
.checkbox {
    display: none;
}
.checkbox+label {
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.checkbox+label:before {
    cursor: pointer;
    content: '';
    background: url("../../../public/img/chb_empty.png") no-repeat center;
    width: 25px;
    height: 25px;
    display: inline-block;
}
.checkbox:checked+label:before {
    background: url("../../../public/img/chb_fill.png") no-repeat center;
}


.rotateslider-container .rotateslider-item.now {
    z-index: 1;
    opacity: 1;
    -webkit-filter: blur(0px);
    filter: blur(0px);
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%) scale(1);
    -ms-transform: translateY(-50%) translateX(-50%) scale(1);
    transform: translateY(-50%) translateX(-50%) scale(1);
}

.pc-flex-center-mb-start {
    display: flex;
    justify-content: flex-start;
}

.rotateslider-container .arrow {
    width: 53px;
    height: 53px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: auto 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
}

.rotateslider-container .arrow.left {
    left: 0;
    transform: rotateY(180deg);
}

.rotateslider-container .arrow.right {
    right: 0;
}

.gz_header .header {
    box-shadow: none;
}

.gz_b01_b1 {
    margin-top: 90px;
}

.gz_b01_tx1 {
    color: #00af49;
    font-weight: 600;
    font-size: 45px;
    word-break: normal;
}

.gz_b01_tx2 {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    word-break: normal;
}

.gz_b01_b2 {
    margin-top: 100px;
}

.gz_b02 .container {
    position: relative;
}

.gz_b02_b1 {
    position: absolute;
    left: -230px;
}

.gz_b02_tx1 {
    font-size: 38px;
    line-height: 53px;
    margin-bottom: 32px;
    font-weight: 700;
}

.gz_b02_b2 img {
    max-width: 200%;
    margin-left: -120px;
    position: relative;
}

.gz_b02_b3 {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.gz_b02_tx2 {
    margin-right: 30px;
    color: #00af49;
    font-size: 30px;
    line-height: 66px;
    width: 70px;
    height: 70px;
    background: #fff;
    text-align: center;
    border-radius: 100%;
    position: relative;
    border: 3px solid #00af49;
    flex: none;
}

.gz_b02_tx2:after {
    content: '';
    display: block;
    position: absolute;
    height: 35px;
    left: 31px;
    width: 2px;
    background: #00af49;
    bottom: -35px;
}

.gz_b02_tx3 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
}

.gz_b02_tx2_last:after {
    display: none;
}

.gz_b02_tx4 {
    font-size: 12px;
    line-height: 20px;
}

.gz_b02_b4 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.gz_b02_rt {
    margin-top: 20px;
    z-index: 2;
    position: relative;
}

.gz_b03 {
    background: url("../../../public/img/gz_bg4.png") no-repeat center bottom / cover;
    padding: 65px 0 40px;
    margin-top: -65px;
}

.gz_b03_in {
    border: 3px solid #00af49;
    padding: 30px 70px;
    background: #fff;
}

.payment-proposal {
    display: flex;
    gap: 10px;
}

.payment-proposal h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 2.2em;
    margin-bottom: 10px;
    text-align: center;
    max-width: 200px;
    font-size: 12px;
}

.payment-proposal button {
    display: block;
    max-width: 200px;
    width: 100%;
    margin-bottom: 10px;
}

.gz_b03_tx1 {
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
}

.paid_proposals>* {
    width: calc(20% - 5px);
    min-width: 150px;
}

.paid_proposals {
    gap: 5px;
}

.gz_b04 {
    background: url("../../../public/img/gz_bg5.png");
    padding: 50px 0 40px;
}

.md-tt {
    margin-right: 10px;
    font-size: 14px;
    width: 35%;
    margin-left: 7%
}

.fr-count {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.fr-count:before {
    margin-right: 10px;
    content: url("../../../public/img/fluent.svg");
    width: 23px;
    height: 23px;

}

.gz_b04_b1 {
    border: 1px solid #c2d4d7;
    padding: 12px 25px;
    background: #fff;
    margin-bottom: 20px;
}

.gz_b04_b1 .col10 {
    padding: 0 0 0 15px;
}

.gz_b04_b1 .col3 {
    padding: 0 15px 0 0;
}

.slider-wrap {
    max-width: 50vw;
    width: 50vw;
}

.gz_b04_tx1 {
    letter-spacing: 0;
    font-weight: 600;
    color: #000;
    font-size: 12px;
    text-decoration: underline;
}

.checkbox-fsn {
    width: 25px;
    height: 25px;
}

.gz_b04_tx2 {
    padding-left: 22px;
    background: url("../../../public/img/gz_ico_eye.png") no-repeat left center;
    font-weight: 500;
    font-size: 12px;
}

.slider-wrap {
    width: 50vw;
    max-width: 50vw;
}

.gz_b04_tx3 {
    margin-left: 7px;
    padding-left: 22px;
    background: url("../../../public/img/gz_ico_say.png") no-repeat left center;
    font-weight: 500;
    font-size: 12px;
}

.gz_b04_b2 {
    display: flex;
}

.gz_b04_b4 {
    letter-spacing: 0;
    color: #5e5e5e;
    font-size: 12px;
    font-style: italic;
    margin: 15px 0;
}

.gz_b04_tx4 {
    font-size: 12px;
    letter-spacing: 0;
}

.gz_b04_tx4 span {
    font-weight: 500;
}

.gz_b04_tx5 {
    padding: 5px 15px;
    border-radius: 100px;
    font-size: 10px;
    text-transform: uppercase;
    color: #ffffff;
    background: #ffae00;
}

.gz_b04_tx7 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: -10px;
}

.gz_b04_tx8 {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 10px;
}

.gz_b04_img {
    margin-top: 40px;
}

.gz_b04_b8 {
    margin-top: 50px;
}

@media screen and (max-width:768px) {
    .ada_con .col8 {
        width: 100% !important;
    }
    .mb-no-flex {
        
        display: block !important;
    }

    .new_project {
        /*margin-left: 155px;*/
    }

    .select-font>select {
        font-size: 20px !important;
    }

    .gz_b04_b8 {
        margin-top: 30px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:425px) {
    .select-font>select {
        font-size: 10px !important;
    }

    .gz_b04_b8 {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    .new_project {
        /*margin-left: 22px;*/
    }
}

.gz_b04_b8 .btn2,
.gz_b06_b3 .btn2 {
    padding: 15px 50px;
}

.gz_b05_b01 {
    position: relative;
    overflow: hidden;
    height: 270px;
}

.gz_b05_b01:hover .line-img {
    background: black;
}

.category-image {
    width: 20%;
}

.gz_b05_b01_in1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 60px 30px 0;
}

.line-img {
    background: rgba(64, 72, 131, 0.50);
    width: 72px;
    margin: 10px 0;
    height: 2px;
}

.gz_b05_tx8 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    text-align: center;
}

.gz_b05_b01_in2 {
    position: absolute;
    background: linear-gradient(99deg, rgb(0, 162, 69) 0%, rgb(2, 210, 90) 100%);
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    padding: 30px 20px;
    transition: .2s;
}

.gz_b05_tx2 {
    font-weight: 700;
    color: #ffffff;
    font-size: 18px;
    margin-bottom: 18px;
}

.gz_b05_tx2:hover {
    text-decoration: underline;
}

.gz_b05_b01:hover {}

.gz_b05_b01:hover .gz_b05_b01_in2 {
    top: 0;
}

.gz_b05_tx3 {
    padding-left: 20px;
}

.gz_b05_tx3 li {
    color: #ffffff;
    list-style: circle;
    margin-bottom: 15px;
}

.gz_b05_tx3 li a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
}

.gz_b05_tx3 li a:hover {
    text-decoration: underline;
}


.gz_b05 {
    padding: 90px 0 40px;
}

.gz_b05_tx1 {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 100px;
}

.gz_b05_img2 {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.gz_b05_b07 {
    margin-top: 60px;
}

.gz_b05_b07 .btn3 {
    margin-left: 15px;
}

.gz_b06 {
    padding: 40px 0 160px;
    background: url("../../../public/img/gz_bg7.png") no-repeat center bottom / cover;
}

.gz_b06_tx1 {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 30px;
}

.gz_b06_tx2 {
    font-size: 15px;
    line-height: 23px;
}

.gz_b06_tx3 {
    padding-left: 75px;
    background: url("../../../public/img/gz_b05_ico_prot.png") no-repeat left center;
    min-height: 75px;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
}

.gz_b06_b3 {
    margin-top: 50px;
}

.gz_b07 {
    background: url("../../../public/img/gz_bg8.png");
    padding: 0 0 65px;
}

.gz_b07_img {
    position: relative;
    margin-top: -80px;
}

.gz_b07 .gz_b06_tx2 {
    max-width: 600px;
}

.gz_b06_tx2+.gz_b06_tx2 {
    margin-top: 5px;
}

.gz_b08 {
    padding: 0 0 30px;
    background: url("../../../public/img/gz_bg8.png");
}

.gz_b08_img {
    max-width: 200%;
    position: relative;
    left: -200px;
}

.gz_b09 {
    padding: 0 0 80px;
    background: url("../../../public/img/gz_bg8.png");
}

.footer {
    background: #ecf6f7;
    padding: 30px 0 50px;
}

.ft_tx1 {
    color: #616161;
    font-size: 11px;
    margin: 12px 0 15px;
}

.ft_tx2 {
    color: #616161;
    font-size: 11px;
}

.ft_tx4 {
    color: #616161;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 600;
}

.ft_tx5 li {
    margin-bottom: 10px;
}

.ft_ln {
    font-size: 12px;
    margin-top: 12px;
}

.ft_ln a {
    color: #616161;
}


.ft_tx5 a {
    color: #616161;
    font-size: 12px;
}

.gi_page .gz_b01 {
    background: url("../../../public/img/gi_bg1.png") no-repeat right top;
}

.gi_page .gz_b03 {
    padding: 135px 0 40px;
}

.gi_page .gz_b07_img {
    margin-top: -30px;
}

.gi_b10 {
    padding: 0 0 120px;
    background: url(../../../public/img/gz_bg8.png);
}

.tariff-wrap {
    margin-left: 20px;
    border: #0b9d54 solid 2px;
    padding: 20px;
}

.gi_b10_tx1 {
    text-align: center;
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 90px;
}

.gi_b10_b1 {
    text-align: center;
}

.gi_b10_tx2 {
    font-size: 18px;
    margin-top: 30px;
    font-weight: 500;
}

.gi_b10_b2 {
    margin-top: 90px;
    text-align: center;
}

.hd_notice {
    background: url("../../../public/img/ico_bell.png") no-repeat center;
    height: 21px;
    width: 15px;
    position: relative;
    cursor: pointer;
    margin-right: 25px;
}

.hd_notice span {
    background: #00af49;
    display: block;
    line-height: 20px;
    text-align: center;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 11px;
    color: #ffffff;
    right: -10px;
    top: -10px;
}

.hd_message {
    background: url("../../../public/img/ico_message.svg") no-repeat center;
    height: 36px;
    width: 30px;
    position: relative;
    cursor: pointer;
    margin-right: 25px;
}

.hd_message span {
    background: #ffaf00;
    line-height: 20px;
    text-align: center;
    display: block;
    position: absolute;
    width: 8px;
    height: 9px;
    border-radius: 100%;
    font-size: 11px;
    color: #ffffff;
    right: 2px;
    top: -1px;
    outline: 1px white solid;

}

.hd_status {
    background: #85dda2;
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff;
    font-size: 13px;
    margin-right: 10px;
    float: right;
    height: 2em;
}

@media screen and (max-width:1920px) {
    .hd_status_list {
        /*margin-top: -20px;*/
        position: absolute;
        right: 21%;
    }
}

@media screen and (max-width:1700px) {
    .hd_status_list {
        right: 17%;
    }
}

@media screen and (max-width:1500px) {
    .hd_status_list {
        right: 13%;
    }
}

@media screen and (max-width:1300px) {
    .hd_status_list {
        right: 7%;
    }
}

#error-buy {
    color: red;
    font-size: 0.7rem;
}

@media screen and (max-width:1200px) {
    .hd_status_list {
        /*margin-top: -20px;*/
        position: absolute;
        right: 6%;
    }
}

@media screen and (max-width:306px) {
    .hd_status_list {
        /*margin-top: -20px;*/
        position: absolute;
        right: 0;
    }
}

.hd_ava {
    border-radius: 100%;
    height: 45px;
    width: 45px;
    margin-right: 20px;
    overflow: hidden;
}

.hd_ava img {
    display: block;
}

.mn_burger2 {
    border-radius: 3px;
    /*border: 2px solid #dbdde8;*/
    height: 30px;
    width: 30px;
}

.dropdown_right .dropdown_menu {
    left: auto;
    right: 0;
}

.ar_page {
    background: url("../../../public/img/ar_bg1.png") no-repeat right 160px, #fafdfd;
}

.breadcrumbs {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
}

.breadcrumbs li a {
    font-size: 14px;
    color: #000;
}

.breadcrumbs li {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #404883;
    font-size: 14px;
}

.breadcrumbs li:after {
    content: '';
    display: inline-block;
    background: url("../../../public/img/bread_arr.png") no-repeat center;
    width: 12px;
    height: 12px;
    margin: 0 12px;
}

.breadcrumbs li:last-child:after {
    display: none;
}

.ar_b01 {
    text-align: center;
    padding: 50px 0 0;
}

h1 {
    font-size: 38px;
}

.ar_tx1 {
    max-width: 970px;
    margin: 60px auto;
    font-size: 14px;
    line-height: 24px;
}

.ar_b02_tx1 {
    color: #00af49;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 40px;
    font-weight: 700;
}

.ar_b03 {
    padding: 100px 0 0;
}

.ar_b03_tx1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 53px;
    margin-bottom: 110px;
    text-align: center;
}

.ar_b03_b1 {
    padding: 20px 20px 20px 70px;
    background: url("../../../public/img/ar_hand.png") no-repeat 20px center;
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.11);
    font-size: 14px;
    line-height: 20px;
    min-height: 110px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.ar_b03_b2 {
    display: flex;
    background: #00af49;
    min-height: 110px;
    justify-content: center;
    align-items: center;
}

.ar_b03_b2 span {
    font-size: 25px;
    font-weight: 700;
    color: #ffffff;
    margin-right: 25px;
}

.ar_b04 {
    padding: 100px 10px 0;
    background: url("../../../public/img/ar_bg3.png") no-repeat top center / cover;
}

.ar_b04_tx1 {
    font-size: 38px;
    font-weight: 700;
    line-height: 53px;
    margin-bottom: 110px;
    text-align: center;
}

.ar_b04_b1 {
    border-top: 3px solid #e3e4ed;
    padding: 30px 0 30px 110px;
    background: url("../../../public/img/ar_check.png") no-repeat 25px 30px;
}

.ar_b04_tx3 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    margin: 15px 0;
}

.ar_b04_tx4 {
    font-size: 14px;
    line-height: 20px;
}

.ar_b05 {
    padding: 100px 0;
}

.tx_38_53 {
    font-size: 38px;
    line-height: 53px;
    font-weight: 700;
}

.tx_14_20 {
    font-size: 14px;
    line-height: 20px;
    word-break: break-word;
}

.ar_b05_tx1 {
    margin-bottom: 45px;
}

.ar_b06_tx1 {
    margin-bottom: 90px;
}

.ar_b06_tx3 {
    font-style: italic;
}

.ar_b06_b1 {
    border: 2px solid #00af49;
    padding: 45px 40px;
    height: 100%;
}

.ar_b06_tx2 {
    margin-bottom: 30px;
}

.ar_b06 {
    padding: 0 0 120px;
}

.ar_b06_tx4 {
    font-size: 25px;
    margin-bottom: 30px;
}

.ar_b06_tx5 {
    font-weight: 500;
}

.bs_b01 {
    background: #fafdfd;
}

.bs_b01_tx1 {
    margin: 35px 0 70px;
    text-align: center;
}

.bs_b02_tx1 {
    color: #00af49;
    font-size: 30px;
    margin-bottom: 60px;
    font-weight: 700;
}

.bs_b02_b1 {
    padding: 25px 25px 45px;
    border-top: 3px solid #dee2eb;
}

.bs_b02_tx4 {
    font-weight: 600;
    font-size: 25px;
    margin-left: 30px;
}

.bs_b02_b2 {
    margin-bottom: 20px;
}

.bs_b02+.bs_b02 {
    margin-top: 70px;
}

.bs_b04_tx1 {
    text-align: center;
    padding: 0 0 70px;
}

.bs_b04 {
    background: linear-gradient(to right, #00af49 0%, #00af49 50%, #404883 50%, #404883 100%);
    padding: 50px 0;
}

.bs_b04_tx2 {
    font-size: 30px;
    margin-bottom: 35px;
    color: #ffffff;
    font-weight: 700;
}

.bs_b04_tx3 {
    color: #fff;
    margin-bottom: 20px;
}

.bs_b04_b4 {
    border-collapse: collapse;
}

.bs_b04_b4 th {
    width: 50%;
    font-weight: 600;
    background: #009d42;
    color: #fff;
    font-size: 18px;
    line-height: 20px;
    padding: 11px 15px;
    text-align: left;
    border-top: 3px solid #66c48e;
    border-bottom: 3px solid #66c48e;
}

.bs_b04_b4 td {
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 17px 15px;
    text-align: left;
    border-bottom: 1px solid #66c48e;
}

.bs_b04_b4 tr:nth-child(2n+1) td {
    background: #009d42;
}

.bs_b04_tx7 {
    margin: 30px 0;
}

.bs_b04_tx7 li {
    padding-left: 30px;
    background: url("../../../public/img/bs_li.png") no-repeat left center;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 18px;
}

.bs_b04_left {
    padding-right: 30px;
}

.bs_b04_right {
    padding-left: 30px;
}

.bs_b04_img8 {
    margin-left: 80px;
}

.bs_b05 {
    padding: 100px 0;
}

.bs_b05_tx1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    margin-bottom: 55px;
}

.bs_b05_tx2 {
    margin-bottom: 20px;
}

.bs_b05_in {
    margin-top: 30px;
    border: 3px solid #00af49;
    padding: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
}

.btn3 {
    display: inline-block;
    outline: none;
    padding: 15px 35px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .1s;
    border-radius: 100px;
    background: #404883;
    /*box-shadow: 0 3px 6px 2px #34203e;*/
}

.btn3:focus {
    box-shadow: 0 3px 6px 0 #34203e;
}

.btn3.active,
.btn3:hover {
    background: #303554;
}

.btn3+.btn3 {
    margin-left: 20px;
}

.vi_b01 {
    background: #fafdfd;
}

.vi_b01_b3 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 50px 30px;
    margin: 60px 0;
}

.vi_b01_b3 ul {
    columns: 4;
    padding-left: 22px;
    margin-bottom: 40px;
}

.forError ul {
    margin-bottom: 0;
}

.vi_b01_b3 li {
    list-style: circle;
    color: #989898;
    transition: .2s;
    margin-bottom: 15px;
    cursor: pointer;
}

.vi_b01_b3 li span,
.vi_b01_b3 li h2 {
    font-weight: 500;
    font-size: 14px;
    transition: .2s;
    color: #000000;
}

.vi_b01_b3 li:hover,
.vi_b01_b3 li:hover span {
    color: #00af49;
}

.vi_b01_b3_in .btn2 {
    display: inline-flex;
    align-items: center;
    /*margin-bottom: 15px;*/
}

.vi_b01_b3_in .btn2 img {
    /*margin-right: 15px;*/
}

.vi_b01_b3_a {
    color: #404883;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
}

.colP {
    padding-right: 0;
}

.vi_b05_b1 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 20px 40px 40px;
    margin: 0 0 35px;
}

.vi_b05_b1 .col2 {
    width: 13%;
}

.vi_b05_b1 .col10 {
    width: 87%;
}

.vi_b05_tx1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 45px;
}
.freelancer-list-item__info {
    color:black;
    font-weight: 500;
    font-size: 16px;
    word-break: initial;
    letter-spacing: 0;
}
.freelancer-list-item__fullName {
    color: #2D6163;
    font-size: 16px;
    font-weight: 600;
}
.price {
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
}
.price__title {
    font-weight: 500;
    color: #848383;
    font-size: 14px;
}
.price__value {
    font-weight: 600;
    color: black;
    font-size: 20px;
}
.vi_b05_ava {
    border-radius: 100%;
    max-width: 117px;
    min-width: 117px;
    height: 117px;
}

.add-photo {
    width: fit-content;
    position: relative;
}

.time-pro,
.time-profi {
    color: #444444;
    font-size: 14px;
    margin: 3px 0 0 0;
    position: absolute;
}

.hide_before:before {
    display: none;
}

.add-photo:before {
    content: "";
    width: 2rem;
    height: 2rem;
    position: absolute;
    background: url("../../../public/img/add-photo.svg") no-repeat;
    background-size: 2rem;
    bottom: 0;
    right: 0;
    cursor: pointer;
}

.vi_b05_tx3 {
    display: flex;
    margin-bottom: 10px;
}

.vi_b05_tx4 {
    font-size: 14px;
}

.vi_b05_tx4 a {
    font-size: 18px;
    font-weight: 700;
}

.vi_b05_tx_plus {
    color: #00af49;
}

.vi_b05_tx_minus {
    color: #df0101;
}

.vi_b05_b4 {
    font-size: 14px;
    margin-bottom: 20px;
}

.vi_b05_b6 {
    font-size: 16px;
    min-height: 62px;
    background: #e3e4ed;
    position: relative;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    padding: 0 25px;
    color: #404883;
    text-transform: uppercase;
}

.vi_b05_b6:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    margin-top: -10px;
    border-right: 10px solid #e3e4ed;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

.title-link {
    font-size: 18px;
    font-weight: 700;
    color: #000;
    margin-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.freelancer-list-item__profile-info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.freelancer-list-item__profile-info > p:first-child {
    flex: 1 1 100px;
}
.vi_b05_tx8 {
    min-height: 50%;
    padding: 8px;
    font-size: 13px;
    font-weight: 600;
    word-break: break-all;
}

.trancle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.trancle-3 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
}

.vi_b05_tx8 .portfolio-info {
    display: flex;
    align-items: center;
}

.vi_b05_tx8 a {
    color: #000000;
}

.vi_b05_b7 {
    border: 1px solid #d9dae6;
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
}

.vi_b05_b7 a img {
    max-width: 100%;
}

.vi_b05_tx9 {
    padding: 12px 8px;
    display: flex;
    justify-content: space-between;
}

.vi_b05_tx9 p {
    padding-left: 25px;
    /*background:url("../../../public/img/vi_eye.png") no-repeat left center;*/
    font-size: 13px;
    font-weight: 600;
}

.vi_b06 {
    padding: 20px 0 50px;

}

.vi_b06 ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vi_b06 li {
    margin: 0 3px;
}

.vi_b06 li:first-of-type {
    margin-right: 15px;
}

.vi_b06 li:last-child {
    margin-left: 15px;
}

.vi_b06 li span {
    color: #00af49;
    font-size: 14px;
    text-decoration: none;
}

.vi_b06 li a,
.vi_b06 li span.vi_b06_sp1 {
    color: #989898;
    font-size: 14px;
    text-decoration: none;
}

.vi_b06 li a {
    text-decoration: underline;
}

.vp_b02 {
    margin-bottom: 40px;
}

.vp_b01 {
    background: #fafdfd;
    padding-bottom: 90px;
    min-height: 55vh;
}

.vp_b03_tx1 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 23px;
}

.st_checkbox {
    display: none;
}

.inversedCheckbox:checked+label:before {
    background: url("../../../public/img/chb_empty.png") no-repeat center !important;
}

.inversedCheckbox+label:before {
    background: url("../../../public/img/chb_fill.png") no-repeat center !important;
}

.st_checkbox+label:before {
    content: '';
    background: url("../../../public/img/chb_empty.png") no-repeat center;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;

}

.st_checkbox+label {
    position: relative;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 30px;
}

.st_checkbox+label img {
    margin-left: 10px;
}

.st_checkbox:checked+label:before {
    background: url("../../../public/img/chb_fill.png") no-repeat center;
}

.vp_b03_b1 {
    padding: 35px 20px;
    background: #fff;
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
}

/*.vp_b03_b1_line {*/
/*    margin-bottom: 12px;*/
/*}*/

.vp_b03_tx2 {
    margin-top: 40px;
}

.st_input {
    width: 100%;
    border-radius: 3px;
    position: relative;
    /*z-index:2;*/
    background: #fff;
    font-size: 14px;
    border: 1px solid #9fa3c1;
    color: #000;
    display: block;
    overflow: hidden;
    text-align: left;
    text-overflow: clip;
    outline: 0;
    letter-spacing: 1px;
    padding: 15px 30px 15px 20px;
}

.wizard-sidebar .step5 .text:last-child {
    margin-bottom: 0;
}

.st_input::placeholder {
    text-align: left;
    color: rgba(159, 163, 193, 1);
}

.st_textarea::placeholder {
    color: rgba(159, 163, 193, 1);
}

.seo-buttons a {
    width: 300px;
    height: 58px;
    display: block;
}

.seo-buttons a button {
    width: 100%;
}

@media screen and (max-width:425px) {
    .st_input {
        padding: 15px 10px 15px 10px;
    }
}

.st_input:focus {
    border-color: #404883;
    box-shadow: 0 0 0 1px #404883 inset
}

.vp_b03_b1_btn {
    padding: 30px 0 10px;
}

.vp_b03_b1_a {
    color: #404883;
    font-size: 13px;
    text-decoration: underline;
}

.vp_b03_b2 {
    padding: 35px 0 45px;
    background: #fff;
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
}

.vp_b03_b3 {
    border-bottom: 1px solid #d9dae6;
}

.vp_b03_b3_tx1 {
    font-weight: 500;
    display: inline-flex;
    padding-bottom: 25px;
    font-size: 14px;
    text-transform: uppercase;
    border-bottom: 3px solid #0cbc46;
}

.vp_b03_b5 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
    margin-bottom: 20px;
    background: #fff;
    border: 3px solid transparent;
}

.marked {
    border: 3px solid #fab114;
}

.vp_b03_b5.marked-new {
    border: 3px solid #85dda2;
    overflow: hidden;
}

.vp_b03_b5.marked-new>.new {
    display: flex;
    justify-content: center;
}

.vp_b03_b5.marked-new>.new {
    color: #85dda2;
    display: flex;
    justify-content: center;
    position: relative;
}

/*.vp_b03_b5.marked-new > .new > div {*/
/*    !*position: absolute;*!*/
/*    top:-45px;*/
/*    border: 3px solid #00af49;*/
/*    transform: rotate(45deg);*/
/*    width: 80px;*/
/*    height: 80px;*/
/*}*/
.vp_b03_b5.marked-new>.new>p {
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    left: 4px;
    top: 13px;
    transform: rotate(-33deg);
}

.vp_b03_b6 {
    padding: 25px 20px;
    border-bottom: 1px solid #d9dae6;
}

@media screen and (max-width:1920px) {
    .vp_right {
        padding-right: 25px;
    }
}

@media screen and (max-width:425px) {
    .vp_right {
        padding-right: 25px;
    }
}

.vp_b03_b4_tx3,
.vp_b03_b4_tx3 h2 {
    color: #404883;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
}

.vp_b03_b4_tx6 {
    letter-spacing: 0;
    font-size: 12px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 25px;
}

.vp_b03_b4 {
    padding: 30px 30px 0;
}

.vp_b03_b7 {
    padding: 15px 20px;
}

.vp_b03_b7_tx1 {
    font-size: 14px;
    font-weight: 600;
    margin-right: 12px;
}

.vp_b03_b7_tx2 {
    color: #00af49;
    font-size: 20px;
    font-weight: 600;
}

@media screen and (max-width:425px) {
    .vp_b03_b7 {
        padding: 15px 1px;
    }

    .vp_b03_b7_tx2 {
        font-size: 16px;
    }
}

.vp_b03_b7_sym_OCWP {
    font-size: 12px;
    background: url("../../../public/img/vp_ico1.png") no-repeat left center;
    height: 21px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    padding-left: 30px;
    margin-right: 15px;
}

.vp_b03_b7_sym_OUP {
    font-size: 12px;
    background: url("../../../public/img/vp_ico2.png") no-repeat left center;
    height: 21px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    padding-left: 30px;
    margin-right: 15px;
}

.vp_b03_b7_tx6 {
    font-weight: 500;
    font-size: 12px;
    opacity: .7;
}

@media screen and (max-width:425px) {
    .vp_b03_b7_tx6 {
    }
}

.vp_b03_b7_tx6 span {
    margin-left: 8px;
}

.vp_b03_b15 ul {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vp_b03_b15 li {
    margin: 0 3px;
}

.vp_b03_b15 li a {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid #00af49;
}

.vp_b03_b15 li.active a {
    background: #00af49;
}

.kf_b03_b6 {
    margin-bottom: 30px;
}

.kf_b01_tx1 {
    margin-top: 30px;
    text-align: center;
}

.kf_b01 {
    background: #fafdfd;
    padding: 1px 0;
}

.kf_b02_tx1 {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-weight: 500;
}

.kf_b03_tx1 {
    font-size: 14px;
}

.kf_b05_tx1 {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 500;
}

.kf_b05_b1,
.kf_b05_b1 a {
    color: #404883;
    font-size: 14px;
    font-weight: 500;
}

.kf_b05_b1 {
    margin-bottom: 30px;
}

.kf_b07 {
    padding: 80px 0;
    background: #00af49;
    margin-bottom: 80px;
}

.kf_b07_hd1 {
    font-weight: 700;
    font-size: 38px;
    color: #fff;
    text-align: center;
}

.kf_b07_iw {
    max-width: 130px;
    margin: 0 auto;
    position: relative;
    bottom: -65px;
    border-radius: 100%;
    overflow: hidden;
}

.kf_b07_b2 {
    background: #fff;
    text-align: center;
    padding: 75px 20px 25px;
}

.kf_b07_b2_tx1 {
    margin-bottom: 15px;
}

.kf_b07_b2_tx1 a {
    font-weight: 700;
    font-size: 18px;
    color: #000;
}

.kf_b07_b2_tx2 {
    font-size: 14px;
    margin-bottom: 25px;
}

.kf_b07_b2_tx3 {
    color: #404883;
    font-size: 16px;
    padding: 8px;
    background: #e3e4ed;
    margin-bottom: 20px;
}

.btn4 {
    display: inline-block;
    outline: none;
    box-shadow: none;
    padding: 15px 30px;
    text-align: center;
    background: #404883;
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid #404883;
    transition: .2s;
    border-radius: 100px;
}

.btn4.active,
.btn4:hover {
    color: #404883;
    background: #fff;
}

.kf_b07_b1 {
    /*margin-top: -10px;*/
}

.kf_b07 .owl-carousel .owl-nav .owl-prev,
.kf_b07 .owl-carousel .owl-nav .owl-next {
    width: 18px;
    height: 30px;
    top: 50%;
    margin-top: -15px;
    position: absolute;
}

.kf_b07 .owl-carousel .owl-nav .owl-prev {
    background: url("../../../public/img/kf_arr_l.png") no-repeat center;
    left: -120px;
}

.kf_b07 .owl-carousel .owl-nav .owl-next {
    background: url("../../../public/img/kf_arr_r.png") no-repeat center;
    right: -120px;
}

.kf_b06 {
    padding: 0 0 50px;
}

.kf_b06_tx1 {
    color: #00af49;
    font-weight: 700;
    font-size: 30px;
}

.kf_b06_tx1 span {
    color: #767676;
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px;
}

.on_b01_tx1 {
    margin: 30px 0;
}

.on_b01_tx2 {
    font-size: 17px;
    line-height: 25px;
    text-align: center !important;
}

.on_b01_tx2 b {
    color: #00af49;
}

.on_b01 {
    background: #fafdfd;
}

.on_b02 {
    position: relative;
    min-height: 890px;
    margin: 15px auto 0;
}

.on_b02_in1 {
    position: absolute;
    background: url("../../../public/img/on_img1.png") no-repeat center;
    height: 582px;
    width: 589px;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 140px 120px 0;
    margin: auto;
}

.on_b02_in1 p {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 20px;
}

.on_b02_tx1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 15px;
}

.on_b02_abs {
    position: absolute;
    max-width: 260px;
}

.on_b02_abs1 {
    left: 240px;
    top: 30px;
}

.on_b02_abs2 {
    right: 120px;
    top: 110px;
}

.on_b02_abs3 {
    right: 0;
    top: 490px;
    max-width: 310px;
}

.on_b02_abs4 {
    left: 420px;
    top: 730px;
    max-width: 290px;
}

.on_b02_abs5 {
    left: 30px;
    top: 390px;
    max-width: 280px;
}

.popup_accept_title {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 1px;
    text-align: left;
    color: #153F41;
}

.on_b03_tx1 {
    font-size: 38px;
    color: #00af49;
    text-align: center;
    font-weight: 700;
}

.on_b03 {
    padding: 130px 0 115px;
    background: url("../../../public/img/on_bg1.png") no-repeat top center / contain;
}

.on_b04 {
    padding-bottom: 35px;
}

.ps_b01 {
    background: #fafdfd;
    padding: 1px 0;
}

.bs_b04_b1 {
    padding: 25px 25px 45px;
}

.ps_b01_tx1 {
    font-size: 30px;
    font-weight: 700;
    margin: 30px 0 30px;
    word-break: break-word;
}

.bs_b04_b2 {
    padding: 25px 25px 45px;
}

.ps_b02 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 20px 40px 40px;
    margin: 0 0 35px;
}

.ps_b02_tx2 {
    font-size: 14px;
    color: #5f5f5f;
    margin-bottom: 10px;
}

.ps_b02_b2 p {
    font-weight: 500;
    font-size: 14px;
}

.ps_b02_b2 {
    padding-bottom: 30px;
    margin-bottom: 20px;
    border-bottom: 1px solid #cfd1e0;
}

.ps_b02_b2 span {
    color: #404883;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
}

.ps_b02_b2 a {
    color: #404883;
}

.ps_b02_b3 span {
    color: #727272;
    font-size: 14px;
    font-weight: 400;
}

.ps_b02_b3 {
    margin-bottom: 15px;
}

.ps_b02_b3 p {
    font-size: 14px;
    font-weight: 500;
}

.ps_b02_b4 {
    /*padding-bottom: 30px;*/
    margin-bottom: 20px;
    /*border-bottom: 1px solid #cfd1e0;*/
}

.ps_b02_b4 p {
    font-size: 18px;
    font-weight: 500;
}

.ps_b02_b4 span {
    color: #00af49;
    font-weight: 600;
}

.ps_b02_b5 {
    margin-bottom: 35px;
}

.link1 {
    font-size: 13px;
    color: #404883;
    text-decoration: underline;
    font-weight: 600;
}

.ps_b02_b6 a {
    margin-right: 30px;
}

.ps_b03_tx1 {
    text-align: center;
    color: #00af49;
    margin: 40px 0;
    font-size: 30px;
    font-weight: 600;
}

.ps_b02 .col2 {
    width: 13%;
}

.ps_b02 .col10 {
    width: 87%;
}

.ps_b03_b1 .col0 {
    width: 20%;
}

.ps_b03_b3 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 30px 10px 20px;
    margin: 0 0 35px;
}

.ps_b03_iw {
    margin: 0 auto 25px;
}

.ps_b03_tx3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
}

.img_block {
    display: block;
}

.ps_b03_tx4 {
    font-size: 13px;
    line-height: 20px;
    min-height: 140px;
}

.ps_b03_tx5 {
    font-size: 20px;
    font-weight: 600;
    color: #404883;
    min-height: 55px;
}

.ps_b03_tx5 span {
    font-size: 14px;
    display: block;
}

.ps_b03_tx6 .btn2 {
    padding: 15px 0;
    width: 100%;
}

.ps_b04_state {
    position: relative;
    padding-left: 45px;
    height: 34px;
    color: #404883;
    font-weight: 600;
    font-size: 14px;
    line-height: 34px;
    background-position: left center;
    background-repeat: no-repeat;
}

.ps_b04_state span {
    background: #ffaf00;
    line-height: 20px;
    text-align: center;
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    font-size: 11px;
    color: #ffffff;
    left: 22px;
    top: -7px;
}

.ps_b04_state1 {
    background-image: url("../../../public/img/ps_ico1.png");
}

.ps_b04_state2 {
    background-image: url("../../../public/img/ps_ico2.png");
}

.ps_b04_state3 {
    background-image: url("../../../public/img/ps_ico3.png");
}

.ps_b04_state4 {
    background-image: url("../../../public/img/ps_ico4.png");
}

.ps_b04_state5 {
    background-image: url("../../../public/img/ps_ico5.png");
}

.ps_b04_state6 {
    background-image: url("../../../public/img/ps_ico6.png");
}

.ps_b04_b1_ul {
    display: flex;
    padding-bottom: 40px;
}

.ps_b04_b1_ul a {
    margin-right: 30px;
    cursor: pointer;
}

.ps_b04_tx1 {
    color: #404883;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 28px;
    margin: 60px 0 40px;
}

.ps_b05_ln1 {
    margin-top: 12px;
    text-align: right;
}

.ps_b05_ln1 .link1 {
    white-space: nowrap;
    font-size: 14px;
}

.ps_b05_lbl {
    position: relative;
    height: 46px;
    color: #404883;
    font-weight: 600;
    font-size: 14px;
    line-height: 46px;
    padding: 0 15px 0 55px;
    background-position: 10px center;
    background-repeat: no-repeat;
    transition: .2s;
    cursor: pointer;
    border-radius: 5px;
}

.ps_b05_lbl.active,
.ps_b05_lbl:hover {
    background-color: #ececf2;
}

.ps_b05_b5 {
    margin-top: 20px;
}

.ps_b06 {
    margin-top: 15px;
}

.ps_b05 .ps_b02 {
    margin-bottom: 0;
}

.ps_b06_b2 {
    padding: 20px 30px 50px;
    background: #ecf6f7;
    position: relative;
}

.ps_b06_b2:before {
    content: '';
    position: absolute;
    left: -10px;
    top: 25px;
    border-right: 10px solid #ecf6f7;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.ps_b06_tx1 {
    font-size: 14px;
    font-weight: 600;
    margin-right: 15px;
}

.ps_b06_tx2 {
    font-size: 12px;
}

.ps_b06_tx3 {
    color: #404883;
    font-size: 14px;
    text-decoration: underline;
}

.ps_b06_tx5 {
    font-size: 13px;
    line-height: 20px;
    margin-top: 20px;
}

.btn5 {
    display: inline-block;
    padding: 15px 35px;
    background: #fff;
    color: #000000;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    border: 3px solid #00af49;
    transition: .1s;
    cursor: pointer;

}

.btn5 span {
    color: #00af49;
    transition: .2s;
    font-size: 18px;
    font-weight: 700;
}

.btn5:hover {
    background: #00af49;
    color: #fff;
}

.btn5:hover span {
    color: #ffffff;
}

.ps_b06_b9 {
    margin-top: 30px;
}

.ps_b07 {
    padding: 8px 30px 8px 0;
}

.ps_b07 .link1 {
    font-size: 14px;
    font-weight: 400;
}

.ps_b05 {
    margin-bottom: 40px;
}

.ps_b06 .col1 {
    width: 6%;
    padding-top: 10px;
}

.ps_b06_b3 {
    padding: 20px 30px 50px;
    background: #e3e4ed;
    position: relative;
}

.ps_b06_b3:before {
    content: '';
    position: absolute;
    right: -10px;
    top: 25px;
    border-left: 10px solid #e3e4ed;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.ps_b06_old {
    display: none;
}

.ps_b06_write {
    display: none;
}

.st_textarea {
    font-size: 13px;
    padding: 15px 20px;
    border: 1px solid #9da2c0;
    background: #fff;
    height: 80px;
    width: 100%;
    border-radius: 4px;
    resize: none;
}

.st_textarea:focus {
    border-color: #404883;
    box-shadow: 0 0 0 1px #404883 inset
}

.ps_b06_b8 {
    margin: 15px 0;
}

.ps_b06_write .ps_b06_b3 {
    padding-bottom: 20px;
}

.ps_b08_tx1 {
    color: #404883;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 35px;
    font-weight: 600;
}

.ps_b08_tx2 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 20px;
}

.ps_b08_b2 {
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    border-radius: 4px;
    background: #404883;
    margin-bottom: 10px;
    display: inline-flex;
}

.ps_b08_b3 {
    font-size: 14px;
    color: #ffffff;
}

.ps_b08_b4 {
    font-size: 30px;
    color: #ffffff;
    margin-left: 30px;
    cursor: pointer;
}

.ps_b08_b9 {
    background: #ececf2;
    padding: 25px;
    margin-top: 50px;
}

.ps_b08_b9_tx1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 16px;
}

.ps_b08_b9_tx1 span {
    color: #00af49;
    font-size: 20px;
    font-weight: 600;
}

.ps_b08_b9_tx2 {
    font-size: 14px;
    margin-bottom: 5px;
}

.ps_b08_b9_tx3 {
    font-size: 14px;
}

.lp_b01 {
    padding: 20px 0 75px;
    background: #fafdfd;
}

.lp_b01_tx1 {
    font-size: 38px;
    margin-bottom: 50px;
    text-align: center;
}

.lp_b01_b1 {
    background: #fff;
    margin: 0 auto;
    max-width: 590px;
    padding: 55px 110px 30px;
    border: 2px solid #2D6163;
    box-shadow: 0 10px 22.08px 1.92px rgba(28, 65, 71, 0.14);
}

.lp_b01_b1 input {}

.lp_b01_b1 .st_checkbox+label {
    font-size: 12px;
    /*margin-top: 20px;*/
}

.ban-info>p:first-child {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
}

.ban-info>p:last-child {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
}

.ban-info>p:last-child>span {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0;
    text-align: center;
}

.lp_b01_tx2 {
    font-size: 16px;
    font-weight: 600;
    margin: 25px 0 20px;
    text-align: center;
}

.lp_b01_tx2>label {
    width: auto;
}

.op_tx1 {
    margin: 30px 0 0;
    text-align: center;
    font-weight: 700;
}

.lp_b01_b2 {
    margin-top: 20px;
}

.lp_b01_b2 p a {
    text-transform: uppercase;
}

.lp_b01_b2 .btn2 {
    margin-bottom: 10px;
    width: 100%;
}

.op_b2_tx1 {
    font-size: 14px;
    text-align: center;
    margin: 40px auto;
    max-width: 830px;
}

.op_b2_tx2 {
    color: #404883;
    font-size: 14px;
    text-align: center;
    margin-bottom: 50px;
}

.op_b3_tx1 {
    color: #00af49;
    font-size: 38px;
    line-height: 45px;
    font-weight: 700;
}

.op_b01 {
    background: #fafdfd;
    padding-bottom: 200px;
}

.op_b3_tx4 {
    margin: 80px 0;
}

.op_b4_b1 {
    box-shadow: 0 0 19.32px 1.68px rgba(28, 65, 71, 0.13);
    background: #fff;
    padding: 30px 5px 30px 30px;
}

.op_b4_iw {
    height: 90px;
    width: 100%;
    position: relative;
    margin-bottom: 40px;
}

.op_b4_iw img {
    position: absolute;
    left: 0;
    bottom: 0;
}

.op_b4_tw {
    height: 50px;
}

.op_b4_tw p {
    font-size: 18px;
    font-weight: 500;
}

.ot_b01 {
    background: #fafdfd;
}

.ot_b01_tx1 {
    margin: 10px 0 50px;
}

.ot_b02 {
    background: #fff;
    margin-bottom: 30px;
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.11);
    border: 1px solid rgba(64, 72, 131, .4);
    padding: 40px 20px;
}

.ot_b02_b1 {
    padding: 17px 0 25px;
    border-bottom: 1px solid #cfd1e0;
}

.ot_b02_tx4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.ot_b02_tx5 {
    color: #696969;
    font-size: 14px;
}

.ot_b02_b2 {
    padding-top: 23px;
}

.ot_b02 .col2 {
    width: 13%;
}

.ot_b02 .col10 {
    width: 87%;
}

.ot_b07 {
    padding: 0 0 70px;
}

/*Modal*/
.modal {
    background: #fff;
    border: 2px solid #2D6163;
    max-width: 760px;
    margin: 50px auto;
    position: relative;
    padding: 65px;
    box-sizing: border-box;
    width: fit-content;
}

.mo_head {
    text-align: center;
    margin-bottom: 50px;
}

.modal-content {
    margin-top: 20px;
}

.modal .close,
.close_modal {
    right: -54px;
    top: 10px;
    position: absolute;
    cursor: pointer;
    height: 36px;
    width: 36px;
    background: #d7d8df url("../../../public/img/modal-close.svg") no-repeat center;
    border-radius: 6px;

}

.portfolio-mb {
    max-width: 100%;

}

.portfolio-mb .vi_b05_tx8 {
    max-width: 80%;
}

.close-portfolio {
    right: 10px;
    bottom: 15px;
    position: absolute;
    cursor: pointer;
    background: url("../../../public/img/mod_close.png") no-repeat center;
    height: 31px;
    width: 31px;
}

.close_modal {
    right: 4px;
    top: 10px;
}

.modal .st_textarea {
    height: 210px;
}

.themodal-lock {
    overflow: hidden;
}

.themodal-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.themodal-overlay>* {
    -webkit-transform: translateZ(0px);
}

.themodal-overlay {
    background: rgba(233, 234, 242, 0.65);
    /* IE6–IE8 */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#7F000000, endColorstr=#7F000000);
    zoom: 1;
}

.modal .st_textarea {
    font-size: 14px;
    letter-spacing: 1px;
}

.mo_tx1 {
    font-weight: 600;
    font-size: 16px;
    margin: 20px 0 15px;
}

.st_input_file {
    width: 1px;
    opacity: 0;
    height: 1px;
    overflow: hidden;
}

.st_input_file+label {
    color: #00af49;
    font-weight: 600;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    background: url("../../../public/img/ot_ico1.png") no-repeat left center;
    padding-left: 25px;
}

.mo_b09 {
    margin: 15px 0;
}

.mo_b09_tx2 {
    color: #323232;
    font-size: 12px;
    margin-bottom: 30px;
    letter-spacing: .7px;
}

.mo_b09_tx2 a {
    cursor: pointer;
    color: #404883;
    letter-spacing: .7px;
}

.pp_b01 {
    background: #fafdfd;
    padding-bottom: 60px;
}

.pp_b01_tx1 {
    margin: 30px 0 50px;
}

.pp_b02 {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 25px;
}

.pp_b02 b {
    color: #00af49;
    font-weight: 600;
}

.pp_b03 {
    padding: 30px 20px;
    border-top: 3px solid #dee2eb;
}

.pp_b03_tx1 {
    font-size: 30px;
    color: #00af49;
    height: 60px;
    width: 60px;
    font-weight: 600;
    border: 3px solid #00af49;
    border-radius: 100%;
    text-align: center;
    line-height: 54px;
    margin-bottom: 20px;
}

.pp_b03_tx1 b {
    color: #00af49;
    font-weight: 600;
}

.pp_b03_tx2 {
    margin: 12px 0 20px;
    font-weight: 600;
    font-size: 25px;
    line-height: 35px;
}

.pp_b03_b2 p {
    word-break: break-word;
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 25px;
}

.ps_b00_tx1 {
    margin: 30px 0 100px;
}

.pv_b01 {
    padding-bottom: 50px;
    background: #fafdfd;
}

.pv_b02 {
    margin: 40px 0 50px;
}

.pv_b02_b1 {
    margin-bottom: 15px;
}

.pv_b03 {
    color: #5f5f5f;
    margin-bottom: 35px;
}

.pv_b04 {
    padding-bottom: 45px;
    border-bottom: 1px solid #cfd1e0;
    font-size: 14px;
}

.pv_b05_tx1 {
    margin: 20px 0;
    color: #00af49;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
}

.pp_b01_green .pp_b03_b2 b {
    color: #00af49;
    font-weight: 600;
}

.prp_b01 {
    background: #fafdfd;
    padding-bottom: 100px;
}

.prp_b02_tx1 {
    font-size: 30px;
    font-weight: 700;
    margin: 20px 0 25px;
}

.prp_b02_b2 {
    margin: 0 0 20px;
}

.prp_b03 {
    font-weight: 700;
    padding: 30px 0 0;
    border-top: 1px solid #cfd1e0;
}

.prp_b03_tx1 {
    color: #00af49;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
}

.prp_b03 .st_textarea {
    height: 200px;
}

.prp_b03_tx2 {
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0 20px;
}

.prp_b04 {
    margin-bottom: 40px;
}

.prp_b06_tx1 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    margin: 50px 0 40px;
}

#popup_free_account {
    max-width: 570px;
    text-align: center;
    padding: 50px 0;
}

.mpf_tx1 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
}

.mpf_tx1_1 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 15px;
}

.mpf_tx1_1 a {
    color: #0cb252;
    text-decoration: underline;
}

.mpf_tx2 {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 500;
}

.mpf_tx4 {
    color: #404883;
    font-size: 15px;
    font-weight: 700;
    text-decoration: underline;
}

.mpf_tx6 {
    text-decoration: line-through;
    color: #989898;
}

.mpf_tx8 {
    font-size: 15px;
    margin-bottom: 5px;
    font-weight: 500;
}

.mpf_tx9 {
    color: #404883;
    font-size: 15px;
    font-weight: 500;
    text-decoration: underline;
}

.os_b01 {
    background: #fafdfd;
    padding: 1px 0 30px;
    min-height: 700px;
}

.os_b01_tx1 {
    margin: 10px 0 50px;
}

.os_b01_b1 p {
    margin-bottom: 20px;
}

.os_b01_tx3 {
    color: #404883;
    font-weight: 600;
}

.rf_b01 {
    background: #fafdfd;
}

.rf_b01_tx1 {
    margin: 20px 0 30px;
    text-align: center;
}

.rf_b02_tx1 {
    font-size: 14px;
    text-align: center;
    max-width: 680px;
    margin: 0 auto 50px;
}

.rf_b02_tx2 {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
}

.rf_b02_tx3 {
    color: #00af49;
    font-size: 30px;
    font-weight: 700;
    margin: 120px 0 55px;
    text-align: center;
}

.rf_b04 {
    border-collapse: collapse;
    width: 100%;
}

.rf_b04 th {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    padding: 23px;
    vertical-align: top;
    border-top: 1px solid #b0b5cc;
    border-bottom: 1px solid #b0b5cc;
}

.rf_b04 td {
    border-top: 1px solid #b0b5cc;
    padding: 23px;
    font-size: 14px;
    vertical-align: top;
}

.rf_b04 tr:last-child td {
    border-bottom: 2px solid #b0b5cc;
}

.rf_b04 td:nth-child(2),
.rf_b04 th:nth-child(2) {
    background: #f2f5f8;
}

.rf_b04 td:nth-child(3),
.rf_b04 th:nth-child(3) {
    /*position: relative;*/
    background: #f0faf6;
}

.rf_b04 td:nth-child(3) span,
.rf_b04 th:nth-child(3) span {
    background: #fff;
    height: 100%;
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    width: 9px;
}

.rf_b06 {
    background: url("../../../public/img/rf_bg01.png") no-repeat top center / contain;
    padding: 1px 0 70px;
}

.rf_b06 td:first-of-type {
    width: 30%;
}

.rf_b06 .rf_b02_tx1 {
    max-width: 870px;
}

.rf_b15 {
    padding: 30px 0 0;
    text-align: right;
    margin-right: 3%;
}



.sc_b01 {
    background: #fafdfd;
    padding: 0 0 60px;
}

.sc_b02_b1 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 40px 20px;
    margin: 0 0 35px;
}

.sc_b02_b2 .st_input {
    margin-bottom: 20px;
}

.sc_b02_b2 .btn2 {
    margin-bottom: 20px;
}

.sc_b02_b5 {
    margin-bottom: 20px;
}

.sc_b02_l {
    padding-left: 50px;
    min-height: 31px;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    color: #000;
    line-height: 14px;
}

.sc_b02_l1 {
    background: url("../../../public/img/sc_ico1.png") no-repeat left center;
}

.sc_b02_l2 {
    background: url("../../../public/img/sc_ico2.png") no-repeat left center;
}

.sc_b02_l3 {
    background: url("../../../public/img/sc_ico3.png") no-repeat left center;
}

.sc_b02_l4 {
    background: url("../../../public/img/sc_ico4.png") no-repeat left center;
}

.sc_b02_l5 {
    background: url("../../../public/img/sc_ico5.png") no-repeat left center;
}

.sc_b02_l6 {
    background: url("../../../public/img/sc_ico6.png") no-repeat left center;
    min-height: 35px;
}

.sc_b02_b7 .btn2 {
    width: 100%;
    text-align: center;
}

.sc_b02_b7 {
    margin-bottom: 50px;
}

.sc_b02_b9 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 30px 20px;
    margin: 0 0 20px;
}

.nofify p {
    text-overflow: ellipsis;
    overflow: hidden;
}

.sc_b02_b9_tx1 {
    font-size: 18px;
    font-weight: 700;

    margin-bottom: 10px;
}

.sc_b02_b9 .col3 {
    width: 18%;
}

.sc_b02_b9 .col9 {
    width: 82%;
}

.sc_b02_b9_tx2 {
    color: #404883;
    font-size: 14px;
}

.sc_b02_b9_tx2_wr {
    margin-bottom: 10px;
}

.sc_b02_b9_tx3 {
    color: #828282;
    font-size: 12px;
    margin-bottom: 10px;
}

.sc_b02_b9_tx5 {
    font-size: 12px;
}

.sc_b02_b9_tx5 a {
    color: #404883;
}

.sc_b02_b9_noava {
    height: 110px;
    width: 110px;
    border-radius: 100%;
    line-height: 110px;
    background: #00af49;
    text-align: center;
    font-size: 45px;
    font-weight: 700;
    color: #fff;
}

.sc_b01 h1 {
    margin: 10px 0 50px;
}

.ef_b01 {
    background: #fafdfd;
}

.ef_b02 {
    background: url("../../../public/img/ef_bg1.png") no-repeat left top;
}

.ef_b01 h1 {
    text-align: center;
    margin: 40px 0 30px;
}

.ef_b01_b1 {
    margin-bottom: 50px;
    text-align: center;
}

.ef_b02_tx2 {
    border-color: #0d9a56;
    color: #0d9a56;
}

.ef_b02_tx2:after {
    background: #0d9a56;
}

.ef_b02_tx2 {
    border-color: #0d9a56;
    color: #0d9a56;
}

.ef_b02_tx2:after {
    background: #0d9a56;
}

.ef_b02_tx3 {
    border-color: #217b67;
    color: #217b67;
}

.ef_b02_tx3:after {
    background: #26726b;
}

.ef_b02_tx4 {
    border-color: #325f75;
    color: #325f75;
}

.ef_b02_tx4:after {
    background: #39537d;
}

.ef_b02_tx5 {
    border-color: #404883;
    color: #404883;
}

.ef_b02 .gz_b02_tx3 span {
    color: #404883;
}

.ef_b02_in2_tx1 {
    margin: 110px 0 30px;
    text-align: center;
}

.ef_b02_in2_tx2 {
    margin: 0 0 80px;
    text-align: center;
}

.ef_b02_in1_b1 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.11);
    background: #fff;
    padding: 35px 0 50px;
    text-align: center;
}

.ef_b02_in1_b1 p {
    font-weight: 600;
    font-size: 25px;
}

.ef_b02_in1_b3 {
    position: relative;
    width: 210px;
    height: 60px;
    display: flex;
    margin: 0 auto;
    top: -20px;
    background: #404883;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.ef_b02_in1_b3 span {
    font-size: 30px;
    font-weight: 700;
    color: #ffffff;
    margin-left: 15px;
}

.ef_b02_in1_b4 {
    background: #00af49;
}

.ef_b02_ia1 {
    position: absolute;
    right: -100px;
    bottom: -50px;
}

.ef_b02_ia2 {
    position: absolute;
    left: -100px;
    bottom: -50px;
}

.ef_b02_in_tx1 {
    text-align: center;
    color: #00af49;
    font-size: 25px;
    font-weight: 700;
    margin: 100px 0 80px;
    text-transform: uppercase;
}

.ef_b02_in3_iw {
    position: relative;
    height: 124px;
    width: 100%;
    margin-bottom: 25px;
}

.ef_b02_in3_iw img {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.ef_b02_in3_tw {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
}

.ef_b03 {
    padding: 120px 0;
    background: url("../../../public/img/ef_bg2.png") no-repeat top center / contain;
}

.rf_b04 .tfoot td {
    font-weight: 700;
    text-transform: uppercase;
    background: #e3e4ed;
    padding: 30px 23px;
}

.rf_b04 .tfoot td:nth-child(2) {
    background: #dddfe9;
}

.rf_b04 .tfoot td:nth-child(3) {
    background: #dae2e7;
}

.rf_b04 .tfoot td:nth-child(3) span {
    background: #e3e4ed;
}

.rf_b04 tr.ef_no_bg td {
    background: transparent;
    border-bottom: 0;
}

.arrow-up-scroll {
    position: fixed;
    right: 200px;
    bottom: 200px;
    background: rgba(24, 35, 107, 0.60);
    display: flex;
    align-items: center;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    justify-content: center;
    opacity: 0;
    transition: opacity .3s;
}

@media screen and (max-width: 1200px) {
    .arrow-up-scroll {
        right: 50px;
    }
}

@media screen and (max-width: 768px) {
    .arrow-up-scroll {
        right: 24px;
        width: 50px;
        height: 50px;
        bottom: 50px;
    }
}

.ef_ln1 {
    width: 200px;
    height: 50px;
    margin: 0 auto;
    top: -20px;
    background: #404883;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-right: -47%;
    box-shadow: 0 3px 6px 2px #34203e;
}

.ef_ln1 {
    cursor: pointer;
}

.ef_ln1:focus {
    box-shadow: 0 3px 6px 0 #34203e;
}

.ef_ln1 p {
    font-size: 13px;
    color: #ffffff;
    text-transform: uppercase;
    margin-left: 15px;
}

.ef_ln2 {
    background: #00af49;
    margin-right: 7%;
    cursor: pointer;
    box-shadow: 0 3px 6px 2px #203e2a;
}

.ef_ln2:focus {
    box-shadow: 0 3px 6px 0 #203e2a;
}

.ss_b01_tx1 {
    margin: 10px 0 35px;
}

.ss_b01 {
    background: #fafdfd;
    padding: 0 0 90px;
}

.ss_b02_tx1 {
    color: #00af49;
    font-weight: 700;
    font-size: 16px;
}

.ss_b02_tx2 {
    font-size: 20px;
    font-weight: 700;
}

.ss_b02_b2 .btn2 {
    width: 100%;
    text-align: center;
    padding: 18px 0;
}

.ss_b02_ln1 {
    font-size: 14px;
    margin: 10px 0 0;
}

.ss_b03_tx1 {
    color: #00af49;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    margin: 50px 0;
}

.ss_b03_tx1_sbt1 {
    margin-bottom: 60px;
    text-align: center;
}

.ss_b03_tx1_empty {
    margin-bottom: 20px;
}

.ss_b03_table {
    width: 100%;
    border-collapse: collapse;
}

.ss_b03_table th {
    font-weight: 600;
    font-size: 18px;
    padding: 22px 20px;
    text-align: left;
    white-space: nowrap;
    border-top: 1px solid #b0b5cc;
    border-bottom: 2px solid #b0b5cc;
}

.ss_b03_table td {
    font-size: 14px;
    padding: 22px 20px;
    border-top: 1px solid #b0b5cc;
}

.ss_b03_table th.ss_b03_th1,
.ss_b03_table td.ss_b03_th1 {
    background: #f2f5f8;
}

.ss_b03_table th.ss_b03_th2,
.ss_b03_table td.ss_b03_th2 {
    width: 10px;
    padding: 0;
}

.ss_b03_plus {
    color: #00af49;
}

.ss_b03_minus {
    color: #d30000;
}

.ss_b03_table a {
    color: #989898;
    text-decoration: underline;
    font-size: 14px;
}

.ss_b05 {
    margin-top: 45px;
    background: #f2f5f8;
    padding: 15px;
}

.ss_b05_tx1 {
    font-size: 14px;
    font-weight: 500;
}

#modal_popoln {
    max-width: 600px;
    text-align: center;
}

.mp_tx1 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
}

#modal_popoln .st_input {
    background: #e3e4ed;
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
}

.mp_tx2 {
    opacity: .65;
    margin: 15px 0 0;
    font-size: 12px;
    font-weight: 500;
}

.mp_tx3 {
    font-weight: 500;
    font-size: 16px;
    margin: 30px 0;
}

.mp_b2 {
    font-size: 11px;
    font-weight: 500;
}

.modal_popoln_in {
    max-width: 350px;
    margin: 0 auto;
}

.modal_popoln_in .col3,
.pcz_b04 .col3 {
    padding: 0;
    width: auto;
    text-align: center;
}

.lk_b01 {
    background: #fafdfd;
}

.lk_b02 {
    padding: 25px 18px;
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
    background: #fff;
}

.lk_b02_tx1 {
    color: #404883;
    font-size: 22px;
    font-weight: 600;
    word-break: break-word;
}

.lk_b02_tx2 {
    color: #00af49;
    font-size: 10px;
    font-weight: 500;
}

.lk_b02_b1 {
    margin-bottom: 20px;
}

.lk_b02_tx4 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
}

.lk_b02_tx5 {
    font-size: 13px;
    margin-bottom: 15px;
}

.lk_b02_tx6 {
    padding-left: 20px;
    font-size: 13px;
    min-height: 20px;
    line-height: 20px;
    background: url("../../../public/img/lk_geo1.png") no-repeat left center;
}

.lk_b02_b3 {
    display: flex;
    align-items: center;
    margin: 20px 0 10px;
}

.lk_b02_b3 .hd_status {
    margin-left: 10px;
    background: #00af49;
}

.lk_b02_tx8 {
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    color: #000000;
}

.lk_b02_b5 {
    margin-bottom: 25px;
    color: #404883;
    font-size: 11px;
    line-height: 15px;
}

.lk_b02_b8 {
    margin: 40px 0 30px;
    border-bottom: 1px solid #b3b6cd;
    padding-bottom: 20px;
}

.lk_b02_tx10 {
    font-size: 14px;
    font-weight: 500;
    padding-left: 45px;
    margin-bottom: 15px;
    min-height: 22px;
    line-height: 22px;
}

.lk_b02_tx10_1 {
    background: url("../../../public/img/lk_ico1.png") no-repeat left center;
}

.lk_b02_tx10_2 {
    background: url("../../../public/img/lk_ico2.png") no-repeat 3px center;
}

.lk_b02_tx10_3 {
    background: url("../../../public/img/lk_ico3.png") no-repeat left center;
}

.lk_b02_tx11 {
    font-size: 14px;
    font-weight: 500;
}

.lk_b02_plus {
    color: #00af49;
}

.lk_b02_minus {
    color: #e30000;
}

.lk_b02_tx20 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
}

.lk_b02_tx21 {
    font-size: 14px;
    margin-bottom: 10px;
    word-break: break-word;
}

.vp_b03_tabs_ul {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #d9dae6;
}

.vp_b03_tabs_ul ul {
    display: flex;
}

.vp_b03_tabs_ul a {
    color: #000;
    margin: 0 12px;
    font-weight: 500;
    display: inline-flex;
    padding-bottom: 25px;
    font-size: 14px;
    color: #989898;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 3px solid transparent;
}

.vp_b03_tabs_ul a.selected,
.vp_b03_tabs_ul a.active,
.vp_b03_tabs_ul li.current a {
    border-bottom: 3px solid #0cbc46;
    color: #000000;
}

.lkz_b01 {
    padding: 40px 30px;
}

.lkz_b01 .vi_b05_b7 {
    margin-bottom: 30px;
}

.lkz_b02_b6 a {
    min-width: 295px;
    text-align: center;
    margin-top: 15px;
}

.lk_b02_tx10_8 {
    background: url("../../../public/img/lkz_ico1.png") no-repeat left center;
}

.lkz_b02_b12 {
    border-bottom: 1px solid #b3b6cd;
    padding: 0 0 20px;
    margin-bottom: 20px;
}

.lkz_b02_tx20 {
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 10px;
}

.lkz_b02_tx21 {
    font-size: 14px;
    padding-left: 15px;
}

.lkz_b02_b14 {
    margin-bottom: 10px;
}

.lkz_b02_b25 {
    margin: 20px 0 0;
    padding: 20px 0 30px;
    border-top: 2px solid #b3b6cd;
}

.lkz_b05_b1 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
    padding: 25px 30px;
    color: #404883;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    background: #fff;
    margin-bottom: 15px;
}

.vp_b03_vt_ul,
.vp_b03_b2_vt_ul {
    max-width: 200px;
    border-right: 1px solid #e3e4ed;
    ;
}

.vp_b03_vt_ul a,
.vp_b03_b2_vt_ul a {
    font-size: 13px;
    display: block;
    font-weight: 500;
    cursor: pointer;
    padding: 15px 30px;
    text-transform: uppercase;
    position: relative;
    color: #000;
    border-bottom: 1px solid #e3e4ed;
}

.vp_b03_vt_ul a:after,
.vp_b03_b2_vt_ul a:after {
    content: '';
    position: absolute;
    border-left: 8px solid #000;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    right: 15px;
    top: 50%;
    margin-top: -6px;
}

.vp_b03_vt_ul a:hover,
.vp_b03_b2_vt_ul a:hover {
    background: #e3e4ed;
}

.vp_b03_vt_ul a.selected,
.vp_b03_b2_vt_ul a.selected,
.vp_b03_vt_ul a.active,
.vp_b03_b2_vt_ul a.active {
    color: #ffffff;
    background: #00af49;
}

.vp_b03_vt_ul a.selected:after,
.vp_b03_b2_vt_ul a.selected:after,
.vp_b03_vt_ul a.active:after,
.vp_b03_b2_vt_ul a.active:after {
    border-left: 8px solid #fff;
}

.vp_b03_vt_t1 {
    padding: 23px;
    width: 100%;
    flex: 1;
}

@media screen and (max-width:425px) {
    .vp_b03_vt_t1 {
        padding: 12px;
        width: 100%;
        flex: 1;
    }
}

.lkz_b10_tx1 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 30px;
    word-break: break-word;
}

.lkz_b10_tx7 {
    padding-top: 15px;
}

.lkz_b10_b5 {
    margin-bottom: 20px;
}

.lkz_b11_tx1 {
    text-decoration: underline;
    margin-bottom: 10px;
}

.lkz_b10_tx1 span {
    color: #e20000;
}

.lkz_b10_b7 {
    margin-top: 50px;
}

.lkz_b11 {
    margin-top: 40px;
}

.md_tx1 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 15px;
    letter-spacing: 0;
    white-space: nowrap;
}

.lkz_b10_tx15 {
    color: #404883;
    font-size: 12px;
    text-decoration: underline;
}

.lkz_b10_b15 {
    margin-top: 15px;
}

.modal_dobavit {
    max-width: 590px;
}

.md_head {
    position: absolute;
    top: -100px;
    white-space: nowrap;
    left: 0;
    text-align: center;
    right: 0;
}

.modal_dobavit {
    margin-top: 120px;
}

.modal.modal_dobavit .st_textarea {
    height: 120px;
}

.js_change_show {
    display: none;
}

.rp_b01_b1 .st_checkbox+label {
    font-size: 10px;
}

.rp_b01_b2 {
    margin: 30px 0;
}

.rp_b01_tx6 {
    text-align: center;
    margin-bottom: 45px;
}

.rp_b03 {
    margin-bottom: 40px;
}

.sz_b02_step {
    opacity: .5;
}

.sz_b02_step.sz_b02_st_active {
    opacity: 1;
}

.sz_b02_tx1 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.sz_b02_tx2 {
    font-size: 13px;
    line-height: 17px;
}

.sz_b02 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
    padding: 20px;
    background: #fff;
}

.sz_b02_step {
    padding: 15px 0 15px 80px;
    position: relative;
}

.sz_b02_step:before {
    content: '';
    position: absolute;
    width: 3px;
    top: 0;
    left: 25px;
    height: 65%;
    background: #c8c8c8;
}

.sz_b02_step:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 35%;
    left: 25px;
    bottom: 0;
    background: #c8c8c8;
}

.sz_b02_nm1 {
    position: absolute;
    background: #fff;
    font-weight: 600;
    font-size: 23px;
    left: 0;
    top: 0;
    height: 55px;
    width: 55px;
    border-radius: 100%;
    text-align: center;
    line-height: 49px;
    color: #c8c8c8;
    border: 3px solid #c8c8c8;
}

.sz_b01 {
    background: #fafdfd;
    padding-bottom: 50px;
}

.sz_b02_st1 .sz_b02_tx1 {
    color: #404883;
}

.sz_b02_st2 .sz_b02_tx1 {
    color: #39547d;
}

.sz_b02_st3 .sz_b02_tx1 {
    color: #2b6b6f;
}

.sz_b02_st4 .sz_b02_tx1 {
    color: #1a8560;
}

.sz_b02_st5 .sz_b02_tx1 {
    color: #0c9d54;
}

.sz_b02_st6 .sz_b02_tx1 {
    color: #01ad4a;
    text-transform: uppercase;
}

.sz_b02_st_active .sz_b02_tx1 {
    text-decoration: underline;
}

.sz_b02_st_active.sz_b02_st_nx_active .sz_b02_tx1 {
    text-decoration: none;
}

.sz_b02_st1:before {
    background: #3e4b81;
}

.sz_b02_st1 .sz_b02_nm1 {
    border-color: #3e4b81;
    color: #3e4b81;
}

.sz_b02_st1.sz_b02_st_nx_active:after {
    background: #3e4b81;
}

.sz_b02_st2.sz_b02_st_active .sz_b02_nm1 {
    border-color: #39537c;
    color: #39537c;
}

.sz_b02_st2.sz_b02_st_active:before {
    background: #335e77;
}

.sz_b02_st2.sz_b02_st_nx_active:after {
    background: #2b6b70;
}

.sz_b02_st3.sz_b02_st_active .sz_b02_nm1 {
    border-color: #2b6b6f;
    color: #2b6b6f;
}

.sz_b02_st3.sz_b02_st_active:before {
    background: #247668;
}

.sz_b02_st3.sz_b02_st_nx_active:after {
    background: #1a8560;
}

.sz_b02_st4.sz_b02_st_active .sz_b02_nm1 {
    border-color: #1a8561;
    color: #1a8561;
}

.sz_b02_st4.sz_b02_st_active:before {
    background: #1a8561;
}

.sz_b02_st4.sz_b02_st_nx_active:after {
    background: #0c9c53;
}

.sz_b02_st5.sz_b02_st_active .sz_b02_nm1 {
    border-color: #0b9d54;
    color: #0b9d54;
}

.sz_b02_st5.sz_b02_st_active:before {
    background: #06a54e;
}

.sz_b02_st5.sz_b02_st_nx_active:after {
    background: #06a54e;
}

.sz_b02_st6.sz_b02_st_active .sz_b02_nm1 {
    border-color: #01ad4a;
    color: #01ad4a;
}

.sz_b02_st6:after,
.sz_b02_st6:before {
    display: none;
}

.sz_b05 {
    margin-top: 30px;
}

.sz_b09 {
    background: #ffffff;
    box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.26);
    padding: 40px 30px;
}

.sz_b09_tx1 {
    font-size: 30px;
    margin-bottom: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sz_b09_tx2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

.sz_b09_tx3 {
    color: #00af49;
}

.sz_b09_tx4 {
    color: #404883;
}

.sz_b10 {
    border: 3px solid #00af49;
    margin: 20px 0;
    /*padding:20px 20px 20px 70px;*/
    /*background:url("../../../public/img/sz_img1.png") no-repeat 20px 20px;*/
    padding: 20px 20px 20px 30px;
}

.sz_b10_tx1 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 20px;
    font-weight: 600;
}

.sz_b10_tx2 {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
    word-break: break-word;
}

.sz_b10_tx3 {
    font-size: 12px;
    background: url("../../../public/img/sz_img2.png") no-repeat left top;
    padding: 4px 0 0 30px;
    margin-top: 25px;
    line-height: 16px;
}

.st_input_file1,
.st_input_file2 {
    width: 1px;
    opacity: 0;
    height: 1px;
    position: absolute;
    overflow: hidden;
}

.st_input_file1+label {
    display: inline-block;
    padding: 15px 35px 15px 55px;
    background: url("../../../public/img/sz_img3.png") no-repeat 15px 15px, #e6e6e6;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .2s;
    cursor: pointer;
}

.sz_b11_tx1 {
    font-size: 14px;
    margin: 15px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sz_b11_tx2 {
    margin: 40px 0 15px;
    color: #00af49;
    font-size: 20px;
    font-weight: 600;
}

.sz_b11 .st_textarea {
    height: 200px;
}

.sz_b11_b1 {
    margin: 20px 0;
}

.sz_b11_b1_sp2 p {
    font-weight: 600;
    margin: 0 3px;
    font-size: 12px;
}

.sz_b11_b1_sp2 .link1 {
    font-size: 12px;
    white-space: nowrap;
}

.sz_b11_b1_sp2 .btn2 {
    width: 260px;
    white-space: nowrap;
    text-align: center;
    padding: 15px 0;
}

.sz_b11_b1_sp4 {
    margin-left: 12px;
}

.sz_b12 {
    padding: 40px 0 30px;
    border-bottom: 1px solid #cfd1e0;
}

.sz_b12_b1 {
    background: #f1f1f1;
    padding: 12px;
    border: 1px solid #646a9a;
    margin-bottom: 20px;
    border-radius: 3px;
}

.sz_b12_tx1 {
    margin-bottom: 15px;
}

.sz_b12_b1 .sz_b12_tx1 {
    color: #404883;
}

.sz_b12_b2 {
    background: #f4fbf7;
    padding: 12px;
    border: 1px solid #30be6b;
    margin-bottom: 20px;
    border-radius: 3px;
    margin-left: 80px;
}

.sz_b12_b2 .sz_b12_tx1 {
    color: #00af49;
}

.sz_b12_tx2 {
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
}

.sz_b13 {
    padding: 25px 0;
}

.sz_b13_tx1 {
    opacity: .65;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
}

.sz_b13_tx2 {
    font-size: 13px;
    margin-bottom: 5px;
    opacity: .65;
}

.sc_b04_tx2 {
    color: #404883;
    font-size: 14px;
    margin-bottom: 15px;
}

.sc_b05_b2 {
    margin-top: 80px;
}

.sc_b04_tx3 {
    margin-bottom: 20px;
    color: #828282;
    font-size: 12px;
}

.pvv_b01 {
    background: #fafdfd;
    padding: 0 0 50px;
}

.pvv_b01_tx1 {
    color: #00af49;
    font-size: 30px;
    font-weight: 700;
}

.pvv_b01_tx1 span {
    color: #767676;
    font-size: 18px;
    font-weight: 400;
    margin-left: 20px;
}

.logo_mobile {
    display: none;
}

.btn7 {
    display: inline-block;
    padding: 15px 35px;
    color: #fff;
    background: #e30613;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .2s;
    cursor: pointer;
}

.btn7:hover {
    box-shadow: 0 0 0 7px #E5BDB7;
}

.sz_b10_b6 {
    align-items: center;
}

.sz_b10_b6 .btn2,
.sz_b10_b6 .green,
.sz_b10_b6 .green-outline,
.sz_b10_b6 .btn7 {
    white-space: nowrap;
    padding: 15px 25px;
    margin-right: 20px;
    font-size: 16px;
    max-width: unset;
}

.sz_b10_b6 p {
    font-size: 11px;
}

.mcz_head {
    color: #00af49;
    font-size: 25px;
    margin-bottom: 30px;
    font-weight: 600;
    text-align: center;
}

.popup_change_zakaz,
.popup_confirm_zakaz,
.popup_pay_zakaz,
.popup570 {
    max-width: 570px;
    padding: 30px 35px;
}

.popup_confirm_zakaz {
    padding: 30px 25px;
}
@media screen and (max-width: 768px) {
    .info-modal h2{
        font-size: 20px !important;
    }
    .info-modal a{
        font-size: 14px !important;
        padding: 7px 15px;
    }
}

.mcz_tx1 {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 30px;
}

.mcz_b03 {
    max-width: 380px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.mcz_b03 .flex {
    align-items: center;
}

.mcz_tx6 .st_checkbox+label {
    flex-wrap: wrap;
}

.mcz_tx6 .st_checkbox+label span {
    width: 100%;
    margin-bottom: 10px;
}

.mcz_b7 {
    margin: 40px 0 0;
}

.mcz_tx8 {
    margin: 10px 0 0;
    font-size: 12px;
}

.mcz_tx8 a {
    text-decoration: underline;
    color: #d90005;
}

.mcnz_tx8 {
    margin: 10px 0 0;
    font-size: 12px;
}

.mcnz_tx8 a {
    text-decoration: underline;
    color: #404883;
}

.mcnz_tx10 {
    opacity: .65;
    font-size: 12px;
    margin-top: 30px;
    text-align: center;
}

.pcz_tx1 {
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 25px;
    font-weight: 600;
}

.pcz_tx2 {
    display: inline-block;
    text-align: left;
    margin-bottom: 25px;
}

.pcz_tx2 p {
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;

}

.pcz_tx2_in {
    width: 320px;
    color: #404883;
    font-size: 15px;
    font-weight: 600;
    text-decoration: underline;
}

.pcz_b04 {
    max-width: 350px;
    margin: 25px auto 15px;
}

.pcz_tx3 {
    font-size: 13px;
    line-height: 22px;
}

.pcz_tx5 {
    font-size: 12px;
    opacity: .65;
}

.pcz_tx5 .link1 {
    font-size: 12px;
}

.sz_b10_g8 {
    margin: 10px 0 0;
}

.sz_b10_g8 p,
.sz_b10_g8 a {
    font-size: 12px;
}

.sz_b10_g6 {
    align-items: flex-start;
}

.msa_tx1 {
    margin: 5px 0 0;
    font-size: 12px;
}

.msa_tx3 {
    margin: 10px 0 0;
    font-size: 12px;
}

.msa_tx3 a {
    color: #404883;
    text-decoration: underline;
}

.sz_b11_tx8 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}

.pez_tx1 {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
    margin: 15px 0;
}

.pez_tx8 {
    font-size: 12px;
    font-weight: 700;
    margin-top: 15px;
}

.pez_tx8 a {
    color: #404883;
    text-decoration: underline;
}

.pez2_tx4 {
    margin-bottom: 20px;
}

.pez2_rd_wrap {
    margin: 20px 0;
}

.radio {
    display: none;
}

.radio+label:before {
    content: '';
    display: inline-block;
    height: 14px;
    vertical-align: middle;
    width: 14px;
    margin-right: 15px;
    border: 1px solid #cfd1db;
    background: #f6f7f9;
    border-radius: 100%;
}

#positive, #platformPositive {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px
}

.radio#positive_0+label, .radio#platformPositive_0 + label {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-end;
    padding: 6px 12px;
    color: #84B747;
    font-size: 14px;
    border: 1px solid #84B747;
}

.popup_review_title {
    font-weight: 600;
    font-size: 24px;
    margin-right: 30px;
}

.radio#positive_1+label, .radio#platformPositive_1 + label {
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    padding: 6px 12px;
    font-size: 14px;
    color: #ca0223;
    border: 1px solid #ca0223;
}

.radio#positive_0+label:before, .radio#platformPositive_0 + label:before {
    background: url("../../../public/img/like.png") no-repeat center;
    width: 24px;
    height: 24px;
    border-radius: inherit;
    border: none;
    vertical-align: initial;
}

.radio#positive_0:checked+label,
.radio#platformPositive_0:checked + label,
.radio#platformPositive_0 + label:hover,
.radio#positive_0+label:hover {
    background: rgba(170, 255, 69, 0.31);
}

.radio#positive_1:checked+label,
.radio#platformPositive_1:checked + label,
.radio#platformPositive_1 + label:hover
.radio#positive_1+label:hover {
    background: rgba(241, 91, 91, 0.31);
}

.radio#positive_1+label:before, .radio#platformPositive_1 + label:before {
    width: 24px;
    height: 24px;
    border-radius: inherit;
    border: none;
    vertical-align: initial;
    background: url("../../../public/img/dislike.png") no-repeat center;
}

.sz_b10_tx2 .sz_b09_tx3 {
    text-transform: uppercase;
    font-weight: 600;
}

.mez_tx8 {
    font-size: 14px;
    line-height: 24px;
}

.mez_tx5 {
    font-size: 14px;
    line-height: 24px;
    color: #404883;
    text-decoration: underline;
}

.mez_tx3 {
    font-size: 14px;
    margin: 20px 0 10px;
}

.vp_v2_b01 .tx-area-counter {
    margin-bottom: 20px;
}
.vp_v2_b01 .st_textarea {
    height: 160px;
    margin: 10px 0 0;
}

.lkz_b10_tx2 {
    margin: 40px 0 20px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
}

.tx_14_20 sub {
    font-size: 12px;
    letter-spacing: 0;
}

.lkz_b10_b5_sb1 {
    margin: 0 0 20px;
}

.vp_b03_vt_tx5 {
    font-size: 12px;
    margin: 15px 0 40px;
}

.vp_b03_vt_photo {
    width: 1px;
    opacity: 0;
    height: 1px;
    position: absolute;
    overflow: hidden;
}

.vp_b03_vt_t5_b1 p {
    margin-bottom: 15px;
}

.vp_b03_vt_t5_b2 {
    margin: 30px 0;
}

#vp_b03_b2_tb4 .st_textarea,
#vp_b03_tabs_t4 .st_textarea {
    height: 270px;
}

#vp_b03_b2_tb4 .sz_b12_b1 {
    margin-right: 80px;
}

#vp_b03_b2_tb4 .sz_b12,
#vp_b03_tabs_t4 .sz_b12 {
    border-bottom: 0;
}

.vp_b03_b2_vt_t2_txt {
    margin-bottom: 10px;
}

.vp_b03_b2_tb1 {
    padding: 30px;
}

.vp_b03_b2_hb1_r1 {
    margin-bottom: 30px;
}

.vp_b03_b2_hb1 {
    background: #f8f8fa;
    padding: 15px 50px;
    margin: 15px 0 0;
}

.vp_b03_b2_hb1 a {
    color: #5a5a5a;
    font-size: 12px;
    font-weight: 500;
    margin-right: 30px;
}

.vp_b03_b2_hb1 a.selected,
.vp_b03_b2_hb1 a.active {
    color: #404883;
}

.vp_b03_b2_hb1_r2 {
    font-size: 13px;
}

.vp_b03_b2_tb2_r1 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.11);
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

input::-webkit-calendar-picker-indicator {
    /*display: none;*/
    opacity: 0 !important;
}

input[type="date"]::-webkit-input-placeholder {
    opacity: 0 !important;
}

.vp_b03_b2_tb2_r1_plus,
.vp_b03_b2_tb2_r1_minus {
    position: absolute;
    right: 15px;
    top: 15px;
    height: 23px;
    width: 23px;
}

.vp_b03_b2_tb2_r1_tx1 a {
    font-size: 15px;
    font-weight: 600;
    color: #000000;
}

.hd_ava1 img {
    border-radius: 100%;
    overflow: hidden;
}

.st_checkbox2 {
    display: none;
}

.st_checkbox2+label:before {
    content: '';
    border-radius: 100%;
    border: 1px solid #cfd1db;
    background: #f6f7f9;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 0;

}

.st_checkbox2+label {
    position: relative;
    font-size: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-left: 30px;
}

.st_checkbox2:checked+label:before {
    border: 1px solid #2dac18;
    background: url("../../../public/img/pz_radio_bg.png") no-repeat center, #00af49;
}

.vp_b03_vt_t3_u7_b1 {
    background: #e3e4ed;
    padding: 15px;
    margin-bottom: 30px;
}

.vp_b03_vt_t3_u7_b1 .col6 {
    margin-bottom: 15px;
}

.col6 img {
    max-width: 100%;
}

.lkz_hd1_b01 {
    /*display:none;*/
    padding: 40px 30px;
}

.lkz_hd1_b02 {
    /*display:none;*/
    padding: 40px 30px;
}

.lkz_hd1_b02_tx1 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 40px;
}

.lkz_hd1_b02_tx2 {
    margin-bottom: 40px;
}

.lkz_hd1_b02_b2 {
    margin-bottom: 30px;
}

.lkz_hd1_b02_b3 {
    margin: 30px 0 0;
}

.lkz_hd1_b02_b3 a {
    color: #00af49;
    font-size: 13px;
    text-decoration: underline;
}

.lki_b10_b01 {
    position: relative;
    cursor: pointer;
}

.lki_b10_b01 img {
    display: block;
    width: 70px;
    height: 70px;
}

.lki_b10_b01_abs {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .3);
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 60px;
    color: #ffffff;
    transition: .2s;
}

.lki_b10_b01:hover .lki_b10_b01_abs {
    opacity: 1;
}

.lki_b10_b01_b2 {
    cursor: pointer;
    height: 70px;
    width: 70px;
    line-height: 70px;
    display: block;
    border: 2px solid #404883;
    color: #404883;
    font-size: 60px;
    text-align: center;
}

.spz_b01_b1 ul {
    columns: 1;
    margin-bottom: 0;
}

.spz_b01_b1 li {
    font-size: 14px;
}

.spz_b01_b1 li span {
    color: #000000;
}

.spz_b01_b1 li.active span,
.spz_b01_b1 li.active {

    color: #00af49;
}

.spz_b01 {
    background: #fafdfd;
    padding: 0 0 1px;
}

.spz_b01_b1_tx1 {
    color: #00af49;
    font-weight: 700;
    margin: 0 0 50px;
}

.spz_b01_b2 img {
    width: 100%;
    display: block;
}

.spz_b01_b2_tx1 a {
    text-align: center;
    color: #404883;
    font-size: 18px;
    font-weight: 700;
}

.spz_b01_b2_tx1 {
    margin: 30px 0;
}

.spz_b01_b2_tx2 {
    background: url("../../../public/img/spz_img1.png") no-repeat left center;
    padding: 5px 0 5px 25px;
    font-size: 12px;
    text-align: left;
    margin-bottom: 15px;
}

.spz_b01_b4 {
    padding: 10px 0;
}

.spz_b01_b2 .kf_b07_b2 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.11);
}

.spz_b016 .kf_b07_b2 {
    padding: 75px 15px 0;
}

.spz_b01_b2_tx3 {
    background: url("../../../public/img/spz_img2.png") no-repeat left center;
    padding: 5px 0 5px 25px;
    font-size: 12px;
    text-align: left;
}

.spz_b01_b4 {
    /*border-top: 1px solid #b3b6cd;*/
}

.freelancer-list-item {
    background: #FFF;
    box-shadow: 0 0 15px 0 rgba(0, 77, 110, 0.05);
    width: 352px;
    padding: 20px;
}

.freelancer-list-item .relative {
    min-height: 180px;
}

.col-filter {
   min-width: 425px
}
@media screen and (max-width: 820px) {
    .col-filter {
        min-width: auto
     }
}
.filepond--browser.filepond--browser {
    height: 100% !important;
}
.portfolio-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.freelancer-list-item .tns-inner {
    padding: 0;
    width: 309px;
    overflow: hidden;
}
.freelancer-list-item .tns-ovh {
    padding: 0;
}
.freelancer-list__portfolio-background__cover  {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}
.adaptive {
    min-width: 375px;
}
.freelancer-list-item .tns-ovh {
}
.image-original {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.freelancer-list__portfolio-background:before {
    content:'';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(237, 237, 237, 0.60);
    backdrop-filter: blur(9px);
    border-radius: 10px;
}
.freelancer-list__portfolio-background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.freelancer-list__portfolio__cover {
    max-width: 100%;
    height: 180px;
}
.freelancer-list__portfolio {
}

.spz_b01_b2_tx4 {
    color: #00af49;
    font-weight: 700;
    font-size: 14px;
}

.spz_b01_b11 {
    padding: 50px 0 0;
}

.spz_b015 {
    background: #fafdfd;
    padding: 50px 0 60px;
}

.spz_b016 {
    padding: 50px 0 60px;
}

.show_768, .show_820 {
    display: none;
}

.psp_b01 {
    border: 3px solid #00af49;
    padding: 20px 50px 30px;
}

.psp_b01_tx1 {
    font-size: 20px;
    line-height: 28px;
    margin-top: 5px;
    font-weight: 600;
}

.psp_b01_tx2 {
    color: #ff9f00;
}

.psp_b01_tx1 a {
    color: #00af49;
    text-decoration: underline;
}

.psp_b03 {
    padding: 10px 0;
}

.psp_b03 .link1 {
    font-size: 14px;
}

.psp_b01_tx4 {
    color: #686868;
    font-size: 13px;
    margin-top: 15px;
}

.sz_b02_mob {
    display: none;
}

.hd_rtop {
    width: 22vw;
}

.top_mn_ispoln li {
    list-style: circle;
    color: #989898;
    transition: .2s;
    width: 33.33%;
    cursor: pointer;
    list-style-position: inside;
    padding-left: 20px;
}

.top_mn_ispoln {
    border-top: 3px solid #0cb544;
    padding: 15px 10px;
    display: flex;
    flex-wrap: wrap;
}

.top_mn_ispoln.show_768 {
    display: none;
}

.dropdown_menu .top_mn_ispoln li a {
    font-size: 14px;
    display: inline-block;
    padding: 10px 0 0;
    background: transparent;
}

.top_mn_ispoln li.active,
.top_mn_ispoln li:hover {
    color: #0cb544;
}

.top_mn_ispoln li.active a,
.top_mn_ispoln li:hover a {
    color: #0cb544;
    background: transparent;
    text-decoration: underline;
}

.ddm_main {
    min-width: 980px;
}

.np_b01_tx1 {
    margin: 10px 0 20px;
}

.np_b01_tx4 {
    font-size: 14px;

}

.np_b01_tx4.error {
    color: red;
}

.np_b01_b2_tx1 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.np_b01_bwr {
    margin-bottom: 35px;
}

.np_b01_b2 {
    margin-bottom: 35px;
}

.np_b01_b2 .st_textarea {
    height: 250px;
    font-size: 14px;
}

.np_b01_b2_tx5 {
    margin-top: 15px;
    display: block;
}

.np_b01 {
    background: #fafdfd;
    padding: 0 0 140px;
}

.np_b01_b2 .btn5 {
    padding: 10px 15px;
    white-space: nowrap;
}

.np_b01_b2 .col12 {
    max-width: 380px;
}

.np_b01_b2_tx8 {
    margin-right: 20px;
}

.st_input.np_b01_b2_inp:before {
    background-position-y: center;
    background: url("../../../public/img/np_ico1.png") no-repeat right 15px top 16px, #fff;
}

.error-wrap {
    width: 100%;
    max-width: 245px;
    margin-left: 231px;

}

.title-project {
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0;
}

@media screen and (max-width: 768px) and (min-width: 425px) {
    .st_input.np_b01_b2_inp:before {
        background-size: 18px 18px !important;
    }
}

.btn8 {
    display: inline-block;
    padding: 15px 35px;
    color: #010101;
    background: #ffaf00;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .2s;
    cursor: pointer;
    font-weight: 600;
}

.btn8:hover {
    box-shadow: 0 0 0 7px #FFDE53;
}

.zpv_bwr {
    padding: 30px 0 60px;
}

.of_b1 img {
    display: block;
    width: 100%;
}

.of_b1 .kf_b07_b2 {
    box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.11);
}

.of_b2 {
    padding: 80px 0;
    text-align: center;
}

.of_b24 {
    padding: 80px 0 0;
    text-align: center;
}

.btn9 {
    display: inline-block;
    padding: 15px 45px;
    color: #000;
    background: #fff;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .2s;
    cursor: pointer;
    font-weight: 600;
}

.btn9:hover {
    box-shadow: 0 0 0 7px #1cb85d;
}

.of_b3 {
    margin-bottom: 0;
}

.szn_b01 .title-link {
    font-size: 14px;
}

.szn_b01 .vi_b05_tx3 {
    margin-bottom: 0;
}

.szn_b01 .lkz_b10_b5 {
    margin-bottom: 35px;
}

.szn_b01_tx1 {
    opacity: .65;
    margin-top: 8px;
    font-size: 12px;
}

.szn_b01_tx2 {
    margin-top: 16px;
}

.szn_b01 .st_textarea {
    height: 170px;
}

.btn10 {
    display: inline-block;
    padding: 15px 15px;
    color: #000;
    background: #e6e6e6;
    text-transform: uppercase;
    font-size: 13px;
    border-radius: 100px;
    transition: .2s;
    cursor: pointer;
}

.btn10:hover {
    box-shadow: 0 0 0 7px #EEEEEE;
}

.pvi_b01 a+a {
    margin-left: 20px;
}

.pvi_b01 .btn2 {
    padding: 15px 15px;
}

.pvi_b01 p {
    font-size: 12px;
    margin: 0 10px;
}

.pvi_b01_tx1 {
    font-size: 14px;
}

.pvi_b01_tx1 .link1 {
    font-weight: 400;
}

.sz_b10_tx2 span {
    color: #00af49;
    text-transform: uppercase;
}

.btn-w {
    width: 210px;
}

.zs_tx1 {
    margin: 15px 0;
}

.zs_b01 .btn10 {
    padding: 15px 35px;
}

.zz_b01 {
    border-color: #ffaf00;
}

.pkz_b01 {
    border-top: 1px solid #cfd1e0;
    margin-top: 40px;
}

.pkz_b01_tx1 {
    color: #d60000;
    padding: 4px 0 4px 30px;
    margin-top: 10px;
}

.pkz_b02 {
    margin: 40px 0;
}

/*страницы ошибок*/
.err_b01 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.err_b01_tx1 {
    text-align: center;
    margin: 0 auto;
    width: 70%;
}

.tx_25_53 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 700;
    padding-top: 20px;
}

.err_b01_img {
    text-align: center;
}

.err_bg1 {

    background: url("../../../public/img/err_bg1.jpg") no-repeat right top;
    padding-bottom: 200px;
}

/*Modal*/

select:disabled,
input:disabled,
textarea:disabled {
    background-color: lightgray;
}

.overlay_container {
    position: relative;
}

.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(64, 64, 64, 0.5);
    z-index: 10;
}

.success_message {
    margin-bottom: 20px;
    color: #00af49;
    font-weight: bold;
}

.react_select {
    font-size: 14px;
}

.st_select {
    width: 100%;
    border-radius: 3px;
    position: relative;
    /*z-index:2;*/
    background: #fff;
    font-size: 14px;
    border: 1px solid #9fa3c1;
    color: #000;
    display: block;
    overflow: hidden;
    text-align: left;
    text-overflow: clip;
    outline: 0;
    letter-spacing: 1px;
    padding: 15px 30px 15px 20px;
}

.react-select__control {
    border-color: #9fa3c1 !important;
    padding: 6px 0 6px 10px !important;
}

.react-select__control--is-focused {
    border-color: #404883 !important;
    box-shadow: 0 0 0 1px #404883 inset !important;
}

.attachment {
    font-weight: bold;
    color: #404883;
}

.attachment img {
    margin-right: 5px;
    vertical-align: middle;
}

.order-status {
    margin-top: 10px;
    margin-bottom: -10px;
    color: #989898;
    font-weight: bold;
    font-size: 14px;
}

.order-status img {
    vertical-align: middle;
    margin-right: 5px;
}

.warning-text {
    background: url("../../../public/img/sz_img2.png") no-repeat left top;
    padding-left: 30px;
}

.ps_b04_b1_ul .selected {
    background-color: #ececf2;
    padding: 5px;
    border-radius: 5px;
    margin-top: -5px;
}

.react-lightbox img {
    max-width: none;
    max-height: none;
}


.settings-specs .specs-tabs {
    display: flex;
    padding-bottom: 15px;
}

.settings-specs .specs-tabs a {
    font-weight: 500;
    display: inline-flex;
    padding: 10px 15px 10px;
    font-size: 14px;
    color: #989898;
    text-transform: uppercase;
    border-color: #d9dae6;
    border-style: solid;
    border-width: 0 0 1px 0;
}

.settings-specs .specs-tabs a.selected {
    border-width: 1px 1px 0 1px;
    color: #000000;
}

.settings-specs .category {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 600;
}

.settings-specs .category:after {
    display: inline-block;
    content: url('../../../public/img/react-arrow.svg');
    margin-left: 5px;
    transition: all .3s;
}

.settings-specs .category.open:after {
    transform: rotateZ(180deg);
}

.settings-specs .category.closed:after {
}
#sort-container, #sort-project-container{
    margin-bottom: 30px;
}
#sort-container {
    min-height: 91px;
}
.settings-specs .spec-container {}

.sidebar .specializations .header2 {
    font-weight: 600;
    font-size: 15px;
    color: gray;
    margin-bottom: 10px;
}
.bg-container-tree {
    padding: 31px 0 31px 20px;
    background-color: #F3F4F7;
}

.flex-tree ul {
    padding-left: 30px;
}

.spec-tag {
    display: inline-block;
    padding: 10px 15px;
    color: #fff;
    background: #8cd39c;
    font-size: 13px;
    border-radius: 100px;
    transition: .2s;
    margin: 7px 7px 0 0;
}

.spec-tag a {
    font-weight: bold;
}

.category-tag {
    background: #00af49;
}
.st-desciplines .category-image {
    width: auto;
    flex: 1 1 20%;
}
.text-warn {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}

.flash {
    margin: 15px;
    font-weight: bold;
    text-align: center;
}

.flash.success {
    color: #2D6163;
}

.flash.danger {
    color: red;
}

.review-body {
    min-height: 108px;
    font-size: 13px;
    line-height: 18px;
    margin: 25px 0 8px;
    position: relative;
}

.review-body-short {
    max-height: 108px;
    overflow: hidden;
}

.review-body-short:after {
    /*display:none; */
    content: " ";
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 15%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
}

.header .col0:nth-last-child(-n+2) {
    padding-top: 17px;
}

#snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background: #3498db;
    color: #fff;
    border-radius: 2px;
    padding: 8px;
    position: fixed;
    z-index: 1;
    right: 1%;
    top: 17%;
    /*display: flex;*/
}

#snackbar.hide {
    visibility: hidden;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}

#snackbar_text {
    text-align: left;
    margin-right: 12px;
    font-size: 12pt;
    white-space: pre-line;
}

.snackbar_text {
    text-align: left;
}

.Toastify__toast--info {
    top: 147px;
}

.Toastify__close-button {
    padding-left: 6px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    outline: none;
    border: none;
    position: absolute;
    top: 0;
    right: 6px;
    /*padding: 0;*/
    cursor: pointer;
    opacity: 0.7;
    transition: 0.3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

@-webkit-keyframes fadein {
    from {
        right: 0;
        opacity: 0;
    }

    to {
        right: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        right: 0;
        opacity: 0;
    }

    to {
        right: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        right: 30px;
        opacity: 1;
    }

    to {
        right: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        right: 30px;
        opacity: 1;
    }

    to {
        right: 0;
        opacity: 0;
    }
}

@media screen and (max-width:425px) {
    @-webkit-keyframes fadein {
        from {
            right: 0;
            opacity: 0;
        }

        to {
            right: 10px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            right: 0;
            opacity: 0;
        }

        to {
            right: 10px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            right: 10px;
            opacity: 1;
        }

        to {
            right: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            right: 10px;
            opacity: 1;
        }

        to {
            right: 0;
            opacity: 0;
        }
    }
}

.rating-info-grid {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-row-gap: 10px;
}

.rating-info-grid>span:nth-child(2n+2) {
    color: #2D6163;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;
    justify-self: end;
    align-self: end;
}

.rating-info-grid>span:nth-child(2n+1) {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
}

.color-green {
    color: #2b6b6f;
}

.new-code>a {
    font-size: 14px;
    border-bottom: 1px dashed blue;
}

.new-code {
    display: flex;
    justify-content: center;
}

.clear_padding {
    padding-left: 0;
}

.error>ul {
    margin-bottom: 0;
}

.price_search {
    padding-left: 0;
}

.nofify a {
    font-weight: 700;
    color: #551A8B;
}

.mb-mt-3 {
    margin-top: 60px;
}

.mb-p-3 {
    padding-right: 0;
}

.flex-mobile {
    justify-content: right;
}

.table_2c {
    width: 60%;
}

.bold {
    font-weight: 500;
}

.bold700 {
    font-weight: 700;
}

.raiting-table li {
    list-style: unset;
    margin-left: 40px;
    margin-bottom: 0.3em;
}

.raiting-table td:first-of-type p {
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 12px;
}

.fs13 {
    font-size: 13px !important;
}

.marked-text {
    font-weight: 600;
    text-decoration: underline
}

.select-font>select {
    padding: 15px 10px;
    font-size: 13px;
}

.select-font>select>option {
    font-size: 14px;
}

.m730 {
    max-width: 730px;
}

.adaptive {
    margin-left: -12px;
}

.favorite+label {
    display: flex;
    align-items: center;
    position: absolute;
    right: 35px;
    top: 30px;
}

.favorite+label:after {
    background-image: url('../../../public/img/favorite.png');
    background-size: 30px 30px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: "";
    margin-left: 10px;
    visibility: visible;
}

.favorite:checked+label:after {
    background-image: url("../../../public/img/favorite-checked.png");
}

.relative {
    position: relative;
}

.additional-menu {
    margin-top: 20px;
    border-top: 1px solid #e3e4ed;
}

.additional-menu a:after {
    display: none;
}

@media screen and (min-width:768px) {

    .portfolio-mb {
        max-width: 240px;
    }
}

@media screen and (min-width:1024px) {
    .w33 {
        width: 33% !important;
    }
}

@media screen and (min-width:1550px) {
    .w33 {
        width: 25% !important;
    }
}

@media screen and (min-width:1600px) {
    .w33 {
        width: 15% !important;
    }
}

.tariff-service-modal {
    display: none;
    max-width: 500px;
}

#registration_form_email,
#registration_form_plainPassword {
    margin-bottom: 20px;
}

.hbtns {
    border: #404883 2px solid;
    border-radius: 60px;
    /*width: 36vw;*/
    width: 100%;
    padding: 2px;
    /*max-width: 26rem;*/
    position: relative;
}

#slider_customer,
#slider_freelancer {
    width: 50%;
    cursor: pointer;
}

#slider_freelancer {
    z-index: 10;
    color: white;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
}

#slider_customer {
    margin-left: 10px;
    z-index: 10;
    color: black;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

#block-c {
    transform: translateX(0);
    transition: transform .2s;
    background: #404883;
    left: 2px;
    border-radius: 60px;
    height: 3rem;
    position: absolute;
    z-index: 1;
    max-width: 13rem;
    width: 50%;
}

.ft_tx5 li a:hover {
    text-decoration: underline;
}

.transform-head {
    transform: translateX(98%) !important;
}

.hbtns div {
    font-size: 12px;
}

.marked-ul li,
.marked-list li {
    display: flex;
    align-items: center;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
}

.marked-ul li:first-of-type:before,
.marked-ul li:nth-child(2):before,
.marked-ul li:nth-child(3):before,
.marked-list li:before {
    min-width: 3rem;
    margin-right: 10px;
    height: 3rem;
    background-size: 33px;
    background-repeat: no-repeat;
    background-position: center;
    content: "";
}

.marked-ul li:first-of-type:before {
    background-image: url('../../../public/img/pencil.png');
}

.marked-ul li:nth-child(2):before {
    background-image: url('../../../public/img/lock.png');
}

.marked-ul li:nth-child(3):before {
    background-image: url('../../../public/img/statistic.png');
}

.marked-list li:before {
    background-image: url('../../../public/img/accepted.png');
}

.timer {
    opacity: 0.25;
}

.time {
    font-weight: 800;
    font-size: 42px;
    position: relative;
}

.btn-orange {
    min-height: 46px;
    color: white;
    text-align: center;
    font-weight: 600;
    font-size: 26px;
    background: #FF8B20;
    border-radius: 15px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.tariff-li a {
    position: relative;
    display: flex;
    align-items: center;
    gap: 3px;
}

.tariff-li a:after {
    display: inline-block;
    /*top: -5px;*/
    /*right: -24px;*/
    content: url("../../../public/img/mdi_fire.svg");
    /*width: 21px;*/
    /*height: 20px;*/
}

.modal-select-role {
    position: fixed;
    margin: 0 auto;
    width: 30vw;
    height: 20px;
}

.sort-select {
    min-width: 15ch;
    max-width: 30ch;
    box-shadow: 0 0 6.44px 0.56px rgb(28 65 71 / 9%);
    border-radius: 0.25em;
    padding: 0.25em 0.5em;
    font-size: 1.25rem;
    margin-bottom: 10px;
    cursor: pointer;
    line-height: 1.1;
    background-color: #fff;
    background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}

.dark-button,
.dark-button-outline,
.dark-button-outline2 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: .2s;
    padding: 25px 40px;
    cursor: pointer;
}

.dark-button-menu,
.dark-button-menu-outline {
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    transition: .2s;
    cursor: pointer;
}

.dark-button,
.dark-button-menu,
.dark-button-menu-outline:hover {
    color: white;
    background: #3F4883;
}

.dark-button-menu:hover {
    background: #283167;
}

.dark-button:hover {
    background: #5C67B1;
}

.auth-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.auth-buttons button {
    height: 100%;
    padding: 10px;
    border-radius: 13px;
    font-size: 14px;
    font-weight: 500;
}

.dark-button-outline,
.dark-button-outline2,
.dark-button-menu-outline {
    color: #3F4883;
    border: 2px solid #3F4883;
    background: white;
}

.dark-button-outline:before {
    background: url("../../../public/img/toggler-blue.svg") no-repeat;
    background-size: 20px 12px;
    width: 20px;
    height: 12px;
    margin-right: 13px;
    display: inline-block;
    content: "";
}

.dark-button-outline:hover:before {
    background: url("../../../public/img/toggler-white.svg") no-repeat;
}

.dark-button-outline:hover,
.dark-button-outline2:hover {
    background: #3F4883;
    color: white;
}

.calendar:before {
    margin-right: 8px;
    width: 33px;
    height: 33px;
    content: url("../../../public/img/calendar.svg");
}

.margin-auto>* {
    margin: 0 auto;
}

.pf-wrap {
    width: 90%;
    margin: 0 auto;
}

.mb-wrap {
    flex-wrap: wrap;
}

.disabled:hover {
    background: white !important;
    cursor: unset;
}

.ps_b02_b6 {
    flex-wrap: wrap;
}

.search-button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    background: url("../../../public/img/dark-magnifying.svg") no-repeat center;
    margin-left: 12px;
}

.search-container {
    padding: 30px 10px;
    z-index: 100;
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    right: -100vw;
    transition: all .3s;
}

.menu-container {
    background: white;
    padding: 43px 0;
    z-index: 100;
    display: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    position: fixed;
}

.search-container-active {
    right: 0;
}

.tariff-button-container {
    max-width: 305px;
    margin: 0 auto;
}