.smart-button {
  position: fixed;
  right: 47px;
  bottom: -200px;
  z-index: 100;
  transition: bottom .3s;
  @include media(1200) {
    right: calc(50% - 145px); } }

.smart-button_active {
  bottom: 108px;
  @include media(1200) {
    bottom: 20px; } }

.smart-button__button {
  @include button_yellow;
  max-width: 323px;
  padding: 16px 23px 12px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  cursor: pointer;
  @include media(1200) {
    max-width: 290px;
    padding: 14px 22px 9px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px; } }
