.header-land {
  padding-top: 14px;
  margin-bottom: 58px;
  @include media(1200) {
    padding-top: 11px;
    margin-bottom: 34px; } }

.header-land__cont {
  @include cont;
  display: flex;
  position: relative; }

.header-land__logo {
  flex-shrink: 0;
  display: block;
  margin-right: 50px;
  width: 140px;
  height: 70px;
  @include media(1200) {
    margin-right: 9px;
    width: 95px;
    height: 47px; } }

.header-land__logo-image {}

.header-land__burger {
  display: none;
  padding: 10px;
  position: absolute;
  top: -6px;
  left: 120px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  @include media(1200) {
    display: block; } }

.header-land__burger_active {
  position: absolute;
  top: -6px;
  left: 6px;
  z-index: 2;
  .header-land__burger-line {
    background-color: #00AF4A;
    &::before, &::after {
      background-color: #00AF4A; } } }

.header-land__burger-line {
  border-radius: 3px;
  width: 25px;
  height: 3px;
  background-color: #3F4883;
  &::before, &::after {
    content: '';
    position: absolute;
    left: 10px;
    width: 25px;
    height: 3px;
    background-color: #3F4883; }
  &::before {
    top: 12px; }
  &::after {
    bottom: 12px; } }

.header-land__list {
  margin-top: 6px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-self: center;
  @include media(1200) {
    margin-top: 0;
    display: none; } }

.header-land__list_active {
  @include media(1200) {
    display: block;
    max-width: 265px;
    padding: 41px 6px 30px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: white; } }

.header-land__item {}

.header-land__link {
  padding: 26px 10px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3F4883;
  @include media(1200) {
    display: block;
    padding: 8px 10px;
    font-size: 15px;
    line-height: 18px; } }
