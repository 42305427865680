.seo-land {
  position: relative;
  z-index: 1;
  padding-top: 147px;
  padding-bottom: 90px;
  @include bgImg('../../../public/img/bg_seo-land-1.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  @include media(1200) {
    padding-top: 120px;
    padding-bottom: 28px; }
  @include media(992) {
    padding-top: 48px; }
  &::before {
    content: '';
    position: absolute;
    top: 180px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;
    background-color: #F3F8FB;
    @include media(1200) {
      top: 120px; } } }

.seo-land__cont {
  @include cont;
  @include media(992) {
    position: relative;
    padding-bottom: 197px; }
  &::before {
    content: '';
    display: table;
    clear: both; } }

.seo-land__info {}

.seo-land__title {
  margin-bottom: 59px;
  @include media(1920) {
    margin-top: -75px; }
  @include media(1200) {
    margin-top: -70px; }
  @include media(992) {
    margin-top: 0; }
  @include title;
  @include media(1200) {
    margin-bottom: 30px; } }

.seo-land__text {
  margin-bottom: 16px;
  letter-spacing: 0.02em;
  font-size: 15px;
  line-height: 22px;
  @include media(1200) {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 21px; } }

.seo-land__picture {
  float: right;
  display: block;
  margin: 129px 130px 60px 40px;
  width: 524px;
  height: 367px;
  @include media(1500) {
    width: 273px;
    height: 191px; }
  @include media(992) {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    float: none;
    margin: 0 auto; } }

.seo-land__image {
  display: block; }

