$green: #00af49;
$greenlight: #e3f6eb;
$greenhover: rgba(0, 175, 73, 0.11);
$greenblue: #f3f9fa;
$greendisabled: #7fd7a4;
$blue: #3f4883;
$bluehover: rgba(64, 72, 131, 0.2);
$bluedark: #404883;
$black: #000000;
$blacklight: #515151;
$white: #ffffff;
$yellow: #ffaf00;
$yellowhover: rgba(255, 175, 0, 0.2);
$yellowgradient: #ff9600;
$red: #f52424;
$gray: rgba(64, 72, 131, 0.5);
$orange: #ffac00;


/* --------------------------------------------- general --------------------------------------------*/
.text {
  color: $black;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.48px;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 400px;
  color: $black;
  text-decoration: none;
  font-size: 13px;
  line-height: 14px;
  border-radius: 28px;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
}
.link {
  color: $blue;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  text-decoration: underline;
}
.img {
  max-width: 100%;
}
.modal-close {
  right: -40px;
  top: 10px;
  position: absolute;
  cursor: pointer;
  background: url("img/icon__closegrey.png") no-repeat 50%;
  height: 31px;
  width: 31px;
}
.yellow-ellipse {
  height: 48px;
  width: 100px;
  border-radius: 10px;
  background: $yellow;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 53px;
}

.list {
  list-style: none;
}
.list__item {
  margin: 0 0 30px 0;
  position: relative;
}

.block {
    display: block;
    padding: 17px 17px 24px 17px;
    width: 100%;
}

.number-in-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    border: 3px solid $black;
    color: $black;
    font-size: 23px;
    line-height: 42px;
    font-weight: 700;
}

.form_content_2col .label {
    margin: 0 0 15px 0;
    font-size: 16px;
    font-weight: 600;
    display: block;
}
.input {
    border: 1px solid $gray;
    border-radius: 3px;
    height: 48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1;
    width: 100%;
    max-width: 350px;
    &:hover {
      border-color: $bluedark;
    }
    &:active {
      border-color: $bluedark;
    }
    &:focus {
      border-color: $bluedark;
    }
}
.textarea {
    height: 110px;
    width: 100%;
    resize: none;
    padding: 16px 20px;
    border-radius: 3px;
    border: 1px solid $gray;
    &:hover {
      border-color: $bluedark;
    }
    &:active {
      border-color: $bluedark;
    }
    &:focus {
      border-color: $bluedark;
    }
}
.input:-ms-input-placeholder, .input:-moz-placeholder, .input::-moz-placeholder, .input::-webkit-input-placeholder,
.textarea:-ms-input-placeholder, .textarea:-moz-placeholder, .textarea::-moz-placeholder, .textarea::-webkit-input-placeholder {
    opacity: 0.8;
}

.hidden {
  display: none  !important;
}

#multistep_modal select {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background: url('img/icon__arrow-down-fill.png') no-repeat;
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 3px;
    position: relative;
    appearance: none;
    padding: 0px 20px;
    background-position-y: center;
    background-position-x: calc(100% - 20px);
}
#multistep_modal select option {
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: $white;
  height: 48px;
}

.button-for-add {
    display: flex;
    align-items: center;
}
.button-for-add__icon-plus {
    flex-shrink: 0;
    display: block;
    width: 41px;
    height: 41px;
    background: $white;
    border: 2px solid $orange;
    color: $orange;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 31px;
    line-height: 1;
    margin: 0 12px 0 0;
    border-radius: 50%;
}
.button-for-add__icon-del {
  display: block;
  width: 21px;
  height: 21px;
  background: url('img/icon__close-red.png') no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px 0 0;
}
.button-for-add__text {
    padding: 10px 28px 12px 17px;
    background-color: $greenblue;
    border-radius: 10px;
    color: $blacklight;
    font-size: 12px;
    line-height: 1;
}

/* -------------------------------------------- change ------------------------------------------- */
.text_weight_extra-bold {
  font-weight: 800;
}
.text_color_blue {
  color: $blue;
}
.text_color_green {
    color: $green;
}
.text_spelling_big {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}
.text_spelling_very-big {
  font-size: 38px;
  font-weight: 700;
  line-height: 53px;
  @media screen and (max-width: 950px) {
    font-size: 32px;
    line-height: 40px;
  }
  @media screen and (max-width: 375px) {
    font-size: 26px;
    line-height: 30px;
  }
}
.text_contains_span-green span {
  color: $green;
}
.text_position_center {
    text-align: center;
}


.list_style_red-star li {
  padding: 0 0 0 25px;

  &:before {
    content: '*';
    color: $red;
    font-size: 33px;
    font-weight: 700;
    line-height: 34px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
}
.button_outline_green {
  position: relative;
  margin: 12px 0 12px 0;

  &:before {
    content: '';
    position: absolute;
    border: 1px solid $green;
    top: -6px;
    left: -7px;
    width: calc(100% + 13px);
    height: calc(100% + 13px);
    border-radius: inherit;
  }
}
.button_color_green {
  background-color: $green;
  box-shadow: 0 3px 6px 2px #203e2a;
  color: $white;
  //
  //&:hover {
  //  position: relative;
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    border: 7px solid $greenhover;
  //    top: -7px;
  //    left: -7px;
  //    width: 100%;
  //    height: 100%;
  //    border-radius: inherit;
  //  }
  //}
}
.button_color_green:focus {
  box-shadow: 0 3px 6px 0 #203e2a;
}

.button_color_green.disabled {
  background-color: $greendisabled;
}
.button_color_blue {
  background-color: $blue;
  color: $white;

  &:hover {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      border: 7px solid $bluehover;
      top: -7px;
      left: -7px;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
  }
}
.button_spelling_big {
  font-size: 18px;
  font-weight: 700;
}
.button_color_yellow {
    background: linear-gradient(to top, $yellowgradient 0%, $yellow 100%);
    color: $black;
    font-weight: 700;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: calc(100% + 14px);
      height: calc(100% + 14px);
      background-color: $yellowhover;
      border-radius: 35px;
      position: absolute;
      top: -7px;
      left: -7px;
    }
}
.button_color_yellow:hover, .button_color_yellow:active, .button_color_yellow:focus {
    background: $yellow;
}

.link_weight_semi-bold {
  font-weight: 600;
}

.block_bg_light-green {
    background-color: $greenblue;
}

.number-in-circle_color_blue {
    border-color: $bluedark;
    color: $bluedark;
}

.form_content_2col {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.form_content_2colv2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}

.form_content_2col__col {
    width: 350px;
    max-width: 49%;
    margin: 0 0 30px 0;
    @media screen and (max-width: 950px) {
      width: 100%;
      max-width: 100%;
    }
}
.form_content_2col__colv2 {
  margin: 0 0 30px 0;
  width: 350px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.form_content_2col__full {
    //display: flex;
    width: 100%;
    margin: 0 0 30px 0;
}
.w100 {
  width: 100%;
}
.form_content_2col__privacy-policy {
  margin: 0 auto;
}
.label_position_center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.input_position_center {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
}

.button_color_white-green-border {
    background: $white;
    border: 2px solid $green;
    color: $black;
    &:hover {
      background-color: $green;
      color: $white;
    }
    &:active {
      background-color: $green;
      color: $white;
    }
    &:focus {
      background-color: $green;
      color: $white;
    }
}

.form_content_2col .label.label_type_privacy-policy-checker {
    max-width: 350px;
    width: 100%;
    position: relative;
    font-size: 10px;
    line-height: 15px;
    padding: 0 0 0 40px;
    &::before {
      content: '';
      width: 14px;
      height: 14px;
      border-radius: 4px;
      border: 1px solid $gray;
      /*background-image: linear-gradient(to top, #29b311 0%, #29b311 1%, #57d841 100%);*/
      position: absolute;
      top: 0;
      left: 0;
    }
}
.input_type_privacy-policy-checker {
  display: none;
}
.input_type_privacy-policy-checker:checked + label {
  &::before {
    background: url('img/iocn__checkmark-green-block.png') no-repeat center;
    border-color: transparent;
  }
}
.forms-block-inline {
  display: flex;
  align-items: center;
}
.forms-block-inline__label {
  width: 270px;
  margin: 0;
}
.forms-block-inline__input {
  width: 260px;
}
.label_position_center+.field-error-container,
.form_content_2col__privacy-policy .field-error-container {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
}


.disabled-mb-m {
  margin: 0 !important;
  > span {
    color: #0b9d54;
  }
}
.switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

.slider-box:checked + .slider {
  background-color: #66bb6a;
}

.slider-box:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
