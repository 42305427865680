.landing {
  overflow-x: hidden;
  .title {
    font-size: 24px;
    @media screen and (min-width:768px)  {
      font-size: 36px;
    }
  }
  .conditions {
    color:rgba(103, 103, 103, 1) ;
    border-bottom: 1px dashed #676767;
    font-size: 8px;
    font-weight: 500;
    @media screen and (min-width:1024px)  {
      font-size: 16px;
    }
  }
  &__block {
    padding: 30px 0 80px;
    max-width: 1230px;
    margin: 0 auto;
    @media screen and (max-width:1229px)  {
      padding: 30px 6vw 49px;
    }
  }
  .ln1 {
    justify-content: space-between;
    display: flex;
    @media screen and (max-width:1023px)  {
      padding: 30px 6vw 49px;
    }
    @media screen and (max-width:1023px)  {
      flex-direction: column;
      align-items: center;
    }
    &__img {
      margin-right: 2rem;
      @media screen and (max-width:1023px)  {
        width: 100%;
      }
    }
    &__common {
      max-width: 579px;
      h1 {
        font-size: 24px;
        @media screen and (min-width:768px)  {
          font-size: 36px;
        }

        span {
          color: rgba(44, 174, 73, 1);
        }
      }
      p {
        font-size: 20px;
        @media screen and (min-width:1200px)  {
          font-size: 24px
        }
      }
    }
  }

  .ln2 {
    padding: 30px 0 140px;
    @media screen and (max-width:1229px)  {
      padding: 30px 6vw 140px;
    }
    .background-block {
      background: url("../../../public/img/background.png") no-repeat;
      background-size: cover;
      min-width: 100%;
      min-height: 80vh;
      position: absolute;
      left: 0;
      z-index: -1;
    }
    & img {
      width: 100%;

      @media screen and (min-width:1024px)  {
        position: relative;
        top:-53px;
      }
    }
    &__common {
      display: flex;
      border: 2px solid #000;
      border-radius: 15px;
      padding: 32px;
      justify-content: space-between;
      background: white;
      @media screen and (max-width:1023px)  {
        padding: 12px;
      }
      & a {
        @media screen and (max-width:1023px)  {
          margin-top: 10px;
        }
      }
      &>div:last-child {
        display: flex;
        @media screen and (max-width:1023px)  {
          width: 50%;
          flex-direction: column;
        }
        &>* {
          width: 48%;
          @media screen and (max-width:1023px)  {
            width: 100%;
            min-height: 86px;
          }
        }
        &>div:first-of-type {
          padding: 10px 17px;
          border: 2px solid #000;
          border-radius: 15px;
          @media screen and (min-width:1024px)  {
            padding: 16px 35px;
            margin-right: 10px;
          }
          &>p:first-of-type {
            margin-top: 0;
            font-size: 8px;
            @media screen and (min-width:768px)  {
              font-size: 14px;
            }
            @media screen and (min-width:1200px)  {
              font-size: 16px
            }
          }
          &>p:nth-child(2) {
            font-size: 20px;
            font-weight: 300;
            text-decoration: line-through ;
          }
          &>p:last-child {
            color: rgba(135, 193, 89, 1);
            font-weight: 700;
            font-size: 16px;
            @media screen and (min-width:450px)  {
              font-size: 20px
            }
            @media screen and (min-width:1200px)  {
              font-size: 24px
            }
          }
        }
      }
      &>div:first-of-type {
        &>p {
          font-size: 14px;
          @media screen and (min-width:1024px)  {
            font-size: 20px;
          }
        }
        &>h2 {
          margin-bottom: 10px;
          font-size: 20px;
          @media screen and (min-width:1024px)  {
            font-size: 32px;
          }
        }
      }

    }
    &>div:last-child {
      padding-top: 50px;
      justify-content: space-between;
      display: flex;
      @media screen and (max-width:1023px)  {
        flex-direction: column;
        justify-content: center;
      }
      @media screen and (min-width:1024px)  {
        padding-top: 182px;
      }
    }
    &>div>div {
      width: 100%;
      @media screen and (min-width:1024px)  {
        width: 50%;
      }
    }
  }
  .ln3 {
    height: 300px;
    background: #000000;
    position: relative;
    color: white;
    overflow: hidden;
    padding-bottom: 10px;
    max-width: 100vw;
    @media screen and (min-width:1024px)  {
      height: 524px;
    }
    &__timer {
      width: 100%;
      @media screen and (min-width:400px)  {
        width: 275px;
      }
      @media screen and (min-width:1024px)  {
        width: 400px;
      }
      &>div {
        display: flex;
        justify-content: space-around;
      }
    }
    & .timer {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: cover;
      max-width: 100%;
    }
    &>div {
      height: 100%;
      &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex-direction: column
      }
    }
    &__percent-container {
      position: absolute;
      left: 0;
      right: 0;
      width: 1230px;
      top: 0;
      margin: 0 auto;
    }
    &__percent {
      position: absolute;
      top: 25px;
      left: 0;
      width: 50px;
      @media screen and (max-width:1229px)  {
        left: 6vw;
      }
      @media screen and (min-width:1024px)  {
        width: auto;
        top: 20%;
      }
    }
  }
  &__image {
    width: 100%;
  }
}
