//стили используемые только на в одном разеделе сайта или на одной странице
.project-tags {
    font-size: 0.7rem;
    margin-bottom: 15px;
}

.tree-open {
    display: block;
}

.tree-hide {
    display: none;
}

.select-specs {
    cursor: pointer;
    border-radius: 5px;
    background: #DDDEE9;
    padding: 10px 20px;
    color: #000;
    font-size: 16px;
    position: relative;
    font-weight: 600;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media screen and (max-width: 1200px) {
        font-size: 14px;
    }
}

.freelancers- {
    &header-with-button {
        @media screen and (max-width: 768px) {
            flex-wrap: wrap;
        }

        .btn2 {
            @media screen and (min-width: 769px) {
                margin-left: 15px;
            }

            @media screen and (max-width: 768px) {
                margin-top: 15px;
            }
        }
    }
}

.tariff-customer-wrap {
    @media screen and (min-width: 1200px) {
        max-width: 972px !important;
    }

    .buy-tariff-wrap__list {
        justify-content: center;

        @media screen and (min-width: 1200px) {
            justify-content: space-between;
        }

        >div {
            height: 140px;
            max-width: 400px;

            @media screen and (max-width: 1200px) {
                width: 340px;
            }
        }
    }
}

.tariff-modal-wrap {
    @media screen and (min-width: 1200px) {
        max-width: 1115px;
    }

    max-width: 90vw;

    .buy-tariff-wrap__form {
        margin-bottom: 0;
    }

    @media screen and (max-width: 525px) {
        min-width: 90vw;
    }

    button {
        width: 100%;
        height: 60px;
        margin: 0 auto 10px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        padding: 1em;

        @media screen and (min-width: 450px) {
            width: 340px;
        }
    }

    .tariff-radio__discount-price {
        font-size: 18px;

        @media screen and (min-width: 768px) {
            font-size: 24px;
        }
    }

    .tariff-radio__content {
        >div:first-of-type {
            font-size: 14px
        }
    }

    .multistep-tariff-form {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .buy-tariff-wrap__list {
            margin-bottom: 0;
        }

        .buy-tariff-wrap__info {
            padding-bottom: 30px;

            @media screen and (min-width: 1400px) {
                margin-bottom: 0;
            }

            ul li:last-child {
                margin-bottom: 0;
            }
        }

        >div {
            @media screen and (min-width: 1400px) {
                width: 50%;
            }
        }

        >div:first-of-type {
            margin-bottom: 30px;

            >div>div:nth-child(3) {
                border-bottom: 1px solid rgba(63, 72, 131, 0.2);
                padding-bottom: 20px;

                @media screen and (min-width: 1400px) {
                    margin-bottom: 20px;
                    padding-bottom: 0;
                }
            }

            @media screen and (min-width: 1400px) {
                >div>div:not(:first-of-type) {
                    border-bottom: none;
                    border-right: 1px solid rgba(63, 72, 131, 0.2);
                    padding-right: 30px;
                }

                border-bottom:none;
                margin-bottom: 0;
            }
        }

        >div:last-child {
            @media screen and (min-width: 1400px) {
                padding-left: 30px;
            }
        }
    }
}

.projects-wrap {
    display: flex;
    justify-content: center;
    margin-bottom: 135px;
    margin-top: 50px;
    flex-wrap: wrap;
    gap: 12px;

    >a {
        width: 100%;
        height: 70px;
        font-size: 18px;
        font-weight: 500;

        @media screen and (min-width: 425px) {
            width: 300px;
        }
    }
}

.slider-container-project {
    display: flex;
    align-items: stretch;
}

$gap: 30px;
$gap-l: 15px;

.projects {
    &__skeleton {
        width: 100%;
        max-height: 50vh;

        @media screen and (min-width: 768px) {
            max-height: 250px;
            width: calc(50% - #{$gap});
        }
    }

    &__item {
        display: flex;
        width: 100%;
        height: 100%;

        &:hover {
            background: #ECF6F7;
        }

        border-radius: 5px;
        border: 1px solid #E4E5E7;
        background: #FFF;
        color: black;
        padding: 20px 30px;
    }

    .item {
        &__header {
            display: flex;
            flex-direction: column;
            //justify-content: space-between;

            //@media screen and (min-width: 1024px) {
            //    display: flex;
            //    align-items: center;
            //    justify-content: space-between;
            //    margin-bottom: 25px;
            //}
        }

        &__title {
            font-weight: 700;
            font-size: 20px;
            margin-bottom: 10px;
            text-overflow: ellipsis;
            //white-space: nowrap;
            overflow: hidden;
            letter-spacing: 0;
        }

        &__common {
            overflow-wrap: break-word;
            max-height: 50px;
            overflow: hidden;

            //@media screen and (min-width: 1024px) {
            //    width: 50%;
            //}
        }

        &__specs {
            margin-top: 10px;
            display: flex;
            //justify-content: flex-end;

            @media screen and (min-width: 1024px) {
                //margin-top: 0;
                //width: 50%;
            }

            >ul {
                max-width: 100%;

                >li {
                    padding: 5px 10px;
                    background: rgba(0, 175, 74, 0.43);
                    border-radius: 5px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    word-break: break-all;

                    @media screen and (max-width: 768px) {}

                    &>span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    &:before {
                        content: url("../../../public/img/uil_paragraph.svg");
                        margin-right: 8px;
                    }
                }
            }
        }

        &__description {
            font-size: 16px;
            max-height: 78px;
            line-height: 19.5px;
            font-weight: 400;
            overflow: hidden;
            word-break: break-word;
            display: -webkit-box;
            margin-top: 10px;
            margin-bottom: 0;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            letter-spacing: 0;

            &::-webkit-scrollbar {
                width: 3px;
            }

            &::-webkit-scrollbar-track {}

            &::-webkit-scrollbar-thumb {
                background-color: darkgrey;
            }
        }

        &__date {
            padding: 7px 18px;
            width: fit-content;
            height: 30px;
            background: #FFBE5B;
            border-radius: 5px;
        }

        &__budget {
            font-size: 16px;
            font-weight: 700;
            text-align: right;
            margin-top: 10px;

            @media screen and (min-width: 1024px) {
                font-size: 37px;
            }
        }
    }
}

.announcement {
    background: #FFFFFF;
    box-shadow: -2px 7px 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 21px 31px;
    width: 100%;
    max-height: 50vh;
    color: black;
    display: block;

    &__rating {
        display: flex;
    }

    &__rating:before {
        content: url("../../../public/img/rating.svg");
        margin-right: 4px;
    }

    &__avatar {
        border-radius: 100%;
        max-width: 90px;
    }

    &:hover {
        background: #ECF6F7;
    }

    @media screen and (min-width: 768px) {
        max-height: 350px;
    }

    @media screen and (min-width: 1000px) {
        max-height: 250px;
    }

    &__header {
        display: block;

        @media screen and (min-width: 425px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @media screen and (min-width: 1024px) {
            margin-bottom: 25px;
            flex-wrap: nowrap;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__common {
        margin-left: 10px;
        width: 100%;

        h2 {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        @media screen and (min-width: 425px) {
            width: 70%;
        }
    }

    &__specs {
        margin-top: 15px;
        display: flex;

        >ul {
            >li {
                width: 250px;
                padding: 5px 10px;
                background: rgba(0, 175, 74, 0.43);
                border-radius: 5px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                word-break: break-all;

                &>span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:before {
                    content: url("../../../public/img/uil_paragraph.svg");
                    margin-right: 8px;
                }
            }
        }
    }

    &__description {
        font-size: 16px;
        font-weight: 400;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        /* start showing ellipsis when 3rd line is reached */
        margin-bottom: 30px;
        max-height: 66px;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {}

        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
        }
    }

    &__date {
        padding: 2px 18px;
        width: fit-content;
        height: 19px;
        background: #FFBE5B;
        border-radius: 5px;
    }

    &__budget {
        font-size: 16px;
        font-weight: 700;
        text-align: right;

        @media screen and (min-width: 1024px) {
            font-size: 32px;
        }
    }
}

.skeleton {
    position: relative;
    height: 100%;

    &__wrapper {
        border-radius: 20px;
        font-weight: 700;
        font-size: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.55);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 10;
        text-align: center;
        padding: 20px;
    }

    &__content {
        padding: 24px 36px;

        >div:nth-child(1) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            >div:first-of-type {
                width: 50%;

                >div:first-of-type {
                    width: 100%;
                    height: 24px;
                    background: #C5C5C5;
                    margin-bottom: 12px;
                }

                >div:last-child {
                    width: 70%;
                    height: 19px;
                    background: #ACACAC;
                    border-radius: 5px;
                }
            }

            >div:last-child {
                width: 30%;
                height: 37px;
                background: #D9D9D9;
                border-radius: 9px;
            }
        }

        >div:nth-child(2) {
            >div {
                height: 11px;
                margin-bottom: 6px;
                background: #D9D9D9;
            }

            >div:nth-child(1) {
                width: 90%;
            }

            >div:nth-child(2) {
                width: 70%;
            }

            >div:nth-child(3) {
                width: 75%;
            }

            >div:nth-child(4) {
                width: 80%;
            }
        }

        >div:nth-child(3) {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            >div {
                width: 105px;
                height: 34px;
                background: #D9D9D9;
            }
        }
    }
}

.announcements {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;

    &__skeleton {
        width: 100%;
        max-height: 50vh;

        @media screen and (min-width: 768px) {
            max-height: 250px;
            width: calc(50% - #{$gap});
        }

        @media screen and (min-width: 1024px) {
            width: calc(33% - #{$gap});
        }
    }

    >.announcement {
        @media screen and (min-width: 768px) {
            width: calc(50% - #{$gap});
        }

        @media screen and (min-width: 1024px) {
            width: calc(33% - #{$gap});
        }
    }
}

.announcements {
    &__skeleton {
        @media screen and (min-width: 768px) {
            max-height: 350px;
        }

        @media screen and (min-width: 1000px) {
            max-height: 250px;
        }
    }
}

.projects {
    &__skeleton {
        @media screen and (min-width: 768px) {
            width: calc(50% - #{$gap});
        }
    }
}

.arrow-unstuck-right {
    right: -65px;

    @media screen and (max-width: 1350px) {
        right: -55px;
    }

    @media screen and (max-width: 1250px) {
        right: -30px;
    }

    @media screen and (max-width: 1150px) {
        right: -20px;
    }

    @media screen and (max-width: 1000px) {
        right: -15px;
    }

    @media screen and (max-width: 770px) {
        right: -30px;
    }

    @media screen and (max-width: 525px) {
        right: -25px;
    }
}

.arrow-right {
    right: -25px;

    @media screen and (max-width: 1200px) {
        right: -15px;
    }

    @media screen and (max-width: 525px) {
        right: 0;
    }
}

.slider-controls {
    position: absolute;
    top: 50%;
    width: 100%;
}

.arrow-unstuck-left,
.arrow-left {
    transform: rotateY(180deg);
}

.arrow-unstuck-left {
    left: -65px;

    @media screen and (max-width: 1350px) {
        left: -55px;
    }

    @media screen and (max-width: 1250px) {
        left: -30px;
    }

    @media screen and (max-width: 1150px) {
        left: -20px;
    }

    @media screen and (max-width: 1000px) {
        left: -15px;
    }

    @media screen and (max-width: 770px) {
        left: -30px;
    }

    @media screen and (max-width: 525px) {
        left: -25px;
    }
}

.arrow-left {
    left: -25px;

    @media screen and (max-width: 1200px) {
        left: -15px;
    }

    @media screen and (max-width: 525px) {
        left: 0;
    }
}

.arrow {
    position: absolute;
    bottom: 0;
    margin: auto 0;
    top: 0;
    cursor: pointer;
    z-index: 10;
    width: 62px;
    height: 62px;
    background: url("../../../public/img/arrowCircle.svg") no-repeat center;
}

.carousel-wrap {
    position: relative;
}

.carousel {
    overflow: hidden;

    &__dots {
        position: absolute;
        bottom: 10px;
        height: 10px;
        right: 0;
        left: 0;
        margin: 0 auto;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
    }
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    vertical-align: bottom;
    justify-content: center;
}

.carousel-item:last-child {
    //margin-right: 0 !important;
}

.tns-nav {
    display: none;
}

.slide-dote {
    width: 5px;
    height: 5px;
    background: #A7A7A7;
    cursor: pointer;
    border-radius: 50%;
}

.slide-dote-active {
    width: 7px;
    height: 7px;
    background: #787878;
}

.banner {
    height: 122px;
    width: 100%;

    &__content {}

    &__text {}

    &__button-orange,
    &__button-blue {
        padding: 8px 10px;
        color: white;
        font-size: 18px;
        border-radius: 103px;
        white-space: break-spaces;

        @media screen and (max-width: 768px) {
            font-size: 14px;
        }

        @media screen and (max-width: 425px) {
            font-size: 12px;
        }
    }

    &__button-orange {
        background: linear-gradient(180deg, #FF9829 0%, #FF8706 99.99%, #FF9829 100%);
        border-radius: 103px;
        font-weight: 600;
        outline: #FFBC75 5px solid;
    }

    &__button-blue {
        background: linear-gradient(180deg, #37BBFE 0%, #007DBB 100%);
        border-radius: 47.58px;
        font-weight: 500;
        outline: #95D1EE 5px solid;
    }
}

.banner1 {
    background: url("../../../public/img/banner1-back.jpg");
    padding: 14px 35px 24px;

    &__content {
        padding: 12px 49px;
        background: white;
        display: flex;
        justify-content: space-between;
        box-shadow: -2px 2px 20px rgba(0, 0, 0, 0.03);
        border-radius: 5px;

        @media screen and (max-width: 768px) {
            padding: 12px 12px;
        }
    }

    &__text {
        width: 65%;
        white-space: break-spaces;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #081150;
        margin-right: 5px;

        @media screen and (max-width: 1444px) {
            font-size: 16px;
            line-height: 18px;
        }

        @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 16px;
        }

        @media screen and (max-width: 425px) {
            font-size: 12px;
            line-height: 14px;
        }
    }

    button {
        width: 195px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        height: 60px;

        @media screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 16px;
        }

        @media screen and (max-width: 425px) {
            font-size: 12px;
            line-height: 14px;
            width: 145px;
        }
    }
}

.banner2 {
    background: url("../../../public/img/banner2-back.jpg");
    padding: 8px 37px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        padding: 8px 8px 24px;
    }

    button {
        font-weight: 500;
        width: 180px;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
            line-height: 16px;
        }

        @media screen and (max-width: 425px) {
            font-size: 12px;
            width: 105px;
            line-height: 14px;
        }
    }

    &__content {
        display: flex;
        align-items: center;

        >div {
            background: #EBEFFF;
            position: relative;
            border-radius: 20px;
            padding: 9px 19px;
            box-shadow: inset 0px -2px 16px rgba(82, 130, 255, 0.1);
            max-width: 420px;
            margin-right: 10px;

            @media screen and (max-width: 768px) {
                background: transparent;
            }

            &:before {
                position: absolute;
                content: url("../../../public/img/triangle.png");
                bottom: -20px;
                left: 20px;
                width: 20px;
                height: 20px;

                @media screen and (max-width: 768px) {
                    display: none;
                }
            }

            >p {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #000C7C;
                white-space: break-spaces;

                @media screen and (max-width: 1024px) {
                    font-size: 14px;
                    line-height: 16px;
                    background: transparent;
                }

                @media screen and (max-width: 425px) {
                    line-height: 12px;
                    font-size: 10px;
                }
            }
        }
    }
}

.banner3 {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    background: linear-gradient(276.18deg, #FFFFFF 27.6%, #F1FFFD 65.6%, #D6F0FF 121.85%);

    &__content {
        padding: 8px 14px 24px 37px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            padding: 12px 12px;
        }

        >p {
            color: rgba(0, 12, 124, 1);
            white-space: break-spaces;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            width: 55%;

            >span {
                font-weight: 700;
            }

            @media screen and (max-width: 1200px) {
                font-size: 12px;
                line-height: 14px;
                width: 85%;
            }

            @media screen and (max-width: 425px) {
                width: 100%;
                font-size: 10px;
                line-height: 12px;
            }
        }

        >.logo {
            position: absolute;
            right: 274px;

            @media screen and (max-width: 1200px) {
                position: static;
                margin-right: 20px;
            }

            @media screen and (max-width: 425px) {
                margin-right: 0;
            }
        }

        >.mb {
            height: 114px;
        }
    }
}

.header .search_field {
    //width: 30vw;
    max-width: 375px;
    min-width: 175px;
}

.search_field {
    border: 1px solid rgba(160, 164, 194, 1);
    display: flex;
    align-items: center;

    input {
        padding: 10px;
        border-radius: 5px;
        border: none;
        height: 50px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1px;
        width: 100%;

        @media screen and (max-width: 425px) {
            font-size: 12px;
        }

        &::placeholder {
            color: rgba(160, 164, 194, 1);
        }
    }
    .search-button-container-green-st {
        width: 20em !important;
    }
    .search-button-container-green {
        cursor: pointer;
        font-size: 15px;
        font-weight: 600;
        border-radius: 0 15px 15px 0;
        height: 50px;
        width: 14em;
        background: #0D8042;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 425px) {
            font-size: 12px;
            width: 14em;
        }
    }

    .search-button-container {
        cursor: pointer;
        border-radius: 0 5px 5px 0;
        background: rgba(24, 35, 107, 1);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.header .search_field {
    background: white;
    border-radius: 5px;

    input {
        max-width: 300px;
        min-width: 125px;
    }

    .search-button-container {
        height: 50px;
        width: 75px;
    }
}

.main-page {
    button {
        @media screen and (max-width: 525px) {
            width: 100%;
        }
    }
    .main-page-title {
        width: 133%;
        @media screen and (max-width: 1200px) {
            width: auto;
        }
    }
    .col6 {
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }

    .arrow-right {}

    .title {
        font-size: 38px;
        font-weight: 700;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .title {
        font-size: 38px;
        font-weight: 700;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }
    }

    .block1 {
        overflow: hidden;
        background: #F3F9FA;
        padding: 83px 0 0;
        margin-bottom: 60px;

        @media screen and (max-width: 768px) {
            padding: 30px 0 0;
        }

        .search_field {
            width: 100%;
            max-width: 800px;
            border: none;
            box-shadow: 1px 4px 11px 0 rgba(0, 0, 0, 0.05);
            border-radius: 15px;
        }

        .search_field input {
            height: 56px;
            max-width: 100%;
            padding: 10px 30px;
            border-radius: 15px 0 0 15px;
        }

        .search-button-container-green {
            height: 56px;
        }

        .flex1 {
            >div:first-of-type {
                flex: 1 1 1000px;

                button {
                    margin-bottom: 48px;
                }

                h1 {
                    color: #2D6163;
                    font-size: 48px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    margin-bottom: 20px;

                    @media screen and (max-width: 768px) {
                        font-size: 24px;
                    }
                }

                .help-info {
                    color: #000;
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    margin-bottom: 60px;

                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                        margin-bottom: 50px;
                    }
                }

                .popular-categories {
                    margin-top: 20px;
                    margin-bottom: 70px;
                    display: flex;

                    @media screen and (max-width: 500px) {
                        flex-wrap: wrap;
                    }

                    >p {
                        white-space: nowrap;
                        margin: 0;
                        font-size: 16px;
                        font-weight: 600;
                        text-align: center;
                        color: #000;
                    }

                    >p:last-child {
                        margin-left: 10px;
                        gap: 10px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                    }

                    >p a {
                        border-radius: 30px;
                        border: 1px solid #000;
                        padding: 5px 8px;
                        color: black;

                        &:hover {
                            background: #000;
                            color: white;
                        }
                    }
                }
            }

            >div:last-child {
                width: 530px;
                display: flex;
                align-items: flex-end;
                position: relative;
                left: 40px;
                @media screen and (max-width: 1200px) {
                    display: none;
                }

                >img {
                    object-fit: cover;
                }
            }
        }
    }

    .block2 {
        .title {
            margin-bottom: 30px;
            margin-top: 0;

            @media screen and (max-width: 768px) {
                margin-bottom: 28px;
            }
        }

        .carousel-item {
            justify-content: flex-start;
            white-space: normal;
        }

        .carousel-wrap__arrow {
            background-color: rgba(225, 225, 225, 0.8);
        }
    }

    .block3 {
        .title {
            margin-bottom: 0;

            @media screen and (max-width: 768px) {
                margin-top: 44px;
                margin-bottom: 0;
            }
        }
    }

    .block4 {
        margin-bottom: 80px;

        .title {
            margin-top: 120px;
            margin-bottom: 100px;

            @media screen and (max-width: 768px) {
                margin-top: 80px;
                margin-bottom: 92px;
            }
        }
    }

    .block5 {
        .title {
            margin-top: 140px;
            padding-bottom: 10px;

            @media screen and (max-width: 768px) {
                margin-top: 90px;
            }
        }

        padding-bottom: 140px;

        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
        }
    }

    .block6 {
        .title {
            margin-bottom: 30px;
        }

        .description {
            margin-bottom: 60px;
            font-size: 20px;
            font-weight: 500;
        }

        img {
            object-fit: contain;
        }
    }

    .block7 {
        .title {
            margin-top: 140px;
            margin-bottom: 30px;

            @media screen and (max-width: 768px) {
                margin-top: 80px;
            }
        }

        margin-bottom: 200px;

        @media screen and (max-width:1200px) {
            margin-bottom: 50px;
        }

        @media screen and (max-width:768px) {
            margin-bottom: 70px;
        }
    }

    .block8 {
        margin-bottom: 160px;

        @media screen and (max-width: 768px) {
            margin-bottom: 80px;
        }

        .title {
            margin-bottom: 30px;

            @media screen and (max-width: 768px) {
                margin-bottom: 0;
            }
        }
    }

    .block9 {
        .content {
            padding-bottom: 90px;

            p {
                font-size: 16px;
            }
        }

        .buttons {
            .col6 {
                padding: 0;
            }

            .col6:last-child {
                @media screen and (max-width: 768px) {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}

.spec-content {
    background: linear-gradient(0, rgba(0, 0, 0, 0.00) 20%, #000 100%);
    width: auto;
    position: absolute;
    top: 0;
    right: 20px;
    left: 0;
    bottom: 0;
    height: 170px;
    z-index: 1;
    border-radius: 5px;
    padding: 20px;

    @media screen and (max-width: 820px) {
        padding: 10px;
    }

    >p:last-child {
        font-size: 24px;
        font-weight: 500;

        @media screen and (max-width: 820px) {
            font-size: 20px;
        }
    }
}

.spec-item {
    height: 340px;
    width: 100%;

    @media screen and (max-width: 940px) {
        height: 240px;
    }

    p {
        width: 100%;
        color: white;

        &:first-of-type {
            font-weight: 500;
            font-size: 16px;
        }

        &:last-child {
            font-weight: 600;
            font-size: 24px;
        }
    }
}

.instructions {
    //$gap: 20px;
    display: flex;
    //gap: $gap;
    //flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    @media screen and (max-width: 940px) {
        flex-wrap: wrap;
        justify-content: center;
        gap: 50px;
    }

    &__item {
        width: 387px;
        //height: 340px;
        border-radius: 10px;
        padding: 0 0 20px 20px;
        border: 1px solid rgba(0, 0, 0, 0.30);
        height: 350px;
        background: radial-gradient(47.21% 31.14% at 50.13% 53.97%, #BDCAFD 0%, rgba(235, 239, 255, 0.00) 100%);

        @media screen and (max-width: 768px) {
            height: 300px;
        }

        @media screen and (max-width: 525px) {
            width: 342px;
        }

        @media screen and (max-width: 370px) {
            height: 350px;
        }
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
        margin-top: -28px;

        @media screen and (max-width: 768px) {
            font-size: 20px;
        }
    }

    &__description {
        font-size: 16px;
        font-weight: 500;
    }

    &__image {
        position: relative;
        //height: 200px;
        //width: 200px;
        top: -28px;
        height: 222px;
        left: 55px;
    }
}

.review {
    white-space: normal;
    border-radius: 5px;
    border: 1px solid #E4E5E7;
    background: #FFF;
    padding: 20px;
    width: 33%;

    @media screen and (max-width: 768px) {
        width: 90%;
    }

    &__name,
    &__type {
        font-weight: 600;
        font-size: 16px;
    }

    &__content {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        word-break: break-word;

        span {
            color: #42C379;
        }
    }

    img {
        width: 68px;
        height: 68px;
    }
}

.search-container {
    >div {
        //box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }

    .search-options {
        //background: transparent !important;
        box-shadow: none !important;
        width: 100%;
    }

    .search_field {
        border-radius: 5px;
    }

    .search-container-input {
        width: 100%;

        .search-options li a {
            padding-left: 0;
        }

        .search_field>* {
            height: 45px;
        }

        .search-button-container {
            width: 60px;
        }
    }
}

.search-container-input {
    position: relative;

    .search-options {
        overflow: auto;
        max-height: 80vh;
        position: absolute;
        background: white;
        z-index: 10;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

        li a {
            display: block;
            padding: 10px 20px;
            color: black;

            &:hover {
                background: #d0cece;
            }

            span {
                font-weight: 700;
            }
        }
    }
}

.tariff-page-customer,
.tariff-page-freelancer {

    .PFT,
    .PCT,
    .PFFT,
    .SFT,
    .SCT {
        @media screen and (min-width: 768px) {
            height: 55px !important;
            font-size: 32px !important;
        }
    }

    .block-tariff {
        margin-bottom: 140px;

        @media screen and (max-width: 768px) {
            margin-bottom: 80px;
        }
    }

    .title {
        color: #000;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 30px;
    }

    .block1 {
        margin-top: 60px;

        &__content {
            border-radius: 10px;
            background: #F1F4FF;
            padding: 50px 30px 50px 48px;

            @media screen and (max-width: 768px) {
                padding: 16px;
            }

            .col6 {
                padding: 0 15px 0 0;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding: 0;
                }
            }

            p:nth-child(2n) {
                font-size: 16px;
                font-weight: 500;

                @media screen and (max-width: 525px) {}
            }

            p:nth-child(2n+1) {
                display: flex;
                align-items: center;
                color: #000;
                font-size: 16px;
                font-weight: 700;

                &:before {
                    content: "";
                    display: inline-block;
                    background: url("../../../public/img/li-marker.png") no-repeat;
                    background-size: 30px 30px;
                    width: 38px;
                    height: 30px;

                    @media screen and (max-width: 425px) {
                        width: 45px;
                        min-width: 45px;
                    }
                }
            }

            p:first-of-type {
                margin-top: 0;
            }
        }
    }

    .block2,
    .block3 {
        .col6 {
            width: calc(50% - 10px);
            padding: 30px 0 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                width: 100%;
                padding: 10px 0 0;
            }
        }

        &__footer {
            padding: 0 37px 40px;
            min-height: 250px;
            max-height: 250px;
            margin-top: 60px;
            border-top: 1px solid rgba(180, 180, 180, 0.4);
            padding-top: 10px;

            @media screen and (max-width: 768px) {
                margin-top: 40px;
                padding: 20px 10px;
                min-height: auto;
                max-height: auto;
            }

            >div:first-of-type {
                margin-bottom: 90px;

                @media screen and (max-width: 768px) {
                    margin-bottom: 40px;
                }
            }

            .time-tariff {
                @include date;
            }

            .price {
                color: #010101;
                font-size: 32px;
                font-weight: 600;

                @media screen and (max-width: 768px) {
                    font-size: 24px;
                }
            }

            a {
                width: 300px;
                margin: 0 auto;
                display: block;

                @media screen and (max-width: 425px) {
                    width: 100%;
                }
            }

            button {
                font-size: 16px;
                font-weight: 700;
                width: 100%;
                height: 58px;
            }
        }

        &__content {
            gap: 20px;

            .info {
                //min-height: 540px;
                height: 100%;
                padding: 0 37px 0;

                @media screen and (max-width: 768px) {
                    padding: 10px;
                }
            }

            >div {
                box-shadow: 4px 5px 30px 0 rgba(0, 0, 0, 0.10);

                >p {
                    margin-bottom: 60px;
                    text-align: center;

                    @media screen and (max-width: 768px) {
                        margin-bottom: 0;
                    }
                }

                >div {}

                .info>p:nth-child(2n+1) {
                    display: flex;
                    align-items: center;
                    color: #000;
                    font-size: 16px;
                    font-weight: 600;

                    &:before {
                        content: "";
                        display: inline-block;
                        min-height: 30px;
                        min-width: 36px;
                        margin-right: 6px;
                    }
                }

                .info>p:nth-child(2n) {
                    display: flex;

                    &:before {
                        content: "";
                        display: block;
                        min-height: 30px;
                        min-width: 36px;
                        margin-right: 6px;
                    }
                }
            }

            .col6:first-of-type {
                .info>p:nth-child(2n+1) {
                    &:before {
                        background: url("../../../public/img/star1.png") no-repeat center center / contain;
                    }
                }
            }

            .col6:last-child {
                .info>p:nth-child(2n+1) {
                    &:before {
                        background: url("../../../public/img/star2.png") no-repeat center center / contain;
                    }
                }
            }
        }
    }

    .block3 {
        .table {
            .grid-container {
                background: #FFF;
                box-shadow: 4px 5px 30px 0 rgba(0, 0, 0, 0.10);
                display: grid;
                grid-template-columns: 2fr 2fr 1fr 1fr;
                grid-template-rows: 120px repeat(7, minmax(100px, auto));
            }

            .grid-item:nth-child(4n+3) {
                background: #F2F2F2;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
            }

            .grid-item>span {
                font-size: 16px;
                font-style: italic;
                font-weight: 500;
            }

            .grid-item:nth-child(4n+7),
            .grid-item:nth-child(4n+8),
            .grid-item3,
            .grid-item1,
            .grid-item2,
            .grid-item3 {
                color: #000;
                font-size: 20px;
                font-weight: 600;
            }

            .grid-item4 {
                color: #404883;
                font-size: 24px;
                font-weight: 600;
            }

            .grid-item4,
            .grid-item3 {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .grid-item:nth-child(4n+4) {
                background: rgba(241, 244, 255, 1);
                font-size: 18px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .grid-item {
                padding: 30px;
                border: 1px solid #D4D7F0;

                @media screen and (max-width: 860px) {
                    padding: 5px
                }
            }
        }

        .table-mb {
            .grid-container {
                background: #FFF;
                box-shadow: 4px 5px 30px 0 rgba(0, 0, 0, 0.10);
                display: grid;
                grid-template-columns: 2fr 1fr 1fr;
                grid-template-rows: repeat(15, minmax(100px, auto));
            }

            .grid-item:nth-child(4n+5),
            .grid-item2 {
                background: #F2F2F2;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
            }

            .grid-item1 {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .grid-item:nth-child(4n+4) {
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .grid-item2 {
                color: #606060;
                font-size: 16px;
                font-weight: 600;
                word-break: break-all;
            }

            .grid-item3 {
                color: #404883;
                font-size: 16px;
                word-break: break-all;
                font-weight: 600;
            }

            .grid-item:nth-child(4n+7) {
                font-size: 16px;
                font-weight: 400;
                grid-column-start: 1;
                grid-column-end: 4;
            }

            .grid-item>span {
                font-size: 16px;
                font-style: italic;
                font-weight: 500;
            }

            .grid-item:nth-child(4n+6),
            .grid-item:nth-child(4n+5),
            .grid-item1 {
                color: #000;
                font-size: 20px;
                font-weight: 600;
            }

            .grid-item:nth-child(4n+6),
            .grid-item3 {
                background: rgba(241, 244, 255, 1);
                font-size: 18px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .grid-item {
                padding: 30px;
                border: 1px solid #D4D7F0;

                @media screen and (max-width: 860px) {
                    padding: 5px
                }
            }
        }
    }
}

.main-page-student {
    .instructions__item>p {
        text-align: center;
    }
}

.tariff-page-freelancer,
.main-page-student {
    .instructions {
        justify-content: space-around;
        gap: 65px;

        @media screen and (max-width: 960px) {
            gap: 30px;
        }

        @media screen and (max-width: 820px) {
            gap: 20px;
        }

        @media screen and (max-width: 525px) {
            gap: 30px;
        }

        &__image-wrap {
            height: 153px;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.30);
            background: radial-gradient(47.21% 31.14% at 50.13% 53.97%, #BDCAFD 0%, rgba(235, 239, 255, 0.00) 100%);
        }

        &__item {
            max-width: 251px;
            width: calc(25% - 65px);
            padding: 0;
            border: none;
            background: none;
            height: auto;

            >p {
                font-weight: 500;
                font-size: 16px;
                text-align: center;
            }

            @media screen and (max-width: 960px) {
                width: calc(25% - 30px);
            }

            @media screen and (max-width: 820px) {
                width: calc(50% - 20px);
            }

            @media screen and (max-width: 525px) {
                width: 251px;
            }
        }

        &__image {
            width: 147px;
            left: 30px;
            height: 198px;

            @media screen and (max-width: 968px) {
                //left: 30px;
            }

            @media screen and (max-width: 820px) {
                left: 30%
            }

            @media screen and (max-width: 525px) {
                left: 21%
            }
        }
    }
    .block1 {
        .st-img-woman {
            position: relative;
            &::before {
                content: "";
                background: url('../../../public/img/main-page-student-woman-bc.png') no-repeat;
                width: 464px;
                height: 608px;
                z-index: 0;
                position: absolute;
                bottom: -17px;
            }
            >img {
                z-index: 1;
            
            }
        }
    }
    .block3 {
        margin-bottom: 80px;

        &__content {
            >div:first-of-type {
                .info>p:nth-child(2n+1) {
                    &:before {
                        background: url("../../../public/img/star2.png") no-repeat center center / contain;
                    }
                }
            }

            >div:last-child {
                .info>p:nth-child(2n+1) {
                    &:before {
                        background: url("../../../public/img/star3.png") no-repeat center center / contain;
                    }
                }
            }
        }
    }
}

.paginator {
    display: flex;
    align-items: center;

    //justify-content: center;
    &__list {
        display: flex;
        align-items: center;
        margin-left: -30px;

        @media screen and (max-width: 525px) {
            margin-left: -10px;
        }
    }

    &__item {
        margin-left: 30px;

        @media screen and (max-width: 525px) {
            margin-left: 10px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a,
        span {
            color: black;
            font-size: 20px;
            font-weight: 500;

            @media screen and (max-width: 525px) {
                font-size: 16px;
            }

            &:hover {
                color: #91DCB1;
            }
        }
    }

    &__arrow {
        width: 20px;
        height: 20px;
        background-color: black;
        -webkit-mask: url('../../../public/img/arrow-paginator.svg') no-repeat center;
        mask: url('../../../public/img/arrow-paginator.svg') no-repeat center;

        &:hover {
            background-color: #91DCB1;
            cursor: pointer;
        }
    }

    &__arrow-right {
        margin-left: 13px;

        @media screen and (max-width: 525px) {
            margin-left: 7px;
        }
    }

    &__item-active {
        &:first-child {
            margin-left: 30px !important;
        }

        margin-left: 17px;

        @media screen and (max-width: 525px) {
            margin-left: 10px;

            &:first-child {
                margin-left: 7px !important;
            }
        }

        a,
        span {
            &:hover {
                color: white;
            }
        }

        span {
            border-radius: 54px;
            background: #91DCB1;
            color: white;
            width: 34px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__item-active+&__item {
        margin-left: 17px;

        @media screen and (max-width: 525px) {
            margin-left: 10px;
        }
    }

    &__prev {
        margin-left: 13px;

        @media screen and (max-width: 525px) {
            margin-left: 7px;
        }
    }

    &__arrow-right {
        transform: rotateY(180deg);
    }
}

.proposal_modal_content {
    width: 410px;

    @media screen and (max-width: 768px) {
        width: 300px;
    }

    @media screen and (max-width: 350px) {
        width: 270px;
    }

    >.info-bold {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;

        &:first-of-type {
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-top: 0;
        }
    }

    >button {
        width: 305px;
        margin: 50px auto 0;
        display: block;
    }

    &__title {
        color: #153F41;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 1px;
    }

    &>p {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1px;
    }
}

.spec-link {
    font-weight: 500;
    font-size: 16px;
    display: block;
    color: black;
}

.spec-link:hover {
    color: blue;
}

.proposal_package_form {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 10px;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    &>span {
        display: flex;
        align-items: center;
    }
}

.proposal_package {
    border-radius: 5px;
    border: 1px solid #2D6163;
    display: flex;
    width: 100%;
    height: 49px;
    padding: 25px 40px 25px 45px;
    justify-content: center;
    align-items: center;
    color: #2D6163;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;

    &:hover {
        border: 1px solid #2D6163;
        background: rgba(45, 97, 99, 0.50);
        color: white;
        cursor: pointer;
    }
}

.proposal_package_form input[type=radio] {
    display: none;
}

input[type=radio]:checked+.proposal_package {
    border: 1px solid #2D6163;
    background: rgba(45, 97, 99, 0.50);
    color: white;
}

.dropdown-head:before {
    background: url("../../../public/img/dropdown-head.svg");
    content: "";
    display: inline-block;
    background-size: 21px 13px;
    margin-right: 13px;
    width: 21px;
    height: 13px;
    transition: all .3s;
}

.dropdown-head.active {
    transform: rotateX(180deg);
}

.select_role_radio {
    display: none;
}

.select_role_radio+label {
    display: flex;
    width: 241px;
    height: 58px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

#ROLE_FREELANCER.select_role_radio+label {
    border: 2px solid #2D6163;
    color: #2D6163;
}

#ROLE_CUSTOMER.select_role_radio+label {
    border: 2px solid #18236B;
    color: #18236B;
}

#ROLE_FREELANCER.select_role_radio:checked+label {
    background-color: #2D6163;
    color: white;
}

#ROLE_CUSTOMER.select_role_radio:checked+label {
    background-color: #18236b;
    color: white;
}

.auto-sub-title {
    width: 213px;
    color: #153f41;
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 425px) {
        font-size: 16px;
    }
}

.reservation-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.reservation-grid>p:nth-child(n+1) {
    font-size: 16px;
    font-weight: 500;
    text-align: start;
}

.reservation-grid>p:nth-child(2n+2) {
    justify-self: end;
    font-weight: 600;
    font-size: 16px;
}

.reservation-grid>p:nth-child(4) {
    align-self: end;
    font-size: 24px;
}

.accept-grid {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.accept-grid>div:first-child {
    margin-right: 50px;
}

.finish_info,
.budget_info {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #E6E6E6;
}

.budget_info {
    background: #F5FAFB;
}

.disciplines-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;

    li {
        height: 110px;
        margin-left: 10px;
        margin-top: 50px;
        flex: 1 1 200px;

        p {
            text-align: center;
        }

        img {
            display: block;
            width: 60px;
            border-bottom: 1px solid black;
            padding-bottom: 10px;
            margin: 0 auto 0;
        }
    }
}

.main-page-student {
    .faq__cont {
        max-width: none;
        padding: 0;
    }

    .block9 {

        * {
            color: #444;
            ;
        }

        p {
            font-size: 16px;
        }

    }

    .block5 {
        .container {}

        @media screen and (max-width: 1001px) {
            background: url('../../../public/img/block5_3.png'), url('../../../public/img/block5_2.png'), url('../../../public/img/block5_1.png');
            background-position: 80% 80%, 70% top, 20% bottom;
            background-repeat: no-repeat, no-repeat no-repeat;
        }

        .flex1 {
            flex-wrap: wrap;

            >div:first-child {
                .title {
                    margin: 0;
                }

                display: flex;
                padding-right: 20px;
                justify-content: center;
                align-items: center;
                background: url('../../../public/img/block5_3.png'),
                url('../../../public/img/block5_2.png'),
                url('../../../public/img/block5_1.png');
                background-position: 80% 80%,
                80% top,
                20% bottom;
                background-repeat: no-repeat,
                no-repeat no-repeat;
                width: 40%;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                    background: none;
                }
            }

            >div:last-child {
                width: 60%;

                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
        }

        .grid-help-opportunity {
            display: grid;
            gap: 10px;
            grid-template-areas: "A A A A   A A B B   B B B B" "C C C C   C C C C   D D D D" "E E E E   E E E E   E E E E";

            .grid-item-op-1 {
                grid-area: A;
            }

            .grid-item-op-2 {
                grid-area: B;
            }

            .grid-item-op-3 {
                grid-area: C;
            }

            .grid-item-op-4 {
                grid-area: D;
            }

            .grid-item-op-5 {
                grid-area: E;
            }

            p {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                background: #FFF6DE;
                padding: 20px 45px;
                color: #000;
                font-size: 20px;
                font-weight: 500;
                line-height: 35px;
                /* 175% */
                letter-spacing: 0.48px;

                @media screen and (max-width: 425px) {
                    padding: 10px;
                    line-height: 25px;
                    /* 175% */
                    letter-spacing: 0.48px;
                }
            }
        }
    }

    .block6 {
        .how-to-work {
            ol {
                display: flex;
                flex-wrap: wrap;

                li {
                    width: 50%;

                    @media screen and (max-width: 840px) {
                        width: 100%;
                    }

                    >p {
                        color: #000;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.368px;
                        margin-left: 40px;
                    }

                    div {
                        display: flex;
                        align-items: center;

                        >p {
                            color: #000;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            letter-spacing: 0.368px;
                            position: relative;
                            left: -10px;

                            @media screen and (max-width: 525px) {
                                font-size: 20px;
                                font-weight: 600;
                                letter-spacing: 0.368px;
                            }

                            @media screen and (max-width: 425px) {
                                max-width: 50%;
                            }
                        }

                        span {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            color: #2D6163;
                            border-radius: 50%;
                            font-size: 110px;
                            font-weight: 600;
                            line-height: 135px;
                            letter-spacing: 1px;
                            text-align: center;
                            background: linear-gradient(147.44deg, #C4D0FD 15%, rgba(197, 208, 254, 0) 76.74%);
                            width: 186px;
                            height: 160px;
                            -webkit-text-fill-color: transparent;
                            -webkit-text-stroke: 2px #2D6163;

                            @media screen and (max-width: 1000px) {
                                font-size: 80px;
                            }

                            @media screen and (max-width: 520px) {
                                width: 154px;
                                height: 119px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.filters {
    position: fixed;
    min-height: 100vh;
    width: 100vw;
    top: 0;left: 0;right: 0;bottom: 0;
    z-index: 100;
    background: white;
    overflow-y: auto;
    transform: translateX(-100vw);
    transition: all .3s;
    &__title {
        color: #000;
        font-size: 20px;
        font-weight: 600;
    }
    &__button {
        font-weight: 500;
        color: black;
        font-size: 16px;
    }
    &__opened {
        transform: translateX(0);
    }
    &__counter {
        border-radius: 50%;
        color: white;
        background: #F54552;
        display: flex;
        height: 26px;
        padding: 10px;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 3px;
    }
}

.freelancer-list-item__carousel {
    letter-spacing: 0;
}