//стили, используемые на формах
.rouble-sign {
  background: url("../../../public/img/np_ico1.png") no-repeat right 15px top 16px, #fff!important;
  background-position-y: center !important;
  @media screen and (max-width: 768px ) and (min-width: 425px){
    background-size: 18px 18px !important;
  }
}

.field-file {
  display: flex;
  align-items: center;

  input {
    width: 1px;
    opacity: 0;
    height: 1px;
    position: absolute;
    overflow: hidden;
  }

  label {
    padding: 10px 15px;
    white-space: nowrap;
  }
}

.field-row {
  margin-bottom: 1rem;
}

.field-label {
  display: inline-block;
}
input.field-control {
  background: #fff;
}
input.flatpickr {
  background:  url("../../../public/img/calendar-date.svg") no-repeat right #fff;
  background-position-x: 94%;
  background-size: 12px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.field-control {
  width: 100%;
  border-radius: 3px;
  position: relative;
  /*z-index:2;*/
  font-size: 14px;
  border: 1px solid #9fa3c1;
  color: #000;
  display: block;
  overflow: hidden;
  text-align: left;
  text-overflow: clip;
  outline: 0;
  letter-spacing: 1px;
  padding: 0 20px 0 20px;
  min-height: 4em;
  max-width: 100% !important;
  &:focus {
    border-color: #404883;
    box-shadow: 0 0 0 1px #404883 inset
  }
  @media screen and (max-width:425px)
  {
    padding: 0 10px 0 10px;
  }
  //@media screen and (max-width: 768px) {
  //  font-size: 1rem;
  //}

  @media screen and (max-width: 320px) {
    font-size: 0.9rem;
  }
}
textarea {
  padding-top: 15px !important;
}
.input_code {
  margin-left: 110px;
  width: 60%;
}
@media screen and (max-width:585px)
{
  .input_code {
    margin-left: 0;
    width: 100%;
  }
}
.field-error {
  color: #d60000;
  font-size: 12px;
  background: url("../../../public/img/sz_img2.png") no-repeat left center;
  padding: 4px 0 4px 30px;
  line-height: 16px;

  @media screen and (max-width: 768px) {
    font-size: 3vw;
    line-height: 3.5vw;
  }
}

#project_modal, #project_modal2 {
  max-width: 90vw;
  width: 450px;
  height: max-content;
  max-height: 80vh;
  overflow: auto;
  min-width: 300px;
  padding: 18px 9px;
  border-radius: 4px;
  background: #fafafa;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: none;
  opacity: 0;
  z-index: 101;
  text-align: center;
  border: 2px solid #00af49;
}

#project_modal #project_modal__close,#project_modal2 #project_modal__close2{
  width: 21px; height: 21px;
  position: absolute;
  font-size: 29px;
  top: 1px; right: 11px;
  cursor: pointer;
  display: block;
}
#project_modal2 #project_modal__close2{
  width: 21px; height: 21px;
  position: absolute;
  font-size: 29px;
  top: 1px; right: 11px;
  cursor: pointer;
  display: block;
}
#project_modal_overlay, #project_modal_overlay2{
  z-index: 100;
  position: fixed;
  background: rgba(0,0,0,.7);
  width: 100%; height: 100%;
  top: 0; left: 0;
  cursor: pointer;
  display: none;
}
#modal_message #modal_body{
  font-weight: 700;
  margin: 15px;
  text-align: justify;
}

.select_interval{
  width: 250px;
  text-align: start;
  margin-top: 20px;
  margin-left: 32%;
}

.fl_checkbox {

}