//общие стили, часто используемые на многих страницах
.attachments-list {
    .item {
      display: flex;
      align-items: center;
      padding-bottom: 10px;

      .index {
        min-width: 20px
      }

      .file-name {
        overflow-wrap: anywhere;
        padding-left: 8px;
        line-height: 1.4rem;

        span {
            font-size: 13px;
        }
      }

      .icon {
        width: 25px;
      }
    }
  }


.btn6 {
  display: inline-block;
  padding: 15px 35px;
  background: #d9dae6;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 100px;
  transition: .1s;
  color: #434343;
  cursor: pointer;
  box-shadow: 0 3px 6px 2px #303030;
  &:hover {
  }
  &:focus {
    box-shadow: 0 3px 6px 0 #303030;
  }
}

.shadow-box {
  background: #ffffff;
  box-shadow: 0 0 6.44px 0.56px rgba(28, 65, 71, 0.09);
  padding: 2rem!important;
}

.btn2, .btn3, .btn7, .btn6, .btn8, .btn9, .btn11 {
  font-weight: 500;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 1em 2em;
  }

  @media screen and (max-width: 320px) {
    font-size: 0.9rem;
  }
}

.nowrap {
 white-space: nowrap;
}
