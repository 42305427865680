@mixin date {
  border-radius: 5px;
  background: rgba(0, 175, 74, 0.43);
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  &:before {
    content: "";
    display: inline-block;
    background: url("../../../public/img/calendar.png") no-repeat center center / contain;
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
}