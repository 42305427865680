.advantages {
  margin-bottom: 68px;
  @include media(992) {
    position: relative;
    padding-bottom: 350px; } }

.advantages_1 {
  position: relative;
  .advantages__picture {
    position: absolute;
    bottom: -106px;
    @include media(992) {
      bottom: -8px;
      z-index: -1; } }
  @include media(992) {
    margin-bottom: 0; } }

.advantages_2 {
  @include media(992) {
    margin-bottom: -60px; } }

.advantages_3 {
  @include media(992) {
    margin-bottom: -38px;
    padding-bottom: 365px; }
  .advantages__picture {
    margin-top: 22px; } }

.advantages__cont {
  @include cont;
  display: flex;
  justify-content: space-between;
  @include media(1920) {
    margin-top: -45px; }
  @include media(1200) {
    margin-top: 0; }
  @include media(992) {
    display: block; } }

.advantages__box {
  &:first-of-type {
    margin-right: 85px;
    flex-shrink: 0;
    max-width: 690px;
    @include media(1500) {
      max-width: 577px; }
    @include media(1200) {
      max-width: 400px; }
    @include media(992) {
      max-width: 100%;
      margin-right: 0; } }
  &:last-of-type {
    margin-top: 4px; } }

.advantages__title {
  @include title;
  margin-bottom: 34px;
  @include media(1200) {
    margin-bottom: 13px; } }

.advantages__note {
  display: block;
  letter-spacing: 0.02em;
  font-size: 20px;
  line-height: 29px;
  font-weight: 500;
  @include media(1200) {
    font-size: 15px;
    line-height: 22px; }
  @include media(992) {
    margin-bottom: 17px; } }

.advantages__picture {
  display: block;
  @include media(992) {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    left: 0;
    height: 350px;
    text-align: center; } }

.advantages__image {
  width: 650px;
  height: 645px;
  @include media(1500) {
    width: 577px;
    height: 573px; }
  @include media(1200) {
    width: 400px;
    height: 397px; }
  @include media(992) {
    width: 353px;
    height: 350px; } }

.advantages__list {
  margin-bottom: 56px;
  @include media(1500) {
    margin-bottom: 33px; } }

.advantages__item {
  display: flex;
  margin-bottom: 28px;
  @include media(992) {
    margin-bottom: 22px; } }

.advantages__item-picture {
  flex-shrink: 0;
  margin-right: 32px;
  margin-top: 11px;
  @include media(992) {
    margin-right: 13px;
    margin-top: 8px; } }

.advantages__item-image {
  width: 76px;
  height: 76px;
  @include media(992) {
    width: 55px;
    height: 55px; } }

.advantages__item-info {}

.advantages__headline {
  margin-bottom: 11px;
  letter-spacing: 0.02em;
  font-size: 24px;
  line-height: 35px;
  @include media(1500) {
    font-size: 18px;
    line-height: 26px; }
  @include media(992) {
    margin-bottom: 3px; } }

.advantages__text {
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  @include media(1500) {
    font-size: 13px;
    line-height: 19px; } }

.advantages__btn-box {
  @include media(992) {
    text-align: center; } }

.advantages__button {
  padding: 15px 35px;
  font-size: 17px;
  line-height: 25px;
  @include media(992) {
    padding: 15px 26px;
    font-size: 15px;
    line-height: 22px; } }

.advantages__button_yellow {
  @include button_yellow; }
