$gap: 20px;

.PFT, .PFFT, .PCT, .SCT, .SFT {
  display: inline-block;
  padding: 6px 15px 10px;
  border-radius: 39px;
  font-weight: 600;
  color: #090909;
  font-size: 20px;

  @media screen and (min-width: 768px){
    font-size: 34px;
  }
}
.PFT, .PCT {
  background: rgba(63, 72, 131, 0.08);
  border: 3px solid #3F4883;
  color: #404883;
}
.PFFT {
  background: rgba(0, 175, 73, 0.08);
  border: 3px solid #00AF49;
  color: #00AF49;
}
.SFT, .SCT {
  background: rgba(122, 122, 122, 0.08);
  border: 3px solid #737373;
  color: #606060;
}

.buy-tariff-wrap {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (min-width: 768px){
      margin-bottom: 25px;
    }

  }
  &__info {
    margin-bottom: 40px;
    > h2 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
      @media screen and (min-width: 768px){
        font-size: 30px;
      }
    }
    >ul li {
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 1px;

      font-size: 12px;
      @media screen and (min-width: 768px){
        font-size: 16px;
        margin-bottom: 30px;
      }
      &:before {
        content: "✅";
        margin-right: 10px;
      }
    }
  }
  &__list {
    margin-bottom: 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $gap;
    >div {
      width: 100%;
      @media screen and (min-width: 450px){
        width: calc(50% - #{$gap});
      }
    }
  }
  &__form {
    margin-bottom: 25px;
    >button {
      margin: 0 auto;
      font-weight: 600;
      font-size: 16px;
      height: 60px;
      line-height: 20px;
      width: 100%;
      @media screen and (min-width: 768px){
        width: 340px;
      }
    }
  }
  &__footer {
    >p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1px;
    }
  }
}

.select-tariff-condition {
  display: none;
}
.select-tariff-condition:checked + .tariff-radio {
  background: #ECF6F7;
}
.tariff-radio {
  display: block;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: -2px 7px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 20px;
  transition: .2s;
  height: 100%;
  &:hover {
    background: #ECF6F7;
  }

  &__interval {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: rgba(0, 175, 74, 0.43);
    border-radius: 5px;
    margin-bottom: 25px;
    width: fit-content;
    @media screen and (max-width: 768px){
      font-size: 12px;
    }
    &:before {
      content: url("../../../public/img/interval.svg");
      margin-right: 11px;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__real-price {
    font-weight: 600;
    line-height: 19px;
    color: #878787;
    text-decoration: line-through;
    text-decoration-thickness: 2px;
    text-align: right;
    font-size: 12px;
    @media screen and (min-width: 768px){
      font-size: 16px;
    }
  }
  &__discount-price {
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 1px;
    color: #000000;
    @media screen and (min-width: 768px){
      font-size: 32px;
    }
  }
}