.need-urgently {
  margin-bottom: 65px;
  background-color: rgba(#00AF49, .15);
  @include media(1200) {
    margin-bottom: 25px; } }

.need-urgently__cont {
  @include cont;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 48px;
  @include media(1200) {
    display: block;
    text-align: center;
    padding-bottom: 33px; } }

.need-urgently__info {
  position: relative;
  display: flex;
  flex-direction: column;
  @include media(1200) {
    margin-bottom: 24px;
    display: block; } }

.need-urgently_img {
  @include media(1200) {
    width: 150px; } }
.need-urgently__title {
  @include title;
  margin-bottom: 11px;
  letter-spacing: 0.02em;
  @include media(1200) {
    margin-bottom: 15px;
    margin-left: 80px;
    margin-right: 71px; }
  @include media(576) {
    margin: 0 -6px 15px; } }

.need-urgently__text {
  letter-spacing: 0.02em;
  font-size: 25px;
  font-weight: 500;
  line-height: 37px;
  @include media(1200) {
    font-size: 15px;
    line-height: 22px; } }

.need-urgently__btn-box {
  flex-shrink: 0;
  margin-left: 46px;
  padding-top: 18px;
  @include media(1200) {
    margin: 0;
    padding: 0; } }

.need-urgently__button {
  padding: 18px 35px 16px;
  @include button_yellow;
  font-size: 18px;
  line-height: 26px;
  @include media(1200) {
    min-width: 255px;
    padding: 16px 12px 14px;
    font-size: 16px;
    line-height: 24px; } }
