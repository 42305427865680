.faq {
  margin-bottom: 10px; }

.faq__cont {
  @include cont; }

.faq__title {
  @include title;
  margin-bottom: 27px;
  padding-top: 15px;
  @include media(1200) {
    margin-bottom: 9px;
    margin-top: 30px; } }

.faq__note {
  margin-bottom: 63px;
  display: block;
  text-align: center;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  @include media(1200) {
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 22px; } }

.faq__area {
  display: flex;
  margin: 0 -15px 15px;
  @include media(1200) {
    margin: 27px 0 24px;
    display: block; } }

.faq__column {
  margin: 0 15px;
  width: 100%;
  @include media(1200) {
    margin: 0; } }

.faq__box {
  margin-bottom: 19px;
  background: #F5FBFB;
  border-radius: 15px;
  @include media(1200) {
    margin-bottom: 9px; } }

.faq__head {
  position: relative;
  padding: 5px 8px 5px 30px;
  min-height: 75px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  @include media(1200) {
    padding: 10px 8px 10px 21px; }
  &::before, &::after {
    content: '';
    position: absolute;
    border-radius: 2px;
    background-color: #2D6163; }
  &::before {
    right: 38px;
    height: 32px;
    width: 3px;
    margin: auto 0;
    @include media(1200) {
      right: 21px;
      width: 2px;
      height: 22px; } }
  &::after {
    margin: auto 0;
    right: 23px;
    width: 32px;
    height: 3px;
    @include media(1200) {
      right: 11px;
      width: 22px;
      height: 2px; } } }

.faq__head_active {
  &::before {
    transform: rotateZ(45deg); }
  &::after {
    transform: rotateZ(45deg); }
  + .faq__body {
    display: block; } }

.faq__headline {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.4px;
  width: 80%;
  @include media(1200) {
    font-size: 17px;
    line-height: 22px; } }

.faq__body {
  display: none;
  margin-top: -8px;
  padding: 0 8px 20px 30px;
  @include media(1200) {
    margin-top: -4px;
    padding: 0 8px 20px 21px; } }

.faq__text {
  letter-spacing: 0.02em;
  font-size: 15px;
  line-height: 26px;
  @include media(1200) {
    font-size: 14px;
    line-height: 21px; } }

.faq__btn-box {
  text-align: center; }

.faq__button {
  padding: 15px 35px;
  letter-spacing: 0.02em;
  font-size: 17px;
  line-height: 25px;
  @include media(1200) {
    padding: 14px 26px;
    font-size: 15px;
    line-height: 22px; } }
