@media screen and (max-width:1600px)
{
    .ar_page
    {
        background:url(../../../public/img/ar_bg1.png) no-repeat 130% 160px, #fafdfd;
    }
    .kf_b07_wrap
    {
        padding:0 50px;
    }
    .kf_b07 .owl-carousel .owl-nav .owl-next
    {
        right:-30px;
    }
    .kf_b07 .owl-carousel .owl-nav .owl-prev
    {
        left:-30px;
    }
}
@media screen and (max-width: 375px) {
    ul.flex-tree label.node, ul.flex-tree li label, ul.flex-tree li label, ul.flex-tree li span, ul.flex-tree li span label {
        font-size: 12px;
    }
}
@media screen and (min-width: 470px) {
    .fr-count {
        display: flex;
        align-items: center;
    }
}
@media screen and (max-width:1920px)
{
    .vi_b05_b3 {
        margin-bottom: 15px;
    }
    .gi_page .gz_b01 {
        margin-bottom: 90px;
        margin-top: 150px;
    }
}
@media screen and (max-width:1500px)
{
    .gi_page .gz_b01
    {
        background:url("../../../public/img/gi_bg1.png") no-repeat right top / 65%;
    }
}
@media screen and (max-width:1400px)
{
    .ar_page
    {
        background:url(../../../public/img/ar_bg1.png) no-repeat 140% 160px, #fafdfd;
    }
    .gz_b01
    {
        background:url(../../../public/img/gz_bg1_mob.png) no-repeat right 160px;
    }
    .gi_page .gz_b01
    {
        background:url(../../../public/img/gi_bg1_mob.png) no-repeat right 160px;
    }
    .prp_b04 .col4
    {
        width:50%;
    }
}
@media screen and (max-width:1230px)
{
    .auth-buttons {
        margin-right: 15px;
    }
    .ddm_main
    {
        min-width:660px;
    }
    .top_mn_ispoln li
    {
        width:50%;
    }
}
@media screen and (max-width:1215px)
{
    .gz_b04_tx7
    {
        font-size:24px;
    }
    .on_b02_in1
    {
        background:url(../../../public/img/on_img1.png) no-repeat center / 100% 100%;
        height:522px;
        width:529px;
    }
    .ss_b05 .col3,
    .ss_b05 .col2
    {
        width:auto;
    }
    .sz_b11_b1_sp2
    {
        flex-wrap:wrap;
    }
    .sz_b11_b1_sp4
    {
        width:100%;
        margin:10px 0;
    }
}
@media screen and (max-width:1200px)
{
    .hide_1200 {
        display: none !important;
    }
    .ar_page
    {
        background:url(../../../public/img/ar_bg1_mob1.png) no-repeat right 160px, #fafdfd;
    }
    .btn1
    {
        width:155px;
    }
    .gz_b05_tx8
    {
        font-size:16px;
    }
    .gz_b05_tx2
    {
        font-size:15px;
    }
    .gz_b05_tx3 li a
    {
        font-size:13px;
    }
    .gz_b05_tx3 li
    {
        margin-bottom:5px;
    }
    .gz_b05_b01
    {
        height:240px;
    }
    .sc_b02_b9_noava
    {
        width:9vw;
        height:9vw;
        line-height:9vw;
    }
    .sz_b10_b6 p
    {
        display:inline-block;
    }
    .pvi_b01
    {
        flex-wrap:wrap;
    }
    .pvi_b01 a
    {
        margin-top:10px;
    }
}
@media screen and (max-width:1155px)
{
    .on_b02_in1
    {
        height:402px;
        width:419px;
        padding:60px 20px 0;
    }
    .on_b02
    {
        height:720px;
        min-height:0;
    }
    .on_b02_abs5
    {
        top:340px;
    }
    .on_b02_abs3
    {
        top:400px;
    }
    .on_b02_abs4
    {
        top:590px;
    }
    .spz_b01_b2_tx1 a
    {
        font-size:15px;
    }
}

@media screen and (max-width:1100px)
{
    .ps_b03_b1 .col0
    {
        width:245px;
    }
    .ps_b03_b1 .flex
    {
        flex-wrap:wrap;
        justify-content:center;
        max-width:770px;
        margin:0 auto;
    }
    .ps_b04_b1_ul
    {
        flex-wrap:wrap;
    }
    .ps_b04_b1_ul a
    {
        width:33%;
        margin-right:0;
        padding-right:0;
        margin-bottom:10px;
    }
    .ps_b04_b1_ul a.selected
    {
        margin-left: -5px;
        margin-right: 5px;
    }
    .ps_b05_b5 .flex
    {
        flex-wrap:wrap;
    }
    .ps_b02_b6
    {
        flex-wrap:wrap;
    }
    .ps_b02_b6 a
    {
        margin-top:3vw;
    }
    .lk_b02_b6 .btn2
    {
        white-space:nowrap;
        padding:15px 0;
        text-align:center;
        width:100%;
    }
    .of_b1 .col3
    {
        width:33.33%;
    }
}
.show_ipad {
    display: none;
}
@media screen and (max-width: 821px) {
    .category-image {
        width: 50%;
    }
    .auth-buttons button {
        font-size: 20px;
    }
    .show_ipad {
        display: block;
    }
    .hd_hide_ipad {
        display: none;
    }
}
@media screen and (max-width: 350px){
    .hide_on_410 {
        display: none !important;
    }
}
@media screen and (max-width:1070px)
{
    .auth-buttons button {
        width: auto;
        font-size: 12px;
    }
    .ul_lv1 > li
    {
        margin:0 8px;
    }
    .ul_lv1 > li > a,.ul_lv1 > li > span
    {
        white-space:nowrap;
        font-size:12px;
    }
    .logo_desk
    {
        width:120px;
        display:block;
        margin-top:7px;
    }
    .logo_wrap .dropdown_wrap
    {
        margin-left:15px;
    }
    .bs_b04_img8
    {
        margin-left:0;
    }
    .vi_b01_b3 ul
    {
        columns:3;
    }
    .gz_b03_tx1
    {
        font-size:24px;
        line-height:28px;
    }
    .gz_b03_in .btn2
    {
        white-space:nowrap;
    }
    .gz_b06_tx3 br
    {
        content:"";
    }
    .gz_b06_tx3 br:after
    {
        content:"\00a0"
    }
    .vp_b03_b7 .flex
    {
        flex-wrap:wrap;
    }
    .vp_b03_b7_tx2,
    .vp_b03_b7_tx6
    {
        white-space:nowrap;
    }
    .modal .close
    {
        top:-40px;
        right:0;
    }
    .lk_b02_parent
    {
        width:320px;
        flex:none;
    }
    .lkz_b02_b6 a
    {
        min-width:100%;
        width:100%;
        padding:15px 0;
        text-align:center;
    }
    #vp_b03_tabs_t3
    {
        /*flex-wrap:wrap;*/
    }
    #vp_b03_tabs_t3 .flex
    {
        flex-wrap:wrap;
    }
    .lkz_b10_b5 .col3,
    .lkz_b10_b5 .col4,
    .lkz_b10_b5 .col5,
    .lkz_b10_b5 .col8,
    .lkz_b10_b5 .col9
    {
        width:100%;
    }
    .lkz_b10_b5_v2 .col3,
    .lkz_b10_b5_v2 .col0
    {
        width:100%;
    }
    .lkz_b10_b5_v2,
    .lkz_b10_b5,
    .lkz_b10_b5 .flex
    {
        flex-wrap:wrap;
    }
    .lkz_b10_b5_v3
    {
        flex-wrap:wrap;
    }
    .lkz_b10_b5_v3 .col3
    {
        display:none;
    }
    .lkz_b02_b25 .flex1
    {
        flex-wrap:wrap;
    }
    .vp_b03_vt_t3_u7_b1 .col6
    {
        width:100%;
    }
    .vp_b03_vt_t3_u7_b1 .flex
    {
        flex-wrap:wrap;
    }
    .lkz_b10_b5 .btn5
    {
        margin-top:15px;
    }
    .vp_b03_b2_hb1
    {
        padding:2vw 3vw;
    }
    .vp_b03_b2_hb1 a
    {
        margin-right:2vw;
    }
    .sz_b10_b6
    {
        flex-wrap:wrap;
    }
    .sz_b10_b6 a
    {
        margin-top:2vw;
        margin-right:0;
    }
    .vp_b03_b2_hb1_r1 .flex
    {
        flex-wrap:wrap;
    }
    .vp_b03_b2_hb1_r1 .col3,
    .vp_b03_b2_hb1_r1 .col4,
    .vp_b03_b2_hb1_r1 .col5
    {
        width:50%;
    }
}
@media screen and (max-width:1050px)
{
    .on_b02_in1
    {
        background:none;
    }
}
@media screen and (max-width:1030px)
{
    .select_interval {
        margin-left: 0 !important;
    }
    .gz_b04 .col5
    {
        width:70%;
    }
    .gz_b03_in .col4
    {
        width:auto;
    }
    .prp_b04 > .flex > .col4
    {
        width:100%;
    }
    .prp_b04 .col4 + .col4
    {
        margin-top:4vw;
    }
    .prp_b04 > .flex
    {
        flex-wrap:wrap;
    }
    .gz_b08_img
    {
        max-width:140%;
    }
}
@media screen and (max-width:1000px)
{
    .btn1 + .btn1
    {
        margin-left:5px;
    }
    .header .btn1
    {
        width:140px;
    }
    .ps_b06 .col1
    {
        width:auto;
    }
    .ss_b03_in1
    {
    }
    .ss_b02 .col4
    {
        width:50%;
    }
    .jspDrag
    {
        border-radius:100%;
        background:#414883;
    }
    .jspHorizontalBar
    {
        height:20px;
        background:transparent;
    }
    .jspHorizontalBar .jspDrag
    {
        height:20px;
        top:-5px;
    }
    .jspHorizontalBar .jspTrack
    {
        height:10px;
        top:5px;
        border-radius:100px;
        background:#e3e4ed;
    }
    .ss_b03_table
    {
        margin-bottom:40px;
        margin-right:100px;
        width:auto;
    }
    .ss_b03_table .nowrap
    {
        white-space:normal;
    }
    .pv_b02
    {
        margin:5vw 0;
        text-align:center;
    }
    .ps_b01 .col1
    {
        width:80px;
        flex:none;
    }
}
@media screen and (max-width:980px)
{
    .ar_b02_tx1
    {
        font-size:25px;
        line-height:35px;
    }
    .ar_b02 .col7
    {
        width:70%;
    }
    .ar_page
    {
        background-size:40%;
    }
    .ar_b03_b2
    {
        padding:0 15px;
    }
    .ar_b03_b1, .ar_b03_b2
    {
        min-height:140px;
    }
    .vi_b05_b25
    {
        flex-wrap:wrap;
        margin-bottom:15px;
    }
    .vi_b05_b25 .col0
    {
        width:100%;
    }
    .vi_b05_tx4
    {
        white-space:nowrap;
    }
    .hd_rt_btns
    {
        flex-wrap:wrap;
        justify-content:flex-end;
    }
    .btn1 + .btn1
    {
        margin-left:0;
        margin-top:5px;
    }
    .register-role .btn1 + .btn1
    {
        margin-top: 0;
    }
    .header .btn1
    {
        width:100%;
        padding:8px 0;
    }
    .logo_wrap
    {
    }
    .gz_b05_tx2, .gz_b05_tx8
    {
        letter-spacing:0;
        font-weight:400;
    }
    .gz_b05_b01_in2
    {
        padding:0 10px;
    }
    .gz_b05_b01_in1
    {
        padding:60px 10px 0;
        text-align:center;
    }
    .on_b02_abs
    {
        position:static;
        max-width:100%;
        width:30%;
        margin:0 1% 30px;
        text-align:left;
        border:1px solid #ddd;
        padding:10px;
    }
    .on_b02
    {
        display:flex;
        flex-wrap:wrap;
        justify-content:center;
    }
    .on_b02_in1
    {
        position:static;
        width:auto;
        height:auto;
        padding:30px 0;
    }
    .on_b02_in1 br
    {
        content:"";
        display:none;
    }
    .on_b02_in1 br:after
    {
        content:"\00a0"
    }
    .on_b02
    {
        height:auto;
    }
    .op_b3 .col3
    {
        width:auto;
    }
    .op_b3 .col9
    {
        width:auto;
    }
    .ps_b08 .col3
    {
        width:33.33%;
    }
    .ps_b08 .flex
    {
        flex-wrap:wrap;
    }
    .vp_b03_b2_hb1 a
    {
        white-space:nowrap;
    }
    .spz_b01_b2 .col3
    {
        width:50%;
    }
    .spz_b01_b2 .flex
    {
        flex-wrap:wrap;
    }
    .spz_b01_b2
    {
        max-width:800px;
        margin:0 auto;
    }
    .spz_b01_b2_tx1 a
    {
        font-size:18px;
    }
    .sz_b05 > .flex > .col4
    {
        width:320px;
        flex:none;
    }
    .sz_b09 .flex1
    {
        flex-wrap:wrap;
    }
    .vi_b05_tx4
    {
        margin-bottom:3vw;
    }
}

@media screen and (max-width:950px)
{
    .favorite + label {
        visibility: hidden;
    }
    .price_search {
        padding: 0 15px;
    }
    .clear_padding {
        padding-left: 15px;
    }
    .gz_b01_tx2
    {
        font-size:13px;
        line-height:18px;
    }
    .gz_b03_in
    {
        padding:30px;
    }
    .kf_b03_b6 .flex
    {
        flex-wrap:wrap;
    }
    .kf_b03_b6 .col4
    {
        width:100%;
    }
    .kf_b03_b6 .col8
    {
        width:100%;
    }
    .kf_b03_b6 br
    {
        content:"";
        display:none;
    }
    .kf_b03_b6 br:after
    {
        content:"\00a0"
    }

    .vp_b03_tabs_ul a
    {
        font-size: 1.5vw;
        margin:0 1vw;
    }

    .settings-specs .specs-tabs a {
        font-size: 1.3vw;
    }
}
@media screen and (max-width:870px)
{
    .ef_b02_ia1,
    .ef_b02_ia2
    {
        display:none;
    }

    .gz_b05_tx8, .gz_b05_tx2
    {
        font-size:16px;
    }
    .gz_b01 h1
    {
        font-size:30px;
        line-height:38px;
    }
    .ul_lv1 > li > a, .ul_lv1 > li > span
    {
        letter-spacing:0;
    }
    .ul_lv1 > li
    {
        margin:0 4px;
    }
    .gz_b02_tx3
    {
        font-size:17px;
    }
    .gz_b02_tx1
    {
        font-size:30px;
        line-height:43px;
    }
    .op_b4_tw p
    {
        font-size:15px;
    }
    .op_b4_b1
    {
        padding:20px 5px;
        text-align:center;
    }
    .op_b4_iw img
    {
        margin:auto;
        right:0;
    }
    .ot_b02 .col2,
    .ps_b02 .col2
    {
        width:20%;
    }
    .sc_b02_b12
    {
        flex-wrap:wrap;
        justify-content:center;
    }
    .sc_b02_b12 .col4,
    .sc_b02_b12 .col5,
    .sc_b02_b12 .col7,
    .sc_b02_b12 .col9
    {
        width:100%;
    }
    .sc_b02_b7 .btn2
    {
        padding:15px 0;
    }
    .sz_b11_b2
    {
        width:100%;
        margin:0 0 10px;
    }
    .ddm_main
    {
        min-width:330px;
    }
    .top_mn_ispoln li
    {
        width:100%;
    }
}
@media screen and (max-width:850px)
{
    .pvi_b01 .btn2,
    .pvi_b01 .btn10
    {
        padding:15px 35px;
    }
    .pvi_b01 a + a
    {
        margin-left:0;
    }

}
@media screen and (min-width: 1024px){
    .show-1024 {
        display: none;
    }
    .time {
        font-size: 80px;
    }
}
@media screen and (max-width: 775px){
    .vp_b03_parent {
        width: 59%;
    }
}
@media screen and (max-width: 820px){
    #freelancers_search {
        display: none;
    }
}
@media screen and (max-width: 768px){
    .mb-column {
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start !important;

    }
    .adaptive {
        min-width: auto;
    }
    .filter-button-container {
        position: sticky;
        background: white;
        bottom: 0;
    }

    .vp_b03_b1 {
        box-shadow: none;
    }
    .form_work_type {
        padding: 0;
    }
    .ot_b02 .col2,
    .ps_b02 .col2 {
        margin-right: 12px;
    }
    input.flatpickr {
        background-size: 18px !important;
    }
    .vp_b03_parent {
        width: 100%;
    }
}
@media screen and (max-width: 1023px){
    .hide-1024 {
        display: none;
    }
}
@media screen and (min-width: 1024px){

}
@media screen and (min-width: 769px){
    .pf-wrap {
        width: 20%;
        margin: 0;
    }
    .flex-justify2 {
        justify-content: flex-start !important;
    }
    .mb-wrap {
        flex-wrap: nowrap;
    }
}


@media screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 90vw !important;
        left: 5vw !important;
    }

}
@media screen and (max-width: 1300px) and (min-width: 951px) {
    .columnts-laptops .col4 {
        width: 45%;
    }
    .columnts-laptops .col8 {
        width: 55%;
    }
}

@media screen and (max-width: 950px) and (min-width: 821px) {
    .columnts-laptops .col4 {
        width: 55%;
    }
    .columnts-laptops .col8 {
        width: 45%;
    }
}
@media screen and (max-width:821px)
{

    .show_820 {
        display: block;
    }
    .hide_820 {
        display: none;;
    }
    .col12-mb {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .col12-mb-clear {
        width: 0%;
        padding: 0;
    }
}
@media screen and (max-width:768px)
{
    .green, .green-outline {
        font-size:16px;
    }
    .sort-select {
        width: 100%;
    }

    .column-mb {
        flex-direction: column;
        padding: 0 !important;
    }
    .scroll-paginator-wrap {
        padding: 0;
    }
    .ps_b02_b3 p, .ps_b02_b3 span {
        font-size: 16px !important;
    }
    .ps_b02_b4 h3, .ps_b02_b4 span, .ps_b02_b2 p {
        font-size: 24px !important;
    }
    .ps_b02_b2 span {
        font-size: 18px !important;
    }
    .error-wrap {
        margin-left: 0;
        max-width: 100%;
    }
    .portfolio-block>div:first-of-type>div {
        width: 100%;
    }
    .dark-button, .dark-button-outline, .dark-button-outline2 {
        padding: 15px 8px;
    }
    .btn-modal button {
        font-size: 13px !important;
    }
    .btn-orange {
        font-size: 16px;
    }
    .hbtns div{
        font-size: 12px;
    }
    .create-account-worker-popup__steps-block {
        display: block;
    }
    .adp-1 {
        font-size: 3.5vw !important;
    }
    .favorite + label {
        top: 4vw;
    }
    .pcz_tx2_in {
        width: auto;
    }
    .adaptive {
        margin-left: 0;
    }
    .p15 {
        padding: 0 15px;
    }
    .table_2c {
        width: 60%;
    }
    .mb-p-3 {
        padding-left: 0;
        width: 41vw;
    }
    /*.vi_b01_b3_in .btn2 {*/
    /*    margin-bottom: 15px;*/
    /*}*/
    .new-code {
        margin: auto;
    }
    .save_text {
        font-size: 18px;
        text-align: center;
    }
    .logo_wrap .dropdown_wrap
    {
        position:static;
    }
    .ddm_main
    {
        border-top: 3px solid #0cb544;
        /* ToDo: проверить отображение на других страницах
        /*min-width:0;*/
        right:15px;
        left:15px;
    }
    .top_mn_ispoln
    {
        border-top: none;
        columns:2;
        display:block;
    }
    .top_mn_ispoln li
    {
        white-space:nowrap;
        font-size:3vw;
        margin-bottom:2vw;
    }
    .top_mn_ispoln li a
    {
        padding-top:0;
        vertical-align:top;
        font-size:3vw;
        white-space:normal;
    }
    .logo_wrap
    {
        width:auto;
    }
    .ar_b06 .flex,
    .ar_b05 .flex
    {
        flex-wrap:wrap;
    }
    .ar_b06 .col6,
    .ar_b05 .col6
    {
        width:100%;
    }
    .ar_b06_tx1 br,
    .ar_b03_tx1 br
    {
        content:"";
        display:none;
    }
    .ar_b06_tx1 br:after,
    .ar_b03_tx1 br:after
    {
        content:"\00a0"
    }
    .logo_desk
    {
        display:none;
    }
    .hd_hide
    {
        display:none;
    }
    .logo_mobile
    {
        display:block;
        width:32vw;
    }
    .logo_wrap .mn_burger
    {
        width:5.2vw;
        height:3.5vw;

    }
    .mn_burger2
    {
        background:none;
        border-top:2.3vw solid #dbdde8;
        border-left:1.9vw solid transparent;
        border-right:1.9vw solid transparent;
        border-bottom:0;
        height:0;
        width:0;
    }
    .mn_burger2:hover
    {
        border-top:2.3vw solid #404883;
    }
    .hd_status
    {
        font-weight:600;
        margin-right:2vw;
    }
    .hd_ava
    {
        margin-right:2vw;
    }
    .logo_wrap
    {
    }
    .header
    {
        padding:25px 0 15px;
    }
    .logo_wrap .dropdown_wrap
    {
        margin-left:7vw;
    }
    .container
    {
        padding:0 4.5vw;
    }
    .breadcrumbs li, .breadcrumbs li a
    {
        font-size:2.7vw;
    }
    .ft_mob_hide
    {
        display:none;
    }
    .ft_mob_view
    {
        width:100%;
    }
    .ft_tx1
    {
        font-size:3vw;
        margin:8vw 0;
    }
    .ft_tx2
    {
        font-size:2.7vw;
    }
    h1
    {
        font-size:6vw;
    }
    .ar_tx1
    {
        font-size:3.5vw;
        line-height:5.1vw;
    }
    .ar_page
    {
        background:#fafdfd;
    }
    .ar_b02 .col7
    {
        width:100%;
    }
    .ar_b02_tx1
    {
        font-size:4.5vw;
        line-height:4.5vw;
    }
    .gz_b02_tx3
    {
        font-size:4vw;
        line-height:5.6vw;
    }
    .gz_b02_tx4
    {
        font-size:3.5vw;
        line-height:5.1vw;
    }
    .ar_b02
    {
        background:url("../../../public/img/ar_bg1_mob2.png") no-repeat right bottom / 80% auto;
        padding-bottom:80vw;
    }
    .ar_b03 .col6
    {
        width:100%;
    }
    .ar_b03 .flex
    {
        flex-wrap:wrap;
    }
    .ar_b03_b1
    {
        font-size:3.6vw;
        line-height:4.5vw;

    }
    .ar_b03_tx1
    {
        font-size:5.5vw;
        line-height:6.2vw;
    }
    .ar_b03_b2 span
    {
        margin-right:0;
        width:100%;
        font-size:5vw;
        text-align:center;
    }
    .ar_b03_b2
    {
        flex-wrap:wrap;
        justify-content:center;
    }
    .ar_b03_b2 img
    {
        width:6vw;
    }
    .ar_b03_b1
    {
        background:url(../../../public/img/ar_hand_mob.png) no-repeat 20px center / 8vw;
        padding-left:18vw;
        margin-bottom:6vw;
    }
    .ar_b03_b2
    {
        padding:4vw;
    }
    .ar_b04_tx1
    {
        font-size:4.4vw;
        line-height:6.5vw;
    }
    .ar_b04_tx3
    {
        font-size:3.8vw;
        line-height:initial;
        margin:0 0 3vw;
    }
    .ar_b04_tx4
    {
        font-size:3.6vw;
        line-height:initial;
    }
    .tariff-wrap {
        margin-left: 0;
        border: none;
        padding: 0;
    }
    .ar_b05 .col6:first-of-type
    {
        order:2;
        margin-top:12vw;
    }
    .tx_38_53
    {
        font-size:4.8vw;
        line-height:6.8vw;
    }
    .ar_b05_tx1
    {
        text-align:center;
    }
    .tx_14_20
    {
        font-size:3.6vw;
        line-height:5.3vw;
    }
    .ar_b04, .ar_b05, .ar_b06
    {
        background:#fafdfd;
    }
    .ar_b06_b1
    {
        margin-top:8vw;
        background:#fff;
        padding: 40px 45px 0 45px;
    }
    .gz_b02_tx2
    {
        width:13vw;
        height:13vw;
        font-size:7vw;
        line-height:12vw;
        border-width:0.4vw;
        margin-right:5vw;
    }
    .gz_b02_tx2:after
    {
        left:6vw;
        width:0.4vw;
    }
    .ar_b03_b1, .ar_b03_b2
    {
        min-height:0;
    }
    .ar_b06_tx1, .ar_b03_tx1, .ar_b04_tx1
    {
        margin-bottom:6vw;
    }
    .ar_b06_tx4
    {
        font-size:5vw;
    }
    .ar_b04, .ar_b03
    {
        padding-top:6vw;
    }
    .ar_tx1
    {
        margin:6vw auto;
    }
    .ar_b05
    {
        padding:4vw 0;
    }
    .bs_b02 .col6
    {
        width:100%;
    }
    .bs_b04 .flex
    {
        flex-wrap:wrap;
    }
    .bs_b04 .col6
    {
        width:100%;
    }
    .bs_b04
    {
        padding:0;
    }
    .bs_b04 .container
    {
        padding:0;
    }
    .bs_b04 .flex
    {
        margin:0;
    }
    .bs_b04_left
    {
        padding:8vw 4.5vw;
        background:#00af49;
    }
    .bs_b04_right
    {
        padding:8vw 4.5vw;
        background:#404883;
    }
    .hide_768
    {
        display:none;
    }
    .hide_768_imp
    {
        display:none !important;
    }
    .show_768,
    .top_mn_ispoln.show_768
    {
        display:block;
    }
    .bs_b02_tx1,
    .bs_b04_tx2
    {
        font-size:5.7vw;
    }
    .bs_b02_tx1
    {
        margin-bottom:5vw;
    }
    .bs_b02_tx4
    {
        font-size:4.5vw;
    }
    .bs_b04_tx7 li,
    .bs_b04_b4 th
    {
        font-size:3vw;
        line-height:4vw;
    }
    .bs_b04_b4 td
    {
        font-size:3vw;
        line-height:4vw;
    }
    .bs_b01_tx1
    {
        margin:5vw 0;
    }
    .bs_b05 .flex
    {
        flex-wrap:wrap;
    }
    .bs_b05 .col6,
    .bs_b05 .col5
    {
        width:100%;
    }
    .bs_b05_tx1
    {
        font-size:6.7vw;
        line-height:7.5vw;
    }
    .bs_b05_in
    {
        font-size:4vw;
        line-height:5.5vw;
    }
    .bs_b02 + .bs_b02
    {
        margin-top:8vw;
    }
    .vi_b01_b3 li span, .vi_b01_b3 li h2,
    .vi_b01_b3 li
    {
        font-size:14px;
    }
    .vi_b01_b3 ul
    {
        columns:1;
    }
    .btn3
    {
        font-size:3.5vw;
        text-align:center;
    }
    .gz_b05_b07 .btn3 {
        margin-left: 0;
        margin-top: 4vw;
    }

    .btn3 + .btn3
    {
        margin:4vw 0 0;
    }
    .title-link
    {
        font-size:4.2vw;
    }
    .vi_b05_b4
    {
        font-size:4vw;
        margin-bottom: 75px;
    }
    .vi_b05_b6
    {
        padding:2vw;
        font-size:2.9vw;
    }
    .vi_b05_b6_in1
    {
        flex-wrap:wrap;
    }
    .vi_b05_b6_in1 .col4
    {
        width:100%;
    }
    .vi_b05_b6_in1 img
    {
        width:100%
    }
    .vi_b05_tx8
    {
        font-size:4vw;
    }
    .vi_b05_b1_mob .col2
    {
        width:20vw;
    }
    .vi_b05_b1_mob > .flex
    {
        align-items:flex-start;
    }
    .vi_b01_b3_a
    {
        font-size:4vw
    }
    .vi_b05_tx1
    {
        font-size:6vw;
        margin-bottom:10vw;
        text-align:center;
    }
    .vi_b05_b1_in4
    {
        display:block;
    }
    .vi_b05_tx4
    {
        font-size:4vw;
        margin:0 0 3vw;
    }
    .vi_b01_b3
    {
        padding:5vw;
    }
    .vi_b01_b1 .btn3
    {
        width:100%;
    }
    .vi_b05_b1_mob
    {
        background:#ffffff;
        box-shadow:0px 1px 9px 0px rgba(0, 0, 0, 0.26);
        padding:3vw 4vw;
        margin-bottom: 3vw;
        /*margin-bottom: 20px;*/
        width: 100%;
    }
    .vi_b05_b1_mob .btn2
    {
        margin-bottom:4vw;
        font-size: 12px !important;
    }
    .vi_b05_tx9 p
    {
        font-size:4vw;
        /*background:url(../../../public/img/vi_eye.png) no-repeat left center / auto 50%;*/
        padding-left:6vw;
    }
    .btn1 + .btn1
    {
        margin-left:1vw;
        margin-top:0;
    }
    .header .flex
    {
        align-items:center;
    }
    .header .btn1
    {
        width:39vw;
        font-size:2.5vw;
        padding:2vw 0;
        margin-top:2vw;
    }
    .gz_header .flex
    {
        /*flex-wrap:wrap;*/
    }
    .gz_header .col0:last-child
    {
        /*width:100%;*/
    }
    .gz_header .flex1
    {
        justify-content:center;
    }
    .gz_b01 h1
    {
        margin-top: 25px;
        font-size:4.6vw;
        line-height:6.4vw;
    }
    .gz_b01 .col8,
    .gz_b01 .col7
    {
        width:100%;
    }
    .gz_b01 .col8
    {
        background:url(../../../public/img/gz_bg1_mob.png) no-repeat center bottom / 100%;
        padding-bottom:75vw;
    }
    .gi_page .gz_b01 .col8
    {
        background:url(../../../public/img/gi_bg1_mob.png) no-repeat center bottom / 100%;
    }
    .gz_b01_tx2
    {
        font-size:3vw;
        line-height:3.5vw;
    }
    .gz_b01,
    .gi_page .gz_b01
    {
        background:none;
    }
    .gz_b01_b1
    {
        margin-top:0;
    }
    .gz_b01_b2
    {
        text-align:center;
        margin-top:6vw;
    }
    .gz_b02 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b02 .col6
    {
        width:100%;
    }
    .gz_b02_b2
    {
        text-align:center;
        margin:5vw 0 0;
    }
    .gz_b02_b2 img
    {
        margin-left:0;
    }
    .gz_b03_tx1
    {
        font-size:3.4vw;
        line-height:3.8vw;
    }
    .gz_b03_in .flex
    {
        flex-wrap:wrap;
    }
    .gz_b03_in .col8,
    .gz_b03_in .col4
    {
        width:100%;
        text-align:center;
    }
    .gz_b03_in .col8
    {
        margin-bottom:3vw;
    }
    .gz_b04 .col5,
    .gz_b04 .col6, .block9 .col6,
     .block6 .col6,.block8 .col6
    {
        width:100%;
    }
    .gz_b04 .col5
    {
        order:1;
    }
    .gz_b04_img
    {
        display:block;
        margin:4vw auto 10vw;
        max-width:60vw;
    }
    .gz_b04 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b04_b1 .col10,
    .gz_b04_b1 .col3
    {
        width:100%;
    }
    .gz_b04_b1 .col3
    {
        margin-top:2vw;
    }
    .gz_b04_tx1
    {
        font-size:4vw;
    }
    .gz_b04_b1
    {
        padding:2vw 5vw;
    }
    .gz_b04_tx2,
    .gz_b04_tx3,
    .gz_b04_b4,
    .gz_b04_tx4
    {
        font-size:4vw;
    }
    .gz_b04_tx5
    {
        font-size:3vw;
        margin-top:2vw;
    }
    .gz_b05 .col3
    {
        width:50%;
    }
    .gz_b05 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b05_b01
    {
        height:40vw;
    }
    .gz_b05_tx8, .gz_b05_tx2
    {
        font-size:16px;
    }
    .gz_b05_b01_in2
    {
        padding:40px 30px;
    }
    .gz_b05_tx3 li a
    {
        font-size:2.4vw;
    }
    .gz_b06 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b06 .col6
    {
        width:100%;
    }
    .gz_b06 .show_768
    {
        margin-top:10vw;
        text-align:center;
    }
    .gz_b06_tx1
    {
        font-size:4.8vw;
        margin-bottom:5vw;
    }
    .gz_b06_tx2,
    .gz_b04_tx8
    {
        font-size:25px;
        line-height:5vw;
    }
    .gz_b06_tx3
    {
        font-size:3vw;
        padding-left:10vw;
        background:url(../../../public/img/gz_b05_ico_prot.png) no-repeat left center / auto 100%;
        min-height:10vw;
    }
    .gz_b06_b3
    {
        text-align:center;
    }
    .gz_b07 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b07 .col8,
    .gz_b07 .col5
    {
        width:100%;
    }
    .gz_b07 .col5
    {
        text-align:center;
    }
    .gz_b07_img,
    .gi_page .gz_b07_img
    {
        margin-top:20px;
        object-fit: cover;
        width: 100%;
    }
    .gi_b10_tx1
    {
        font-size:4.8vw;
        margin-bottom:5vw;
    }
    .gi_b10_tx2
    {
        font-size:3.1vw;
        margin:3vw 0 8vw;
    }
    .gi_b10 .col4
    {
        width:100%;
    }
    .gi_b10 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b02_b2 img
    {
        max-width:100%;
    }
    .gz_b05_tx1,
    .gz_b04_tx7
    {
        font-size:4.0vw;
        line-height:6.6vw;
    }
    .gz_b01
    {
        padding: 200px 0 20px;
    }
    .gz_b01_tx1
    {
        font-size:8vw;
    }
    .gz_b02_tx1
    {
        font-size:4.8vw;
        line-height:6.8vw;
    }
    .gz_b03
    {
        padding:10vw 0;
        margin:0;
    }
    .gz_b02_b4
    {
        margin:5vw 0 0;
        text-align:center;
    }
    .gz_b08 .flex
    {
        flex-wrap:wrap;
    }
    .gz_b08 .col6
    {
        width:100%;
    }
    .gz_b08_img
    {
        max-width:100%;
        left:0;
        margin:5vw 0;
    }
    .vp_b02 .flex
    {
        flex-wrap:wrap;
    }
    .vp_b02 .col0
    {
        text-align:center;
        width:100%;
    }
    .vp_b02 .col0 + .col0
    {
        margin-top:5vw;
    }
    .vp_b03 .flex
    {
        flex-wrap:wrap;
    }
    .vp_b03 .flex>div
    {
        width: 100%;
    }
    .vp_b03 .col4,
    .vp_b03 .col8
    {
        width:100%;
    }
    .st_checkbox + label
    {
        /*font-size:4vw;*/
    }
    .vp_b03_tx1
    {
        font-size:4vw;
    }
    /*.vp_b03_b1_line **/
    /*{*/
    /*    font-size:4vw;*/
    /*}*/
    /*.st_checkbox + label:before*/
    /*{*/
    /*    top:1vw;*/
    /*}*/
    .vp_b03_b6 .flex1
    {
        flex-wrap:wrap;
    }
    .vp_b03_b4_tx3, .vp_b03_b4_tx3 h2
    {
        font-size:3.5vw;
        word-break:break-all;
        margin-right: 30px;
    }
    .vp_b03_b4_tx6
    {
        font-size:13px;
        line-height:1.3em;
        word-break:break-all;
    }
    .vp_b03_b7 .flex1
    {
        flex-wrap:wrap;
    }
    .vp_b03_b7 .flex1 p
    {
        /*margin-top:1vw;*/
        margin-left: 4px;
    }
    .vp_b03_b4
    {
        font-size: 6vw;
        padding:1vw;
    }
    .lp_b01_tx2,
    .st_input,
    .lp_chb1,
    .st_select,
    .react_select
    {
        font-size:4vw;
    }
    s.lp_b01_tx1
    {
        font-size:7vw;
    }
    .lp_b01_b1
    {
        padding:10vw;
    }
    .breadcrumbs li:after
    {
        vertical-align:middle;
    }
    .breadcrumbs
    {
        padding:2vw 0;
        align-items:center;
    }
    .kf_b03 .flex
    {
        flex-wrap:wrap;
    }
    .kf_b03 .col6
    {
        width:100%;
    }
    .kf_b03_tx1
    {
        font-size:3vw;
    }
    .fs-dropdown *
    {
        font-size:3vw;
    }
    .kf_b02_tx1
    {
        font-size:5vw;
    }
    .kf_b05_b1, .kf_b05_b1 a
    {
        font-size:3vw;
    }
    .kf_b05_tx1
    {
        font-size:3vw;
    }
    .kf_b07 .owl-carousel .owl-dot
    {
        display:inline-block;
        width:3vw;
        height:3vw;
        border-radius:100%;
        border:1px solid #fff;

        margin:0 1vw;
    }
    .kf_b07 .owl-carousel .owl-dot.active
    {
        background:#fff;
    }
    .kf_b07 .owl-dots
    {
        margin:5vw 0 0;
        text-align:center;
    }
    .on_b02_abs
    {
        width:100%;
        border:0;
        padding:3vw 3vw 3vw 6vw;
    }
    .on_b02_abs1
    {
        background:url(../../../public/img/on_rn_ico1.png) no-repeat left 9vw / 3vw;
    }
    .on_b02_abs2
    {
        background:url(../../../public/img/on_rn_ico2.png) no-repeat left 9vw / 3vw;
    }
    .on_b02_abs3
    {
        background:url(../../../public/img/on_rn_ico3.png) no-repeat left 9vw / 3vw;
    }
    .on_b02_abs4
    {
        background:url(../../../public/img/on_rn_ico4.png) no-repeat left 9vw / 3vw;
    }
    .on_b02_abs5
    {
        background:url(../../../public/img/on_rn_ico5.png) no-repeat left 9vw / 3vw;
    }
    .on_b02_tx1
    {
        font-size:6vw;
        line-height:7vw;
    }
    .on_b02_in1 p
    {
        font-size:6vw;
    }
    .on_b01_tx2
    {
        font-size:4vw;
        line-height:6vw;
    }
    .on_b04 .col6
    {
        width:100%;
    }
    .on_b03
    {
        padding:5vw 0;
    }
    .on_b03_tx1
    {
        font-size:8vw;
    }
    .op_b3 .flex
    {
        flex-wrap:wrap;
    }
    .op_b3 .col3,
    .op_b3 .col9
    {
        width:100%;
    }
    .op_b3_tx1
    {
        font-size:6vw;
        text-align:center;
    }
    .op_b2_tx1,
    .op_b2_tx2
    {
        font-size:4vw;
    }
    .op_b4 .flex
    {
        flex-wrap:wrap;
    }
    .op_b4 .col3
    {
        width:100%;
    }
    .op_b4_tw p
    {
        font-size:5vw;
    }
    .op_b4_tw
    {
        height:15vw;
    }
    .op_b4_b1
    {
        padding:3vw;
        margin-bottom:5vw;
    }
    .op_tx1
    {
        margin:5vw 0 0;
    }
    .op_b2_tx1
    {
        margin:4vw auto;
    }
    .op_b01
    {
        padding-bottom:5vw;
    }
    .ot_b02 .flex,
    .ps_b02 > .flex
    {
        display:block;
    }
    .ot_b02 .col2,
    .ps_b02 .col2
    {
        float:left;
        min-width: 117px;
        padding: 0;
    }
    .ot_b02_b1
    {
        padding:3vw 0 5vw;
    }
    .ot_b02_tx5
    {
        font-size:3vw;
    }
    .ot_b02 .col10,
    .ps_b02 .col10
    {
        width:100%;
    }
    .ot_b02_tx4
    {
        font-size:5vw;
    }
    .ot_b02
    {
        padding:5vw 3vw 5vw;
    }
    .modal
    {
        padding:4vw;
    }

    .pp_b02,
    .pp_b03_b2 p
    {
        font-size:4vw;
        line-height:5vw;
        margin-bottom:5vw;
    }
    .pp_b03_tx2
    {
        font-size:4vw;
        margin:0 0 5vw;
        line-height:4.5vw;
    }
    .pp_b03_tx1
    {
        font-size:5vw;
        height:10vw;
        width:10vw;
        line-height:9.2vw;
        border:0.5vw solid #00af49;
    }
    .pp_b03_b1 .col0
    {
        padding:0 1vw;
    }
    .ps_b00_tx1
    {
        margin:3vw 0;
    }
    .ps_b00_tx1 br
    {
        content:"";
    }
    .ps_b00_tx1 br:after
    {
        content:"\00a0"
    }
    .pp_b01_tx1
    {
        margin:10vw 0;
    }
    .prp_b03 .st_textarea
    {
        height:50vw;
    }
    .st_textarea
    {
        font-size:4vw;
    }
    .ps_b02 .flex1
    {
        flex-wrap:wrap;
    }
    .ps_b02_tx2,
    .ps_b02_b2 p,
    .ps_b02_b2 span,
    .ps_b02_b3 p,
    .ps_b02_b3 span
    {
        font-size:3vw;
    }
    .ps_b02_b4 p
    {
        font-size:4vw;
    }
    .vi_b05_tx3
    {
        align-items:flex-start;
        margin-bottom:2vw;
    }
    .ps_b02_b6
    {
        text-align:center;
    }
    .ps_b04_state
    {
        font-size:3.6vw;
        height:5.8vw;
        white-space:nowrap;
        padding-left:7vw;
        line-height:6vw;
    }
    .ps_b04_b1_ul a
    {
        width:50%;
    }
    .ps_b04_b1_ul a.selected
    {
        margin-left: -5px;
        margin-right: 5px;
    }
    .ps_b03_b1 .col0
    {
        width:100%;
    }
    .ps_b03_tx3
    {
        font-size:5vw;
    }
    .ps_b03_tx4
    {
        font-size:4vw;
        line-height:5vw;
        margin-bottom:4vw;
        min-height:0;
    }
    .ps_b03_tx5
    {
        font-size:6vw;
        margin-bottom:5vw;
    }
    .ps_b03_tx5 span
    {
        font-size:5vw;
    }
    .ps_b08 .col3,
    .ps_b08 .col4
    {
        width:100%;
    }
    .ps_b08_tx1
    {
        font-size:3.5vw;
    }
    .ps_b08_tx2
    {
        font-size:3vw;
    }
    .link1,
    .psp_b03 .link1
    {
        font-size:2.6vw;
    }
    /*.ps_b02_b6 .btn2, .button-wrapper button, .button-wrapper a*/
    /*{*/
    /*    font-size:3.3vw !important;*/
    /*}*/
    .ps_b02_b6
    {
        justify-content:center;
    }
    .ps_b02_b6 a
    {
        margin:3vw;
    }
    .ps_b03_tx1
    {
        font-size:5vw;
    }
    .ps_b04_tx1
    {
        font-size:4vw;
        line-height:5vw;
    }
    .ps_b05_ln1 .link1
    {
        font-size:2.6vw;
    }
    .ps_b05_ln1
    {
        text-align:left;
    }
    .ps_b06_b2 .flex,
    .ps_b06_b2 .flex1
    {
        flex-wrap:wrap;
    }
    .ps_b06_tx1
    {
        font-size:3vw;
        width:100%;
    }
    .ps_b06_tx2
    {
        font-size:3vw;
        width:100%;
    }
    .ps_b06_b2 .hd_message
    {
        position:absolute;
        right:2vw;
        top:3vw;
    }
    .ps_b06_tx5
    {
        font-size:3.5vw;
        line-height:4.3vw;
    }
    .btn5
    {
        font-size:3.4vw;
        white-space:nowrap;
        padding:4vw 5vw;
    }
    .ps_b07 .link1
    {
        font-size:3.5vw;
    }
    .ps_b08_b9_tx1,
    .ps_b08_b9_tx1 span
    {
        font-size:4vw;
    }
    .ps_b08_b9
    {
        padding:2vw;
    }
    .ps_b08_b9_tx3,
    .ps_b08_b9_tx2
    {
        font-size:2.6vw;
    }
    .ps_b04_state
    {
        background-size:auto 100%;
    }
    .ps_b02
    {
        padding:5vw;
    }
    .ps_b04_state span
    {
        line-height:4.6vw;
        width:4vw;
        height:4vw;
        font-size:3vw;
        left:3vw;
        top:-2vw;
    }
    .rf_b02_tx1
    {
        font-size:3.5vw;
    }
    .rf_b02_tx2
    {
        font-size:5vw;
    }
    .rf_b02_tx3
    {
        font-size:6vw;
    }
    .rf_b04 th
    {
        font-size:3.3vw;
        padding:2vw;
    }
    .rf_b04 td
    {
        padding:2vw;
        font-size:2.7vw;
    }
    .rf_b04 td:nth-child(3) span, .rf_b04 th:nth-child(3) span
    {
        width:1vw;
    }
    .rp_b01_b1 .st_checkbox + label
    {
        font-size:0.7rem;
    }
    .btn1
    {
        width:47vw;
        font-size:3.5vw;
    }
    .register-role .btn1
    {
        font-size: 2.5vw;
    }
    .lp_b01_tx1
    {
        margin-bottom:6vw;
    }
    .rp_b01_tx6
    {
        margin-bottom:9vw;
    }
    .rp_b03
    {
        margin-bottom:10vw;
    }
    .lp_b01_b2
    {
        margin-top:12vw;
    }
    .vi_b06 li a,
    .vi_b06 li span
    {
        font-size:4vw;
    }
    .mo_b09_tx2
    {
        font-size:3vw;
    }
    .st_input_file + label
    {
        font-size:3.5vw;
    }
    .mo_tx1
    {
        font-size:4vw;
    }
    .sc_b02 .flex
    {
        flex-wrap:wrap;
    }
    .sc_b02 .col4,
    .sc_b02 .col8
    {
        width:100%;
    }
    .sc_b02_b2
    {
        text-align:center;
    }
    .sc_b02_l
    {
        min-height:5vw;
        font-size:4vw;
        line-height:5vw;
        padding:3vw 0 3vw 16vw;
        background-size:auto 100%;
    }
    .sc_b02_l.sc_b02_l6
    {
        background-size:auto 80%;
    }
    .sc_b02_b9_tx1
    {
        font-size:4.5vw;
        margin-bottom:0;
    }
    .sc_b02_b5
    {
        margin-bottom:4vw;
    }
    .sc_b02_b9_tx2_wr
    {
        margin-bottom:0;
    }
    .sc_b02_b12
    {
        margin-top:7vw;
        text-align:center;
    }
    .sc_b02_b9_tx2
    {
        font-size:4vw;
    }
    .sc_b02_b9_tx3
    {
        font-size:3vw;
    }
    .sc_b02_b9_tx5
    {
        font-size:2.5vw;
        margin-top:5vw;
    }

    /*.sc_b02_b9 .flex*/
    /*{*/
        /*display:block;*/
    /*}*/
    .sc_b02_b9 .col3
    {
        width:20vw;
        float:left;
        margin-right:3vw;
        padding-right:0;
    }
    .sc_b02_b9 .col9
    {
        width:100%;
    }
    .sc_b02_b7 .flex
    {
        flex-wrap:wrap;
        justify-content:center;
    }
    .sc_b02_b7 .col9
    {
        width:100%;
    }
    .sc_b02_b7 .col3
    {
        width:auto;
        margin-top:3vw;
    }
    .sc_b02_b7 .btn2
    {
        padding:4vw 10vw;
    }
    .sc_b02_b9_noava
    {
        font-size:8vw;
        height:15vw;
        line-height:15vw;
        width:15vw;
    }
    .sc_b04_tx2
    {
        font-size:4vw;
        margin-bottom:0;
    }
    .sc_b04_tx3
    {
        font-size:3vw;
        margin-bottom:2vw;
    }
    .sc_b05_tx5,
    .sc_b05_b3
    {
        font-size:4vw;
    }
    .sc_b05_b2
    {
        margin-top:8vw;
    }
    .ef_b02_in1 .flex,
    .ef_b02_in2 .flex,
    .ef_b02_in3 .flex
    {
        flex-wrap:wrap;
    }
    .ef_b02_in1 .col6,
    .ef_b02_in2 .col6,
    .ef_b02_in3 .col3
    {
        width:100%;
    }
    .ef_b02_in1 .col6:first-of-type
    {
        order:1;
        text-align:center;
    }
    .gz_b02_tx2:after
    {
        height:7vw;
        bottom:-7vw;
    }
    .ef_b02_in1_b1 p,
    .ef_b02_in_tx1
    {
        font-size:5vw;
    }
    .ef_b02_in3_tw
    {
        font-size:4.4vw;
        line-height:5vw;
        margin-bottom:5vw;
    }
    .ef_b03
    {
        padding:9vw 0;
    }
    .ef_ln1 p
    {
        font-size:3vw;
        margin-left:2vw;
    }
    .ef_ln1
    {
        width:40vw;
        height:11vw;
        padding:1vw 0;
        font-size:4vw;
        margin:6vw auto 0;
    }
    .ef_ln3
    {
        width:100% !important;
    }
    .ss_b02 .flex
    {
        flex-wrap:wrap;
    }
    .ss_b02 .col3,
    .ss_b02 .col4
    {
        width:100%;
    }
    .ss_b02 .col3
    {
        margin-top:2vw;
    }
    .ss_b02_tx1
    {
        font-size:4vw;
    }
    .ss_b02_tx2
    {
        font-size:5vw;
    }
    .ss_b02_ln1
    {
        font-size:4vw;
    }
    .ss_b03_tx1
    {
        font-size:6vw;
    }
    .ss_b05 .flex
    {
        flex-wrap:wrap;
    }
    .ss_b05 .col0,
    .ss_b05 .col3,
    .ss_b05 .col2
    {
        width:100%;
        margin-bottom:2vw;
    }
    .ss_b05 .col2
    {
        text-align:center;
    }
    .ss_b05_tx1
    {
        font-size:4vw;
    }
    .pv_b04
    {
        font-size:3vw;
        margin-bottom:5vw;
    }
    .vi_b05_b1 .flex
    {
        display:block;
    }
    .vi_b05_b1 .col2
    {
        width:20vw;
        float:left;
    }
    .vi_b05_b1 .col10
    {
        width:100%;
    }
    .pv_b03
    {
        margin-bottom:4vw;
    }
    .pv_b04
    {
        padding-bottom:4vw;
    }
    .pv_b05_tx1
    {
        margin:3vw 0;
        font-size:4vw;
    }
    .vi_b05_b1
    {
        padding:8vw 5vw;
    }
    .vp_b03_parent,
    .lk_b02_parent
    {
        width:100%;
    }
    .lk_b01 .flex
    {
        flex-wrap:wrap;
    }
    .lk_b02_tx1
    {
        font-size:5vw;
    }
    .lk_b02_tx4
    {
        font-size:4vw;
    }
    .lk_b02_tx5,
    .lk_b02_tx6
    {
        font-size:3.8vw;
    }
    .vi_b05_ava
    {
        width:100%;
    }
    .lk_b02_tx2
    {
        font-size:2.5vw;
    }
    .btn4
    {
        font-size:3.5vw;
    }
    .lk_b02_tx10,
    .lk_b02_tx11
    {
        font-size:3.5vw;
        margin:0;
    }
    .lk_b02_b8 .flex1
    {
        margin:2vw 0;
        align-items:center;
    }
    .lk_b02_tx20
    {
        font-size:4vw;
        margin-bottom:1.5vw;
    }
    .lkz_b02_tx20
    {
        font-size:3.5vw;
    }
    .lk_b02_tx20_btn
    {
        cursor:pointer;
    }
    .lk_b02_tx20_btn:after
    {
        content:'';
        border-top:2vw solid #404883;
        border-left:1vw solid transparent;
        border-right:1vw solid transparent;
        display:inline-block;
        vertical-align:middle;
        margin-left:3vw;
    }
    .lk_b02_tx20_hide
    {
        display:none;
    }
    .lk_b02_b8
    {
        margin-bottom:3vw;
    }
    .lkz_b02_b12
    {
        margin-bottom:3vw;
        padding-bottom:2vw;
    }
    .lkz_b02_b25
    {
        margin:3.5vw 0 0;
        padding:3vw 0 3vw;
    }
    .lkz_b05_b1
    {
        padding:4vw 6vw;
        font-size:4vw;
        margin:3vw 0;
    }
    .lkz_b02_tx21,
    .lk_b02_tx21
    {
        font-size:3vw;
    }
    .lkz_b02_tx21
    {
        padding-left:2vw;
    }
    .vp_b03_tabs_ul a
    {
        font-size:2.3vw;
        margin:0 1vw;
        padding-bottom:4vw;
    }
    .vp_b03_b2
    {
        padding:35px 0 4vw 0;
    }
    .lkz_b01 .col4
    {
        width:100%;
    }
    .vi_b05_b7 img
    {
        width:100%;
    }
    .dop_spec {
        font-size: 22px;
    }
    .lkz_b10_tx1
    {
        font-size:3vw;
    }
    .st_file_field
    {
        font-size:3vw;
    }
    .vp_b03_vt_tx5
    {
        font-size:2.5vw;
    }
    .lkz_b10_b5_v3 .col0
    {
        width:25%;
        padding:0 1vw;
        margin-bottom:4vw;
    }
    .lki_b10_b01 img
    {

    }
    .lkz_hd1_b02_tx1
    {
        font-size:4vw;
    }
    .lkz_hd1_b02_b2 .flex
    {
        flex-wrap:wrap;
    }
    .lkz_hd1_b02_b2 .col4
    {
        width:100%;
        margin-bottom:4vw;
    }
    .lkz_hd1_b02_b2 img
    {
        display:block;
        width:100%;
    }
    .lkz_hd1_b02_b3 a
    {
        font-size:3vw;
    }
    .lkz_hd1_b02_b3
    {
        text-align:center;
    }
    .lkz_hd1_b01,
    .lkz_hd1_b02,
    .lkz_b01
    {
        padding:4vw;
    }
    .vp_b03_b2_tb1 .col6
    {
        width:100%;
    }
    .vp_b03_b2_tb2_r1_tx1 a
    {
        font-size:3.5vw;
    }
    .review-body
    {
        font-size:3vw;
        line-height:4vw;
        min-height: 20vw;
    }

    .review-body-short {
        max-height: 20vw;
    }

    .vp_b03_b2_tb2_r1_plus,
    .vp_b03_b2_tb2_r1_minus
    {
        width:5vw;
        height:5vw;
        line-height:4.5vw;
        font-size:4vw;
        right:3vw;
        top:3vw;
    }
    #vp_b03_tabs_t3,
    #vp_b03_tabs_t4
    {
        flex-wrap:wrap;
    }
    .vp_b03_vt
    {
        width:100%;
        padding:4vw 0;
    }
    .vp_b03_vt_ul, .vp_b03_b2_vt_ul
    {
        max-width:100%;
    }
    .vp_b03_vt_ul a, .vp_b03_b2_vt_ul a
    {
        font-size:4vw;
        padding:3vw 5vw;
    }
    .vp_b03_vt_ul a:after, .vp_b03_b2_vt_ul a:after
    {
        border-left:2vw solid #000;
        border-top:1vw solid transparent;
        border-bottom:1vw solid transparent;
    }
    .vp_b03_vt_ul a.selected:after, .vp_b03_b2_vt_ul a.selected:after,
    .vp_b03_vt_ul a.active:after, .vp_b03_b2_vt_ul a.active:after
    {
        border-left:2vw solid #fff;
    }
    .lkz_b10_tx15
    {
        font-size:2.5vw;
    }
    .sz_b12_tx2
    {
        font-size:3vw;
        line-height:4vw;
    }
    .mp_tx1,
    .md_tx1
    {
        font-size:4vw;
        margin-bottom:1vw;
    }
    .mp_b2
    {
        font-size:2.5vw;
    }
    .modal_dobavit .col6
    {
        width:100%;
    }
    .modal_dobavit .flex
    {
        flex-wrap:wrap;
    }
    .modal_popoln_in
    {
        max-width:100vw;
    }
    .mp_tx3
    {
        font-size:3vw;
    }
    .modal .st_textarea
    {
        font-size:3vw;
    }
    .md_head
    {
        top:-14vw;
    }
    .vp_b03_b2_hb1_r1 .col3, .vp_b03_b2_hb1_r1 .col4, .vp_b03_b2_hb1_r1 .col5
    {
        width:100%;
    }
    .vp_b03_b2_hb1 a
    {
        border-bottom: 1px solid black;
        margin-left: 4px;
        margin-right:0;
        font-size :3vw;
        letter-spacing:0;
    }
    .vp_b03_b2_hb1
    {
        justify-content:space-between;
        /*display:flex;*/
    }
    .lk_b02_tx8
    {
        font-size:4vw;
    }
    .lk_b02_b5
    {
        font-size:3vw;
        line-height:4vw;
        margin-bottom:4vw;
    }
    #vp_b03_b2_tb3,
    #vp_b03_b2_tb4
    {
        flex-wrap:wrap;
    }
    .spz_b01_b2 .col3
    {
        width:100%;
    }
    .kf_b07_iw
    {
        max-width:45vw;
        bottom:-22vw;
    }
    .kf_b07_b2,
    .spz_b016 .kf_b07_b2
    {
        padding:23vw 4vw 5vw;
    }
    .spz_b01_b2_tx1 a
    {
        font-size:5vw;
    }
    .spz_b01_b2_tx2,
    .spz_b01_b2_tx3
    {
        font-size: 13px;
    }
    .spz_b01_b2_tx4
    {
        font-size:4vw;
    }
    .spz_b01_b1_tx1
    {
        margin-bottom:5vw;
        font-size:7vw;
    }
    .sz_b05 > .flex > .col4
    {
        margin-bottom:5vw;
    }
    .sz_b05 > .flex > .col4,
    .sz_b05 > .flex > .col8
    {
        width:100%;
        flex:none;
    }
    .sz_b05 > .flex
    {
        flex-wrap:wrap;
    }
    .sz_b02_step,
    .sz_b02_st_active.sz_b02_st_nx_active
    {
        display:none;
    }
    .sz_b02_st_active
    {
        display:block;
    }
    .sz_b02_st_active .sz_b02_tx1,
    .sz_b02_st_active .sz_b02_tx2
    {
        display:block;
        text-align:center;
    }
    .sz_b02_tx1
    {
        font-size:5vw;
    }
    .sz_b02_tx2
    {
        font-size:3vw;
        line-height:3.3vw;
    }
    .sz_b02_mob
    {
        display:flex;
        justify-content:space-between;
    }
    .sz_b02
    {
        padding:4vw;
    }
    .sz_b02_nm1
    {
        position:relative;
        z-index:2;
        font-size:6vw;
        line-height:8.8vw;
        height:10vw;
        width:10vw;
        border:.6vw solid #c8c8c8;
    }
    .sz_b02_nm1:after
    {
        content:'';
        position:absolute;
        display:block;
        z-index:1;
        height:.6vw;
        width:10vw;
        top:50%;
        left:101%;
        margin-top:-.3vw;
        background:#c8c8c8;
    }
    .sz_b02_nm1_last:after
    {
        display:none;
    }
    .sz_b02_step .sz_b02_nm1,
    .sz_b02_step:after,
    .sz_b02_step:before
    {
        display:none;
    }
    .sz_b02_step
    {
        padding:4vw;
    }
    .sz_b02_nm1_act1
    {
        border-color:#3e4b81;
        color:#3e4b81;
    }
    .sz_b02_nm1_act1:after
    {
        background:#3e4b81;
    }
    .sz_b02_nm1_act2
    {
        border-color:#39537c;
        color:#39537c;
    }
    .sz_b02_nm1_act2:after
    {
        background:#2b6b70;
    }
    .sz_b02_nm1_act3
    {
        border-color:#2b6b6f;
        color:#2b6b6f;
    }
    .sz_b02_nm1_act3:after
    {
        background:#1a8560;
    }
    .sz_b02_nm1_act4
    {
        border-color:#1a8561;
        color:#1a8561;
    }
    .sz_b02_nm1_act4:after
    {
        background:#0c9c53;
    }
    .sz_b02_nm1_act5
    {
        border-color:#0b9d54;
        color:#0b9d54;
    }
    .sz_b02_nm1_act5:after
    {
        background:#06a54e;
    }
    .sz_b02_nm1_act6
    {
        border-color:#01ad4a;
        color:#01ad4a;
    }
    .sz_b09_tx2
    {
        font-size:3.2vw;
    }
    .sz_b09 .col2
    {
        float:left;
    }
    .sz_b09 .col10
    {
        width:100%;
    }
    .sz_b09_tx1
    {
        font-size:5vw;
        margin-bottom:5vw;
    }
    .sz_b10_tx1
    {
        font-size:3.5vw;
        line-height:4vw;
        margin-bottom:3vw;
    }
    .sz_b10_tx2
    {
        font-size:2.6vw;
        line-height:3vw;
        margin-bottom:3vw;
    }
    .sz_b10_b6 .green, .sz_b10_b6 .green-outline
    {
        padding:2.5vw 5vw;
        font-size:3.5vw;
        text-align:center;
        white-space:normal;
        margin-top:2vw;
        margin-right:20px;
    }
    .sz_b10_b6 p
    {
        font-size:3vw;
        margin:3vw 0;
    }
    .sz_b11_tx1
    {
        font-size:3vw;
    }
    .sz_b11_tx2
    {
        font-size:3.5vw;
    }
    .st_input_file1 + label
    {
        padding:3vw 5vw 3vw 6.5vw;
        background:url(../../../public/img/sz_img3.png) no-repeat 2vw 3.2vw / 3vw 3vw, #e6e6e6;
        text-transform:uppercase;
        font-size:3vw;
    }
    .sz_b13_tx1
    {
        font-size:3.5vw;
    }
    .sz_b13_tx2
    {
        font-size:2.5vw;
    }
    .mcz_head
    {
        font-size:4vw;
    }
    .mcz_tx4
    {
        font-size:3.5vw;
    }
    .mcz_b03 .flex
    {
        flex-wrap:wrap;
    }
    .mcz_b03 .col7,
    .mcz_b03 .col3,
    .mcz_b03 .col2
    {
        width:100%;
        text-align:center;
    }
    .mcz_tx1
    {
        font-size:3vw;
        margin-bottom:4vw;
    }
    .st_checkbox + label:before
    {
        background:url(../../../public/img/chb_empty.png) no-repeat center / 100%;
        width:4vw;
        height:4vw;
    }
    .st_checkbox + label
    {
        padding-left:6vw;
    }
    .mcz_tx8,
    .mcnz_tx8
    {
        font-size:3vw;
    }
    .mcnz_tx10
    {
        font-size:3vw;
        margin-top:3vw;
    }
    .sz_b10_tx3
    {
        font-size:3vw;
        line-height:3.5vw;
    }
    .pcz_tx1
    {
        font-size:3.5vw;
        margin-bottom:5vw;
    }
    .pcz_tx2 p
    {
        font-size:3vw;
        margin-bottom:2vw;
    }
    .pcz_tx3
    {
        font-size:2.5vw;
    }
    .pcz_tx5,
    .pcz_tx5 .link1
    {
        font-size:2.5vw;
    }
    .sz_b10_g8 p, .sz_b10_g8 a
    {
        font-size:3vw;
    }
    .sz_b10_b6_sb1
    {
        width:100%;
        text-align:center;
    }
    .sz_b10
    {
        margin:3vw 0;
        /*padding:3vw 4vw 3vw 10vw;*/
        /*background:url(../../../public/img/sz_img1.png) no-repeat 2vw 4vw / 7vw;*/
        padding:3vw 4vw 3vw 4vw;
    }
    .pez_tx8
    {
        font-size:2.6vw;
    }
    .pez_tx1
    {
        font-size:3vw;
        line-height:3.4vw;
        margin:3vw 0;
    }
    .msa_tx1
    {
        font-size:2.6vw;
    }
    .mcz_b7
    {
        margin-top:5vw;
    }
    .radio#positive_0 + label, .radio#platformPositive_0 + label
    {
        font-size:2.5vw;
        margin-right:3vw;
    }
    .radio#positive_1 + label, .radio#platformPositive_1 + label
    {
        font-size:2.5vw;
    }
    .mez_tx3
    {
        font-size:3vw;
        margin:1vw 0 3vw;
    }
    .mez_tx8,
    .mez_tx5
    {
        font-size:3vw;
        line-height:3.5vw;
    }
    .kf_b07_b2_tx3
    {
        font-size:3.2vw;
    }
    .kf_b07_b2_tx1 a
    {
        font-size:3.4vw;
    }
    .kf_b07_b2_tx2
    {
        font-size:3vw;
    }
    .fs-dropdown-selected
    {
        padding:15px 6vw 15px 2vw;
    }
    .gz_b02_b3
    {
        margin-bottom:4vw
    }
    .ef_b02
    {
        background:none;
    }
    .ef_b02_in1
    {
        background:url(../../../public/img/ef_bg1.png) no-repeat left bottom / contain;
    }
    .ef_b01 h1
    {
        margin:5vw 0;
    }
    .ef_b01_b1
    {
        margin-bottom:5vw;
    }
    .sz_b11_b1
    {
        text-align:center;
    }
    .sz_b12_b2
    {
        margin-bottom:3vw;
        margin-left:10vw;
    }
    .psp_b01
    {
        padding:4vw 2vw;
    }
    .ps_b01 .col1
    {
        padding-right:0;
        width:17vw;
    }
    .ps_b01_tx1
    {
        font-size:6vw;
    }
    .psp_b01_tx1
    {
        font-size:4vw;
        line-height:5vw;
    }
    .psp_b01_tx4
    {
        font-size:3vw;
    }
    .lk_b01 .flex.lkz_b10_b5_v3
    {
        flex-wrap:nowrap;
        margin:0 -1vw;
    }
    .text-center-mob
    {
        text-align:center;
    }
    .lkz_b10_b5 .col3
    {
        margin-bottom:20px;
    }
    .pvv_b01_tx1
    {
        font-size:5vw;
    }
    .pvv_b01_tx1 span
    {
        font-size:3vw;
    }
    .vp_b03_b1
    {
        margin-bottom:5vw;
    }
    .vp_b03_b3_tx1
    {
        font-size:4vw;
    }
    .kf_b07
    {
        margin-bottom:10vw;
    }
    .kf_b07_b1
    {
        /*margin-top:-15vw;*/
    }
    .spz_b01_b11
    {
        padding:7vw 0 0;
    }
    .spz_b016
    {
        padding:7vw 0;
    }
    .sz_b11_b1_sp2
    {
        justify-content:center;
    }
    .sz_b11_b2
    {
        text-align:center;
    }
    .sz_b11_b1_sp2 p,
    .sz_b11_b1_sp2 .link1
    {
        font-size:2.6vw;

    }
    .sz_b11_b1_sp2 .btn2
    {
        width:auto;
        padding:3vw 5vw;
    }
    .sz_b11_b1_sp4
    {
        justify-content:center;
    }
    .sz_b11_b1_last
    {
        width:100%;
    }
    .sz_b10_b6
    {
        justify-content:center;
    }
    .sz_b11_tx8
    {
        font-size:3vw;
    }
    .sz_b10_b6 .green.sz_b10_g6_btn2
    {
        padding:2.5vw 1vw;
    }
    .sz_b10_g6_left
    {
        margin-left:-7vw;
    }
    .sz_b10_tx3.sz_b10_g6_left
    {
        padding:4px 0 0 7vw;
        background:url(../../../public/img/sz_img2.png) no-repeat 2vw top / 4vw;
    }
    .sz_b10_b6 .green.sz_b10_g6_btn2_1
    {
        padding:2.5vw 2vw;
    }
    .sz_b10_g8
    {
        justify-content:center;
        text-align:center;
    }
    .sz_b11_b1_sp2 p
    {
        font-size:2.6vw;
    }
    .np_b01 .flex
    {
        flex-wrap:wrap;
    }
    .np_b01 .col9,
    .np_b01 .col3
    {
        width:100%;
    }
    .np_b01_b2 .st_textarea
    {
        font-size:4vw;
    }
    .np_b01_b2_tx1
    {
        font-size:4vw;
    }
    .np_b01_b2_tx3
    {
        margin-top:15px;
        display:block;
    }
    .np_b01_b2 .col12
    {
        max-width:100%;
    }
    .st_checkbox:checked + label:before
    {
        background:url(../../../public/img/chb_fill.png) no-repeat center / 100%;
    }
    .np_b01_b2 .st_checkbox + label:before
    {
        width:4vw;
        height:4vw;
    }
    .np_b01_b2 .st_checkbox + label:before
    {
        top:0.5vw;
    }
    .np_b01_b2_tx8
    {
        margin-right:2vw;
    }
    .zpv_lnk1
    {
        margin-top:4vw;
    }
    .prp_b02_tx1
    {
        margin:4vw;
        font-size:4vw;
    }
    .zpv_bwr
    {
        padding:5vw 0;
    }
    .btn8
    {
        text-align:center;
    }
    .of_b2
    {
        padding:10vw 0;
    }
    .of_b24
    {
        padding:10vw 0 0;
    }
    .of_b1 .col3
    {
        width:100%;
    }
    .of_b3
    {
        margin-bottom:0;
    }
    .szn_b01_tx1
    {
        font-size:3vw;
    }
    .pvi_b01 .btn2,
    .pvi_b01 .btn10
    {
        font-size:3.5vw;
        padding:3vw 5vw;
        white-space:normal;
    }
    .pvi_b01 p
    {
        margin:1vw 0;
        font-size:3.5vw;
    }
    .pvi_b01_tx1,
    .pvi_b01_tx1 .link1
    {
        font-size:3vw;
    }
    .pkz_b02
    {
        padding:4vw 0;
    }
    .pkz_b01_tx1
    {
        background:url(../../../public/img/sz_img2.png) no-repeat left center;
        padding-left:7vw;
    }
    .tx_25_53 {
        font-size: 4.8vw;
        line-height: 6.8vw;
    }

    .err_b01_tx1 {
        width: 100%;
    }

    .err_bg1 {
        background-size: auto 90%;
    }
}
@media screen and (max-width:680px)
{
    .gz_b05 .col3
    {
        width:50%;
    }
    .gz_b05_b01
    {
        height: 290px;
    }
    /*.gz_b05_tx2 {*/
    /*    font-size: 14px;*/
    /*}*/
    .gz_b05_tx8, .gz_b05_tx2
    {
        /*font-size:6.5vw;*/
    }

    .pvi_b01_tx1 .link1
    {
        display:block;
    }
    .pvi_b01_tx1
    {
        margin-bottom:2vw;
    }
    .pvi_thead
    {
        flex-wrap:wrap;
    }
    .sz_b09
    {
        padding:4vw 3vw;
    }
    .zs_tx1
    {
        margin:3vw 0;
    }
}
@media screen and (max-width:600px)
{
    .top_mn_ispoln li
    {
        padding-left:0;
    }

    .lp_b01_b1 {
        margin: 0 5px;
    }
}
@media screen and (max-width:520px)
{
    .st-desciplines .category-image {
        flex: 1 1 35%;
    }
    .popup-button {
        max-width: 280px;
    }
    .category-image {
        width: 50%;
    }
    .seo-buttons a{
        width: 100%;
    }
    .auth-buttons button {
        font-size: 14px;
    }
    .mb-p-3 {
        padding-left: 0;
        width: 50vw;
    }

    .kf_b07_wrap
    {
        padding:0;
    }
    .sz_b10_b6 a
    {
        letter-spacing:0;
    }
    .sz_b10_b6_nw1
    {
        white-space:nowrap;
    }
}
@media screen and (max-width:500px)
{
    .hide_500 {
        display: none !important;
    }
    .hd_message {
        margin-right: 13px;
    }
    .ar_b04_b1
    {
        background:url(../../../public/img/ar_check.png) no-repeat 5vw 7vw / 9vw;
        padding:5vw 0 5vw 17vw;
    }
    .vi_b05_b1 .col2
    {
        width:25vw;
    }
    .vi_b05_b1_mob .col2
    {
        width:26vw;
        padding:0 0 0 15px;
    }
    .logo_wrap .dropdown_wrap
    {
    }
}

@media screen and (max-width:1230px) and (min-width: 1100px)
{
    .freelancer-list-item .tns-inner {
        width: auto;
    }
    .freelancer-list-item {
        width: calc(50% - 10px);
        padding: 10px;
    }
}

@media screen and (max-width:1000px)
{
    .freelancer-list-item .tns-inner {
        width: auto;
    }
    .freelancer-list-item {
        width: 100%;
    }
}
@media screen and (max-width:820px) and (min-width: 600px)
{
    .freelancer-list-item .tns-inner {
        width: auto;
    }
    .freelancer-list-item {
        width: calc(50% - 10px);
        padding: 10px;
    }
}

@media screen and (max-width:768px)
{
    .pc-flex-center-mb-start {
        justify-content: center;
    }
    .justify-content-center-mb {
        justify-content: center;
    }
 

    .w768 {
        min-width: 550px !important;
    }
    .filepond--drop-label label {
        font-size: 2.5vw !important;
    }
}
@media screen and (max-width: 550px) {
    .w768 {
        max-width: 90vw !important;
        min-width: 0 !important;
    }
}
@media screen and (max-width:380px)
{
    .hd_message {
        margin-right: 15px;
    }
    .filepond--drop-label label {
        font-size: 3.6vw !important;
    }

    .hd_rt_btns {
        margin-bottom: 15px;
    }

    .gz_b04_tx7 {
    }

    .gz_b03_in .btn2 {
        margin-left: -12px !important;
    }
}

@media screen and (max-width:768px)
{
    .vp_b03_b2 .vp_b03_tabs .flex1 .vp_b03_b2_vt_ul a,
    .vp_b03_b2 .vp_b03_tabs .flex1 .vp_b03_vt_t1 .vp_b03_b4
    {
        font-size: 20px !important;
    }

    #snackbar_text
    {
        font-size: 17pt;
    }

    .ps_b03_tx6
    {
        width: 60%;
        margin-left: 20%;
    }

    .form-select-specialty__box option
    {
        font-size: 14px;
    }

    .header
    {
        z-index: 90;
    }

    .vi_b05_b3
    {
        font-size: 13px;
    }

    .vi_b01 .vi_b01_b3,
    .spz_b01 .vi_b01_b3
    {
        margin: 30px 0;
    }

    #project_search .field-row p a
    {
        font-size: 4vw;
    }

    .lp_b01_b2.text-center
    {
        margin-top: 3vw;
    }

    .dropdown_menu .top_mn_ispoln li a
    {
        font-size: 14px !important;
        padding: 0 !important;
    }

    .gz_b01_b2
    {
        margin-top: 0;
    }

    .settings-specs .specs-tabs {
        padding-bottom: 4vw;
    }

    .settings-specs .specs-tabs a {
        font-size: 2.6vw;
        padding: 3vw 4vw;
    }

    .settings-specs .category {
        font-size: 3.4vw;
    }
}

@media screen and (max-width:600px)
{
    #snackbar_text
    {
        font-size: 12pt;
    }
    .vp_b03_b2 .vp_b03_tabs .flex1 .vp_b03_b2_vt_ul a,
    .vp_b03_b2 .vp_b03_tabs .flex1 .vp_b03_vt_t1 .vp_b03_b4
    {
        font-size: 13px !important;
    }
}

@media screen and (max-width:768px)
{
    .sidebar .specializations .header2 {
        font-size: 3.8vw;
        margin-bottom: 1.2vw;
    }

    .ft_tx5_mob
    {
        margin-top: 15px;
    }

    .ft_tx5_mob li {
        margin-bottom: 10px;
    }

    .ft_tx5_mob a {
        color: #616161;
        font-size: 12px;
    }

    .modal_paint_small-and-shadow
    {
        width: 400px;
    }

    .form_button
    {
        /*margin: 20px auto 10px;*/
    }

    .create-account-worker-popup__block-2button>button
    {
        padding: 10px;
    }

    .create-account-worker-popup__verificationcode .field-label.label.forms-block-inline__label.required {
        margin: auto auto 15px;
    }

    #multistep_modal select
    {
        font-size: 14px !important;
        max-width: 335px !important;
        text-overflow:ellipsis
    }



    .category_modal
    {
        left: 7px;
        width: 500px;
    }

    .bz_img
    {
        object-fit: cover;
        width: 100%;
    }

    .search_in_catalog_img
    {
        object-fit: cover;
        width: 100%;
    }

    .gz_b02_tx1
    {
        margin-top: 28px;
        margin-bottom: 0;
    }

    .gz_b04
    {
        padding: 0 0 10px;
    }

    .gz_b05_tx1
    {
        margin-bottom: 25px;
    }

    .gz_b05
    {
        padding: 15px 0 0;
    }

    .gz_b05_b07 {
        margin-top: 20px;
    }
    .gz_b06
    {
        padding: 40px 0 40px;
    }

    .gz_b09 {
        padding: 0px 0 35px;
    }
    .btn2
    {
        font-size: 0.9rem;
        padding: 1em 2em;
    }

    .text-right
    {
        text-align: left !important;
    }

    .lp_b01 {
        padding: 20px 0 20px;
    }

    .on_b04
    {
        padding-bottom: 0px;
    }

    .op_b3_tx4
    {
        margin: 40px 0;
    }

    .bs_b04_tx1
    {
        padding: 0 0 25px;
    }

    .bs_b05 {
        padding: 40px 0;
    }

    .bs_b05 {
        padding: 25px 0;
    }

    .bs_b04_img8
    {
        object-fit: cover;
        width: 100%;
    }

    .pp_b01
    {
        padding-bottom: 0;
    }
    .ar_b04_tx1_mob{
        font-size: 4.5vw;
    }

    .ar_mob{
        display: none;
    }

    .ar_b06
    {
        padding: 0 0 60px;
    }

    .ef_b02_in2_tx1
    {
        margin: 30px 0 30px;
    }

    .ef_b02_in_tx1
    {
        margin: 50px 0 60px;

    }

    .rf_b02_tx3
    {
        margin: 30px 0 30px;
    }

    .rf_b06
    {
        padding: 1px 0 40px;
    }

    .ft_tx5_mob a
    {
        font-size: 23px;
        line-height: 34px;
    }

    .btn2, .btn3, .btn6, .btn7, .btn8, .btn9 {
        font-size: 1.2rem !important;
        padding: 1em 2em;

    }
    #btn_pred {
        display: block;
        margin: 0 auto;
    }
    .gz_b04_b4, .gz_b04_tx2, .gz_b04_tx3, .gz_b04_tx4
    {
        font-size: 3vw !important;
    }

    .gi_page .gz_b03 {
        padding: 50px 0 40px;
    }

    .dropdown_menu .top_mn_ispoln li a
    {
        margin-top: 6px;
        font-size: 20px;
    }

    .field-control
    {
        font-size: 20px !important;
    }

    .form_content_2col .label.label_type_privacy-policy-checker {
        font-size: 12px !important;
    }

    .mb-5 {
        font-size: 17px;
    }

    }

@media screen and (max-width: 600px){
    .mb-p-3 {
        padding-left: 0;
        width: 50vw;
    }
    .favorite + label {
        top: 3vw;
    }
}

@media screen and (max-width: 658px){

    .vi_b01_b3_in .btn2 {
        margin-bottom: 15px;
    }
}
@media screen and (max-width:769px)
{
    .mb-mt-3 {
        margin-top: 10vw;
    }
    .gi_page .gz_b01 {
        margin-bottom: 0;
        margin-top: 0;
    }
    .np_b01
    {
        padding: 0 0 50px;
    }
}
@media screen and (min-width:769px)
{
    .ft_tx5_mob
    {
        display: none;
    }
}
@media screen and (min-width:1600px) {
    .slider-wrap {
        max-width: 26rem;
    }
}

@media  screen and (max-width: 1356px) {
    .hdTariff  {
        display: none;
    }
}

@media screen and (min-width:550px) {

    .slider-wrap {
        max-width: 50vw;
        width: 50vw;
    }
}


@media screen and (min-width:768px) {
    .slider-wrap {
        max-width: 40vw;
        width: 36vw;
    }
    .marked-ul li, .marked-list li {
        font-size: 24px;
        margin-top: 40px;
    }
    .marked-ul li:first-of-type:before, .marked-ul li:nth-child(2):before, .marked-ul li:nth-child(3):before, .marked-list li:before {
        background-size: 50px;
        margin-right: 50px;
    }
    .btn-orange {
        min-height: 70px;
    }
    .ds768 {
        display: none;
    }
}
@media screen and (max-width:425px) {

    input.flatpickr {
        background-size: 17px !important;
    }
    .gz_b05_tx3 li a
    {
        font-size:5.4vw;
    }
    .gz_b05_tx8, .gz_b05_tx2
    {
        font-size:16px;
    }
    .gz_b05 .col3
    {
        width:100%;
    }
    .ps_b02_b3 p, .ps_b02_b3 span {
        font-size: 12px !important;
    }
    .ps_b02_b4 h3, .ps_b02_b4 span, .ps_b02_b2 p {
        font-size: 18px !important;
    }
    .ps_b02_b2 span {
        font-size: 14px !important;
    }
    /*#search-fl {*/
    /*    font-size: 12px;*/
    /*}*/
    .modal-adapter {
        max-width: 90vw;
    }
    .sum {
        font-size: 10px !important;
    }
    .md-tt {
        margin-right: 10px;
        font-size: 12px;
        width: 33%;
        margin-left: 5%
    }
}
@media screen and (max-width:768px) {
    .button-wrapper {
        width: 100%;
    }
    .button-wrapper button, .button-wrapper a {
        width: 100%;
    }
    .payment-proposal {
       flex-wrap: wrap;
    }
    .payment-proposal button {
        display: block;
        width: 80%;
        margin: 0 auto 10px;
    }
    .payment-proposal h3 {
        margin: 0 auto 10px;
    }
    .sum {
        font-size: 12px !important;
        padding: 10px !important;
    }
    .ds768 {
        display: block;
    }
}
@media screen and (min-width:550px) {

    .ds550 {
        display: none;
    }
    .hd550 {
        display: flex;
    }
}
@media screen and (max-width:550px) {
    .vp_b03_tabs_ul a {
        font-size: 12px;
    }
    .ds550 {
        display: flex;
    }
    .hd550 {
        display: none;
    }
}

@media screen and (max-width:425px)
{
    .lk_b02_tx10, .lkz_b02_tx21,.tx_14_20, .review-body, .vp_b03_b2_tb2_r1_tx1 a, .lk_b02_tx11, .lk_b02_tx10, .breadcrumbs li a  {
        font-size: 12px;
    }
    .modal {
        margin: 50px 20px;
    }

    .tx_38_53 a,
    .vp_b03_tabs_ul a,
    .lk_b02_tx20,
    .lk_b02_tx10, .lk_b02_tx11,
    .review-body-short,
    .vp_b03_b2_tb2_r1_tx1 a,
    .ps_b02_b6 .btn2,
    .lk_b02_tx5, .lk_b02_tx6,
    .ps_b02_tx2,
    .ps_b02_b2 p,
    .ps_b02_b2 span,
    .ps_b02_b3 p,
    .ps_b02_b3 span,
    .psp_b03 .link1,
    .ps_b02_b6 a,
    .breadcrumbs li, .breadcrumbs li a,
    .ft_tx1,
    .button-wrapper a
    {
        font-size:12px !important;
    }
    #tariff-service-modal {
        max-width: 90vw;
    }
    .favorite + label {
        top: 10px;
        right: 15px;
    }
    .pcz_tx2_in {
        width: 320px;
    }
    .flex-mobile {
        /*justify-content: center;*/
    }
    .mb-mt-3 {
        margin-top: 30px;
    }
    .save_text {
        font-size: 14px;
    }
    .gz_b01_b2 {
        margin-top: 9px;
    }
    .vi_b05_b4 {
        margin-bottom: 33px;
    }
    .tx_14_20
    {
        margin-bottom: 15px;
    }
}
@media screen and (max-width:375px)
{
    .mb-p-3 {
        padding-left: 0;
        width: 53vw;
    }
    .dop_spec {
        font-size: 14px;
    }
    .lkz_b10_tx1
    {
        font-size:4vw;
    }

    .dropdown_menu .top_mn_ispoln li a {
        margin-top: 0;
    }

    .top_mn_ispoln
    {
        max-width: 300px;
    }

}

@media screen and (max-width:425px)
{
    .title-link {
        max-width: 32vw;
    }
    .tx_14_20 {
        font-size: 12px;
    }
    .rf_b15 .btn2.buy_tariff
    {
        margin-right: calc(50% - 144px)
    }

    .ar_b05_tx1,
    .ar_b06_tx1
    {
        font-size:5.5vw;
        line-height:5.5vw;
        margin-bottom: 30px;
    }

    .ar_b06_tx1
    {
        margin-top: 15px;
    }

    .on_b03
    {
        padding: 0 0 5vw;
    }

    .button.button_color_white-green-border.create-account-worker-popup__button.prev-btn,
    .button.button_color_green.create-account-worker-popup__button.create-account-worker-popup__button-register
    {
        padding: 10px;
    }

    #multistep_modal
    {
        max-width: calc(100% - 40px);
    }

    .np_b01
    {
        padding: 0 0 20px;
    }

    #js-project-form .form-select-specialty__list,
    #js-project-form .form-select-specialty
    {
        margin: 0 !important;
    }

    .wizard-body .info-item .title
    {
        font-size: 20px !important;
        line-height: 20px !important;
    }

    .wizard-body h1
    {
        font-size: 1.4rem !important;
        margin-left: 0;
    }

    .wizard-body .info-item p
    {
        font-size: 14px !important;
    }

    #project_modal, #project_modal2
    {
        margin-top: 80px;
    }

    .lp_b01_b2.text-center .link1
    {
        font-size: 12px;
    }

    #snackbar.show
    {
        max-width: calc(100% - 20px);
    }

    .lp_b01_b2.text-center .btn2
    {
        padding: 15px 50px;
    }

    .freelancers-header-with-button
    {
        margin-top: 10px;
    }

    .spz_b015
    {
        padding: 10px 0 20px;
    }

    .vi_b01_b3
    {
        margin: 30px 0;
    }

    .on_b02_in1
    {
        padding: 0;
    }

    .bs_b05_tx1
    {
        margin-bottom: 20px;
    }

    .bs_b02_b1
    {
        padding-bottom: 0 !important;
    }

    .on_b02_abs.on_b02_abs5.text-right
    {
        margin-bottom: 0 !important;
    }

    .gz_b04_b4, .gz_b04_tx2, .gz_b04_tx3, .gz_b04_tx4
    {
        font-size: 3.6vw !important;
    }

    .ft_tx5_mob a
    {
        font-size: 15px;
        line-height: 22px;
    }

    .gz_b01
    {
        padding:35vw 0 5vw;
    }

    .gz_b01_fr {
        padding:25vw 0 5vw !important;

    }
    .gz_b01 .freelancers-main {
        padding-bottom:95vw !important;
    }
    .gz_b01 .col8
    {
        padding-bottom:90vw;
    }

    .btn2, .btn3, .btn6, .btn7, .btn8, .btn9 {
        font-size: 1rem !important;
    }
    .btnSize {
        font-size: 0.8rem!important;
    }
    .st_checkbox2+label {
        font-size: 15px;
    }
    .field-control
    {
        font-size: 12px !important;
    }

    .fs-dropdown *, .kf_b03_tx1
    {
        font-size:4vw;
    }

    .vi_b05_b1_mob
    {
        width: auto;
        margin-left: 0;
    }

    .category_modal
    {
        width: 350px;
    }

    .mb-5 {
        font-size: 14px;
    }

}
@media screen and (max-width:425px)
{
    .gz_b06_tx2,
    .gz_b04_tx8
    {
        font-size:14px;
    }
    .vp_b03_b4_tx3, .vp_b03_b4_tx3 h2
    {
        margin-right: 10px;
    }
}
