.container{
    margin:0 auto;
    max-width:1230px;
    padding:0 15px;
}
.container_w{
    margin:0 auto;
    max-width:1600px;
}
.flex{
    display:flex;
    margin:0 -15px;
}
.flex1{
    display:flex;
}
@media screen and (max-width:425px) {
    .flex-uniq{
        margin-left: 26px;
    }
}
.flex-wrap{
    flex-wrap:wrap;
    word-wrap: break-word;
}
.flex-v-center{
    align-items:center;
}
.flex-v-end{
    align-items:flex-end;
}
.flex-end{
    justify-content:flex-end;
}
.flex-center{
    justify-content:center;
}
.flex-justify{
    justify-content:space-between;
}
.col0, .col1, .col2, .col3, .col4, .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12{
    padding:0 15px;
}
.col1{
    width:8.33%;
}
.col2{
    width:28.66%;
}
@media screen and (max-width:768px) {
    .col7, .col6 {
        padding: 0 30px;
    }
}
@media screen and (max-width:425px)
{
    .col2{
        width:40.66%;
    }
}
.col3{
    width:25%;
}
@media screen and (max-width:425px)
{
    .col3{
        width:29%;
    }
}
.col4{
    width:33.33%;
}
.col5{
    width:41.66%;
}
.col6{
    width:50%;
}
.col7{
    width:58.33%;
}
.col8{
    width:66.66%;
}
.col9{
    width:75%;
}
.col10{
    width:83.33%;
}
.col11{
    width:91.66%;
}
.col12{
    width:100%;
}
.text-center{
    text-align:center;
}
.text-right{
    text-align:right;
}
.hidden{
    display:none;
}
.nowrap{
    white-space:nowrap;
}