.label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    display: block;
}
.formField,.label {
    margin-bottom: 20px;
}
.select {
    position: relative;
    &-normal {
        .select__option,.select__input  {
            padding: 18px;
        }
    }
    &-small {
        .select__option,.select__input  {
            padding: 5px 10px;
        }
    }
    &__head {
        position: relative;
        &__opened {
            .select__input {
                border-radius: 5px 5px 0 0;
            }
            .select__toggle-icon {
                transform:  translateY(-50%) rotateZ(180deg);
            }
        }
    }
    &__input {
        border-radius: 5px;
        border: 1px solid #A0A4C2;
        width: 100%;
        height: 100%;
        &::placeholder {
            color: #A0A4C2;
        }
        &:disabled {
            background: initial;
        }
    }
    &__options {
        background: white;
        left: 0;
        right: 0;
        z-index: 12;
        position: absolute;
        border-radius: 0 0 5px 5px;
        border-bottom: 1px solid #A0A4C2;
        border-left: 1px solid #A0A4C2;
        border-right: 1px solid #A0A4C2;
    }
    &__option,&__input  {
        color: black;
        font-weight: 500;
        font-size: 16px;
    }
    &__option {
        &:hover {
            cursor: pointer;
            background-color: #DDDEE9;
        }
    }

}

.select__toggle-icon {
    transition: all .3s;
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
}
.select__toggle-icon__open {
    transform:  translateY(-50%) rotateZ(180deg);
}