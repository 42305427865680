$white: #fff;
$black: #000;
$dark: red;
$gray: red;
$red: blue;
$green: red;
$yellow: red;
$blue: red;

$fc_white: $white;
$fc_dark: $dark;
$fc_red: $red;
$fc_blue: $blue;
$fc_green: $green;
$fc_yellow: $yellow;

// bp 1500, 1200, 992, 768
@mixin media($bp-size) {
  @media (max-width: $bp-size + px) {
    @content; } }

@mixin media_min($bp-size) {
  @media (min-width: $bp-size + 0.1 + px) {
    @content; } }

@mixin hover {
  @include media_min(1200) {
    @content; } }

$imgPath: '../images/';

@mixin bgImg($img) {
  background-image: url($imgPath + $img); }

@mixin bgIcon($img, $w: 100%, $h: 100%) {
  background-image: url($imgPath + $img);
  background-size: $w $h;
  background-repeat: no-repeat;
  background-position: center center; }

@mixin cont {
  margin: 0 auto;
  max-width: 1442px;
  padding-left: 16px;
  padding-right: 16px;
  @include media(1500) {
    max-width: 1140px; }
  @include media(1200) {
    max-width: 960px; }
  @include media(992) {
    max-width: 720px; }
  @include media(768) {
    max-width: 540px; } }

@mixin title {
  letter-spacing: 0.02em;
  font-size: 40px;
  line-height: 59px;
  @include media(1200) {
    font-size: 25px;
    line-height: 36px; } }
