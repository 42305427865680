//стили используемые только в разделе пошагового создания заказа
.wizard-container {
  @media screen and (max-width: 768px) {
    &.flex {
      flex-wrap: wrap;
    }

    > .col4, > .col8 {
      width: 100%;
      flex: none;
    }
  }

  @media screen and (max-width: 320px) {
    > .col4, > .col8 {
      padding: 0 7px;
    }
  }
}

.wizard-body {
  .field-label-big-bold {
    font-size: 1.1rem;
    font-weight: 600;
  }

  p {
    @media screen and (max-width: 320px) {
      font-size: 0.9rem;
    }
  }

  .buttons-container {
    flex-wrap: wrap-reverse;

    @media screen and (max-width: 768px) {
      > a, > button {
        margin-top: 4vw;
      }
    }
  }

  .info-item {
    padding: 30px 0;

    &.bottom-line {
      border-bottom: 2px solid #e4e4ee;
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 25px;
      margin-bottom: 15px;

      @media screen and (max-width: 320px) {
        font-size: 1.2rem;
      }
    }

    p {
      font-size: 14px;

      @media screen and (max-width: 768px) {
        font-size: 1.1rem;
        line-height: 1.6rem;
      }

      @media screen and (max-width: 320px) {
        font-size: 0.8rem;
        line-height: 1.3rem;
      }
    }

    img {
      height: 14vw;
      max-height: 75px;
      max-width: none;
    }

    .img-wrapper {

      padding-right: 5vw;

      @media screen and (min-width: 769px) {
        width: 100px;
        padding-right: 40px;
      }

      @media screen and (max-width: 768px) {
        min-width: 16vw;
      }
    }
  }

  .header1 {
    font-size: 2rem;
    margin-bottom: 35px;
    color: #153F41;
    font-weight: bold;

    @media screen and (max-width: 320px) {
      font-size: 1.5rem;
    }
  }
  .header-center {
    margin-left: 90px;
    @media screen and (max-width: 425px) {
      margin-left: 0;
    }
  }
  .header2 {
    font-size: 2rem;
    margin-bottom: 30px;
    font-weight: bold;

    @media screen and (max-width: 320px) {
      font-size: 1.5rem;
    }
  }

  .counter {
    display: block;
    text-align: right;
    font-size: 0.8rem;
    margin-top: -10px;
  }

  .description {
    height: 200px;
  }

  .input-width-auto {
    width: auto;
  }
  .input-width-fixed {
    width: 250px;
    @media screen and (max-width: 425px){
      width: 100%;
    }
  }
  .safe-deal-features {
    padding: 20px 0 30px;

    li {
      background: url("../../../public/img/w_sd_l.png") no-repeat left center;
      padding: 10px 0 10px 40px;
    }
  }

  .spec-pill {
    display: inline-block;
    padding: 10px 35px;
    background: #d9dae6;
    font-size: 14px;
    border-radius: 100px;
    color: #434343;
  }

  .resume {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    border-top: 1px solid #e4e4ee;

    .item {
      display: flex;
      padding: 0 10px;
      flex-wrap: wrap;


      img {
        max-width: none;
        max-height: 38px;
      }

      .icon {
        padding-top: 7px;
        padding-right: 12px;

      }

      .text-container {
        //margin-left: 12px;
      }

      .title {
        text-decoration: underline;
        margin-bottom: 10px;
      }

      .value {
        font-weight: 600;
      }

      @media screen and (max-width: 490px) {
        text-align: center;
        padding: 0 1.5vw;

        .icon {
          padding: 0 0 5px 0;
          width: 100%;
        }

        .title {
          font-size: 4vw;
        }

        .value {
          font-size: 3.5vw;
        }
      }
    }
  }

  .registration-text {
    margin: 0 auto;
    max-width: 590px
  }

  .registration-login-link {
    color: #2D6163;
    text-decoration: underline;
  }

  .publicated-box {
    background: #fff;
    margin: 0 auto;
    max-width: 590px;
    padding: 20px 20px 30px;
    border: 3px solid #2D6163;
    box-shadow: 0px 10px 22.08px 1.92px rgba(28, 65, 71, 0.14);

    .field-error-container {
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: 600px) {
      margin: 0 5px;
    }
  }

}

.wizard-sidebar {
  .mob {
    display: none;
  }

  .circle {
    z-index: 2;
    position: absolute;
    background: #fff;
    font-weight: 600;
    font-size: 23px;
    left: 0;
    top: 0;
    height: 55px;
    width: 55px;
    border-radius: 100%;
    text-align: center;
    line-height: 49px;
    border: 3px solid;
  }

  %step-shared {
    padding: 15px 0 15px 80px;
    position: relative;

    .text {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .stick {
      position: absolute;
      width: 3px;
      height: 100%;
      left: 25px;
      bottom: 0;
    }
  }

  @mixin step-color($color) {
    color: $color;

    .circle {
      border-color: $color;
      color: $color;
    }

    .stick {
      background: $color;
    }
  }

  @mixin step($color1, $color2) {
    @extend %step-shared;

    @include step-color($color1);
    &.active {
      @include step-color($color2);
    }
  }

  $steps: (
          "1": (#9fa3c0, #3f4881),
          "2": (#9fa3c0, #39557d),
          "3": (#94b6b7, #296f6f),
          "4": (#8ac6ad, #168e5e),
          "5": (#83d1a7, #00af4a),
  );

  @each $step, $colors in $steps {
    .step#{$step} {
      @include step(nth($colors, 1), nth($colors, 2));
    }
  }

  .mob-step {
    color: #9fa3c0;

    .circle {
      position: relative;
      border-color: #9fa3c0;
      height: 13vw;
      width: 13vw;
      line-height: 12vw;
      font-size: 5vw;
      border-width: 0.7vw;
    }

    .stick {
      position: absolute;
      display: block;
      z-index: 1;
      height: .6vw;
      width: 16vw;
      top: 50%;
      left: 101%;
      margin-top: -.3vw;
      background: #9fa3c0;
    }

    &.active {
      color: #3f4881;
      .circle {
        color: #3f4881;
        border-color: #3f4881;
      }

      .stick {
        background: #3f4881;
      }
    }
  }

  @media screen and (min-width: 769px) {
    min-width: 320px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 5vw;

    .mob {
      display: block;
    }

    .step {
      padding: 2vw;
      display: none;

      .circle, .stick {
        display: none;
      }

      &.current {
        display: block;
      }

      .text {
        text-align: center;
        font-size: 4.8vw;
      }
    }

    .mob-steps {
      display: flex;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 520px) {
    .step {
      .text {
        font-size: 6vw;
      }
    }
  }
}
