.feedback {
  overflow: hidden;
  position: relative;
  padding-top: 107px;
  padding-bottom: 69px;
  @include bgImg('../../../public/img/feedback_bg.svg');
  background-position: top 45px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  @include media(1200) {
    padding-top: 47px;
    padding-bottom: 36px; }
  &::before {
    content: '';
    position: absolute;
    top: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    background-color: #f3f8fb;
    @include media(576) {
      top: 50px; } } }

.feedback__cont {
  @include cont; }

.feedback__title {
  @include media(1500) {
    margin-top: 120px; }
  @include media(768) {
    margin: 112px;
    margin-top: 0; }
  @include title;
  margin-bottom: 93px;
  text-align: center;
  margin-top: 70px;
  @include media(1200) {
    margin-bottom: 26px; }

  @include media(576) {
    margin: 0 -8px 26px; } }

.feedback__slider-box {
  position: relative;
  margin-bottom: 74px;
  @include media(768) {
    margin: 0 auto 19px;
    width: 330px; } }

.feedback__slider {
  .slick-track {
    padding-bottom: 40px; }
  .slick-arrow {
    position: absolute;
    z-index: 10;
    top: 0;
    width: 60px;
    height: 100%;
    cursor: pointer;
    font-size: 0;
    @include media(1500) {
      width: 42px; } }
  .slick-prev {
    left: -60px;
    @include bgIcon('../../../public/img/arrow-left.svg');
    background-size: 28px 28px;
    background-position: top 50% left;
    @include media(1500) {
      left: -40px; }
    @include media(1200) {
      left: -22px;
      background-size: 18px 18px; } }
  .slick-next {
    right: -60px;
    @include bgIcon('../../../public/img/arrow-right.svg');
    background-size: 28px 28px;
    background-position: top 50% right;
    @include media(1500) {
      right: -40px; }
    @include media(1200) {
      right: -22px;
      background-size: 18px 18px; } } }

.feedback__item {
  position: relative;
  margin: 0 15px;
  padding: 18px 25px 42px;
  width: 330px;
  border: 3px solid #3F4883;
  border-radius: 15px;
  background-color: white;
  @include media(1500) {
    margin: 0 29px; }
  @include media(1200) {
    margin: 0 14px;
    width: 289px; }
  @include media(992) {
    width: 330px; }
  &::before {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 28px;
    z-index: -1;
    border: 3px solid #3F4883;
    border-radius: 10px;
    transform: skewY(-30deg);
    height: 70px;
    width: 80px;
    background-color: $white; }
  &::after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 31px;
    border-radius: 10px;
    transform: skewY(-30deg);
    height: 70px;
    width: 90px;
    background-color: $white; } }

.feedback__item_1 {
  height: 380px; }

.feedback__item_2 {
  height: 450px; }

.feedback__item_3 {
  height: 420px; }

.feedback__picture {
  display: block;
  width: 62px;
  height: 62px;
  margin-bottom: 12px;
  @include media(1500) {
    margin-bottom: 27px; } }

.feedback__image {}

.feedback__text {
  margin-bottom: 10px;
  letter-spacing: 0.02em;
  font-size: 15px;
  line-height: 26px;
  @include media(1500) {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 21px; } }

.feedback__author {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  align-self: flex-end;
  letter-spacing: 0.02em; }

.feedback__author-picture {
  margin-right: 22px; }

.feedback__author-image {}

.feedback__author-note {
  padding-top: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 31px;
  color: #3F4883; }

.feedback__btn-box {
  text-align: center; }

.feedback__button {
  padding: 15px 35px;
  letter-spacing: 0.02em;
  font-size: 17px;
  line-height: 25px;
  @include media(992) {
    padding: 14px 26px;
    font-size: 14px;
    line-height: 22px; } }
