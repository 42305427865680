@mixin button_yellow {
  display: inline-block;
  vertical-align: top;
  box-shadow: 0 0 0 8px rgba(255, 175, 0, 0.34);
  border-radius: 100px;
  background: linear-gradient(180deg, #FFAF00 0%, #FA9704 100%);
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: $black;
  @include media(1200) {
    box-shadow: 0 0 0 6px rgba(255, 175, 0, 0.34); }
  &:hover {
    @include hover {
      background: linear-gradient(180deg, #FFB71B 0%, #F9A324 100%); }
    &:active {
      background: linear-gradient(180deg, #F9AB01 0%, #E98B00 100%); } } }
