.choose-best {
  position: relative;
  z-index: 2;
  margin-bottom: 60px;
  @include media(1500) {
    margin-bottom: 30px; } }

.choose-best__cont {
  @include cont; }

.choose-best__title {
  @include title;
  margin-bottom: 80px;
  text-align: center;
  @include media(1500) {
    margin-bottom: 28px; }
  //margin-left: 10%
  @include media(768) {
    margin-left: 20%;
    margin-right: 100px; }
  @include media(456) {
    margin-left: 3%;
    margin-right: 0; }
  @include media(375) {
    margin-left: 0;
    margin-right: 0; } }
.choose-best__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 65px;
  @include media(1500) {
    margin-bottom: 29px; }
  @include media(1200) {
    justify-content: space-around;
    margin-bottom: 0; }
  @include media(768) {
    justify-content: center; } }

.choose-best__item {
  width: 306px;
  text-align: center;
  @include media(1500) {
    width: 260px; }
  @include media(1200) {
    width: 310px;
    margin-bottom: 35px; }
  @include media(768) {
    width: 277px; } }

.choose-best__picture {
  display: block;
  margin: 0 auto 33px;
  @include media(1500) {
    margin-bottom: 16px; } }

.choose-best__image {
  width: 182px;
  height: 182px;
  @include media(1500) {
    width: 136px;
    height: 136px; } }

.choose-best__text {
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  @include media(1500) {
    display: block;
    font-size: 16px;
    line-height: 24px; } }

.choose-best__btn-box {
  margin-top: -6px;
  text-align: center;
  @include media(1200) {
    margin-top: 1px; } }

.choose-best__button {
  padding: 15px 46px 15px;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.02em;
  @include media(1200) {
    width: auto;
    max-width: 288px;
    padding: 14px 10px;
    font-size: 15px;
    line-height: 22px; }
  @include media(992) {
    line-height: 22px;
    padding: 14px 26px;
    max-width: 100%; } }
