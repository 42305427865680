.cap {
  padding-bottom: 122px;
  margin-bottom: calc(100vh - 735px);
  margin-top: 6%;
  @include media(768) {
    padding-bottom: 32px;
    display: flex;
    align-items: center; }
  @include media(1500) {
    margin-top: 15%; }
  @include media(1200) {
    padding-bottom: 30px;
    margin-top: 5%;
    margin-bottom: 0; }
  @include media(425) {
    margin-top: 0; }
  //@include media(1024)
  //  height: calc(100vh - 111px)
  //  display: flex
  //  align-items: center
  &::before, &::after {
    content: '';
    position: absolute;
    z-index: -1; }
  &::before {
    top: -33px;
    left: -50px;
    width: 1120px;
    height: 932px;
    @include bgImg('../../../public/img/cap_bg-1.svg');
    background-repeat: no-repeat;
    @include media(1500) {
      top: -8px;
      left: -5px;
      width: 318px;
      height: 582px;
      @include bgImg('../../../public/img/cap_bg-3.svg');
      background-repeat: no-repeat; } }
  &::after {
    top: 195px;
    right: 0;
    width: 356px;
    height: 753px;
    @include bgImg('../../../public/img/cap_bg-2.svg');
    background-repeat: no-repeat;
    @include media(1500) {
      content: none; } } }
.cap_1 {
  .cap__picture {
    top: -17px;
    left: 0;
    @include media(1500) {
      top: -40px; }
    @include media(1200) {
      top: -17px; } } }

.cap_2 {
  .cap__picture {
    top: -17px;
    left: 0;
    @include media(1500) {
      top: -40px; }
    @include media(1200) {
      top: -17px; } } }



.cap_3 {
  .cap__info {
    margin-top: 0; }
  .cap__title {
    margin-bottom: 24px;
    @include media(1500) {
      margin-bottom: 7px; } }
  .cap__picture {
    top: -17px;
    left: 0;
    @include media(1500) {
      top: -40px; }
    @include media(1200) {
      top: -17px; } } }

.cap__cont {
  @include cont;
  position: relative;
  display: flex;
  @include media(992) {
    flex-wrap: wrap;
    position: relative; } }

.cap__picture {
  display: block;
  position: absolute;
  @include media(992) {
    position: static;
    margin-bottom: 20px; } }

.cap__imag.experts {
  @include media(768) {
 } }    //height: calc(100vh - 65px)
e {
  width: 737px;
  height: 584px;
  @include media(1500) {
    width: 537px;
    height: 426px; }
  @include media(1200) {
    width: 420px;
    height: 333px; }
  @include media(992) {
    width: 688px;
    height: 546px; }
  @include media(769) {
    width: 508px;
    height: 403px; }
  @include media(576) {
    width: 100%;
    height: auto; } }

.cap__info {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: auto;
  max-width: 690px;
  flex-shrink: 0;
  @include media(1500) {
    margin-top: 0;
    max-width: 490px; }
  @include media(1200) {
    max-width: 390px; }
  @include media(992) {
    display: block;
    max-width: 100%; } }

.cap__caption {
  margin-bottom: 5px;
  letter-spacing: 0.01em;
  font-size: 55px;
  line-height: 76px;
  color: #00AF4A;
  text-transform: uppercase;
  @include media(1500) {
    margin-bottom: 10px;
    font-size: 35px;
    line-height: 43px; } }

.cap__title {
  margin-bottom: 46px;
  letter-spacing: 0.02em;
  font-size: 30px;
  line-height: 42px;
  @include media(992) {
    font-size: 20px; }
  @include media(1500) {
    margin-bottom: 7px;
    font-size: 21px;
    line-height: 22px; } }

.cap__text {
  margin-bottom: 45px;
  letter-spacing: 0.02em;
  font-size: 22px;
  font-weight: 600;
  line-height: 35px;
  @include media(1500) {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 21px; }
  @include media(992) {
    margin-bottom: 0;
    font-size: 15px; } }

.cap__note-box {
  display: flex;
  margin: 0 -13px 63px;
  @include media(1500) {
    margin: 0 -6px 30px; }
  @include media(992) {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 80px; }
  @include media(425) {
    margin-top: 0; } }
.cap__note, .cap__new {
  margin: 0 13px;
  max-width: 570px;
  display: block;
  padding: 20px 29px;
  letter-spacing: 0.02em;
  font-size: 24px;
  font-weight: 600;
  line-height: 35px;
  background: #F5FBFB;
  border-radius: 15px;
  @include media(1500) {
    margin: 0 6px;
    padding: 8px 14px;
    font-size: 16px;
    line-height: 24px; }
  @include media(992) {
    max-width: 100%;
    flex-grow: 1; }
  span {
    letter-spacing: 0.02em;
    font-size: 40px;
    font-weight: 700;
    color: #3F4883;
    @include media(1500) {
      font-size: 20px; } } }

.cap__new {
  span {
    color: #00AF4A; } }

.cap__box-btn {
  order: 2;
  @include media(992) {
    margin-bottom: 20px;
    text-align: center; } }

.cap__btn {
  padding: 18px 41px 16px;
  margin-top: -15px;
  @include button_yellow;
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  @include media(1500) {
    min-width: 255px;
    padding: 16px 12px 14px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 0; } }
.experts {
  @include media(768) {
 } }    //height: calc(100vh - 65px)
.cap__note-box {
  @include media(768) {
    width: 80vw;
    margin-left: -7vw; }
  @include media(425) {
    margin-left: 0;
    width: 100%; } }
.mt80 {
  @include media(992) {
    margin-top: 80px; }
  @include media(425) {
    margin-left: 0;
    width: 100%;
    margin-top: 0; } }
