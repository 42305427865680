.footer-land {
  padding-top: 34px;
  padding-bottom: 76px;
  background-color: #DCE7ED;
  @include media(1500) {
    padding-bottom: 60px; }
  @include media(1200) {
    padding-top: 13px;
    padding-bottom: 110px; } }

.footer-land__cont {
  @include cont;
  display: flex;
  @include media(1500) {
    flex-wrap: wrap; } }

.footer-land__picture {
  flex-shrink: 0;
  margin-right: 94px;
  width: 188px;
  height: 94px;
  @include media(1500) {
    margin-right: 37px;
    width: 116px;
    height: 58px; }
  @include media(992) {
    margin-top: 4px; } }

.footer-land__logo {
  float: right; }

.footer-land__info {
  margin-right: 91px;
  flex-shrink: 0;
  max-width: 350px;
  font-size: 13px;
  line-height: 20px;
  @include media(1500) {
    margin-right: 50px;
    font-size: 12px;
    line-height: 18px; }
  @include media(992) {
    order: 1;
    max-width: 100%;
    margin-top: 10px; } }

.footer-land__text {
  margin-bottom: 13px;
  letter-spacing: 0;
  @include media(1500) {
    margin-bottom: 3px; } }

.footer-land__copyright {
  letter-spacing: 0; }

.footer-land__list {
  @include media(1900);
  flex-grow: 1;
  column-count: 2;
  @include media(1200) {
    column-count: 1;
    line-height: 20px;
    margin-right: 150px;
    margin-top: 10px; } }
.footer-land__item {
  margin-bottom: 15px;
  @include media(1500) {
    margin-bottom: 5px; } }

.footer-land__link {
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  color: #212121;
  @include media(1500) {
    font-size: 11px;
    line-height: 16px; } }
