@import 'general-and-change';


/* -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.have-account-popup__buttons-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 425px;
  max-width: 100%;
  margin: 0 auto;
}
.have-account-popup__button {
  width: 197px;
}
.have-account-popup__close {
  top: 0;
  right: -34px;
 }
.create-account-worker-popup__title {
    margin: 0 0 47px 0;
}
.create-account-worker-popup__steps-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: url('img/img__arrow-yellow-right-up.png') no-repeat;
    margin: 35px 0 40px 0;
    background-position-y: calc(100% - 23px);
    background-position-x: 410px;
}
.create-account-worker-popup__steps-list {
    width: auto;
    max-width: calc(100% - 262px);
}
.create-account-worker-popup__steps-item {
  display: flex;
  align-items: center;
  height: 55px;
  width: 100%;
  padding: 0 0 0 65px;
  margin: 0 0 45px 0;
  background: url('img/icon__create-new-worker-account-steps-1.png') no-repeat left center;
  background-size: contain;
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.2;
}
.create-account-worker-popup__steps-item span {
  margin: 0 10px 0 0;
  font-weight: 700;
}
.create-account-worker-popup__steps-item--2 {
  background-image: url('img/icon__create-new-worker-account-steps-2.png');
}
.create-account-worker-popup__steps-item--3 {
  background-image: url('img/icon__create-new-worker-account-steps-3.png');
  margin: 0;
}
.create-account-worker-popup__button-start {
    width: 248px;
    height: 55px;
}
.create-account-worker-popup > .modal{
  padding: 50px 55px;
}

.create-account-worker-popup__steps-number {
    margin: 0 auto 30px auto;
}
.create-account-worker-popup__button {
    margin: 50px auto 0 auto;
    width: 210px;
  display: block;
}
.create-account-worker-popup__button-for-add {
    margin: -15px 0 0 0;
}
.create-account-worker-popup__block-2button {
    display: flex;
    align-items: center;
    justify-content: center;
}
.create-account-worker-popup__block-2button > button {
    margin: 20px 0 0 0;
}
.create-account-worker-popup__block-2button > button:nth-child(1) {
    margin-right: 30px;
}
.create-account-worker-popup__modal {
    padding:40px 50px;
}
.create-account-worker-popup__button-register {
  width: 240px;
  margin: 20px auto 0 auto;
}
.create-account-worker-popup__verificationcode > label {
  display: block;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  width: auto;
}
.create-account-worker-popup__verificationcode {
  width: 475px;
  margin: 0 auto;
}
.create-account-worker-popup__verificationcode>div {
  display: none;
}

@media screen and (max-width:768px)
{
  .create-account-worker-popup__verificationcode>div {
    width: 75%;
    max-width: 375px;
    margin: 0 auto;
    display: block;
  }
}

.create-account-worker-popup__button-del-for-add {
  width: 125px;
  align-items: center;
  justify-content: right;
  margin: -16px 0 0 auto;
}
.create-account-worker-popup__button-del-for-add > .button-for-add__text {
  padding: 10px 17px;
}
.form-select-specialty {
  margin-bottom: 20px;
  width: 100%;
}
.form-select-specialty__head {
  display: flex;
  width: 750px;
  margin: 0 12px 10px;
}
.form-select-specialty__title {
  display: block;
  width: calc(50% - 20px);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.075em;
}
.form-select-specialty__list {
  margin: 0 12px 0;
}
.form-select-specialty__item {
  position: relative;
  display: flex;
  margin: 0 0 8px;
  overflow: hidden;
  border-radius: 10px;
  padding: 18px 20px;
  background-color: rgba(64, 72, 131, 0.1);
  //width: 700px;

}
.form-select-specialty__item_hidden {
  display: none;
}
.form-select-specialty__remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 27px;
  height: 27px;
  background-image: url('img/icon__close-red.svg');
  background-repeat: no-repeat;
  background-size: 17px;
  background-position: center;
  cursor: pointer;
}
.form-select-specialty__box {
  margin: 0 10px;
  width: calc(50% - 20px);
  background-color: rgba(64, 72, 131, 0);

  select{
    display: block !important;
  }
}
.form-select-specialty__box .field-error-container {
  background-color: rgba(64, 72, 131, 0);
}
.form-select-specialty__add {
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  cursor: pointer;
  user-select: none;
  margin: 10px 20px;
}
.form-select-specialty__add_hidden {
  display: none;
}
.form-select-specialty__plus {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 12px;
  border-radius: 50%;
  border: 2px solid #FFAC00;
  width: 40px;
  height: 40px;
}
.form-select-specialty__plus::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 17px;
  width: 2px;
  height: 16px;
  background-color: #FFAC00;
}
.form-select-specialty__plus::after {
  content: '';
  position: absolute;
  top: 17px;
  left: 10px;
  width: 16px;
  height: 2px;
  background-color: #FFAC00;
}
.form-select-specialty__note {
  display: inline-block;
  vertical-align: top;
  border-radius: 10px;
  padding: 11px 16px;
  background-color: rgba(64, 72, 131, 0.1);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.075em;
}

#multistep_modal form button {
    cursor: pointer;
}

@media screen and (max-width: 950px) {
  .create-account-worker-popup__close {
    right: 20px;
    top: 20px;
  }
  .create-account-worker-popup__steps-block {
    display: block;
    background: none;
  }
  .create-account-worker-popup__steps-list {
    width: 100%;
    max-width: 100%;
  }
  .create-account-worker-popup__button-start {
    width: 100%;
    margin: 40px auto 0 0;
  }
  .create-account-worker-popup > .modal {
    padding: 4vw;
  }

}
.bdr {
  border-radius: 10px 0 0 10px;
}

.bdl {
  border-radius: 0 10px 10px 0;
}

@media screen and (max-width: 768px) {
  .form-select-specialty__list {
    margin: 0 0 20px;
  }
  .form-select-specialty__box {
    margin: 0;
  }
  .form-select-specialty__box + .form-select-specialty__box {
    margin-top: 11px;
  }
  .bdr {
    border-radius: 10px;
  }
  .bdl {
    border-radius: 10px;
  }

  .form-select-specialty__box {
    width: 100%;
  }
  .create-account-worker-popup__close {
    right: 10px;
    top: 10px;
    z-index: 1;
  }
  .create-account-worker-popup__verificationcode {
    display: block;
    width: 100%;
  }
  .forms-block-inline__label {
    margin: 0 auto 10px;
    text-align: center;
  }
  .forms-block-inline__input {
    margin: 0 auto;
    width: 75%;
    max-width: 350px;
  }
}

@media screen and (max-width: 576px) {
  .form-select-specialty__head {
    display: none;
  }
  .form-select-specialty__item {
    display: block;
    padding: 18px 16px 18px;
  }
  .form-select-specialty__item_hidden {
    display: none;
  }
  .form-select-specialty__box {
    margin: 0 0 16px;
    width: 100%;
  }
  .form-select-specialty__remove + .form-select-specialty__box {
    margin-top: 11px;
  }
}
